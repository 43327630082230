import React, { useEffect, useMemo, useRef, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import { Button, Fade, ListItemText, Portal, Typography } from '@mui/material';

import {
  ExtendedCheckpoint,
  useCreateCheckpointMutation,
  useDeleteCheckpointMutation,
  useDeleteCheckpointsMutation,
  useUpdateCheckpointMutation,
} from '@checkpoints/shared';

import { CheckpointsStore } from '../../stores/checkpointsStore';
import { refreshCheckpointOrderIds } from '../../stores/helpers';
import { CapitalizedButton } from '../button';
import { CancelRounded, DeleteIcon, ListIcon } from '../common';
import { ColorPickerButton } from '../button/ColorPickerButton';
import { useRunsheetData } from '../../pages/checkpoints/RunsheetHeader';
import { ModalStore } from '../../stores/ModalStore';

import { MovePopover } from './MovePopover';
import { PaddedDiv, GrayDivider } from './PopoverUtils';
import { SmallIconButton } from './SmallIconButton';
import { DuplicatePopover } from './DuplicatePopover';

const POPPER_ARROW_SIZE = 1.41;
const popperArrowWidth = `${POPPER_ARROW_SIZE}em`;
// = width / sqrt(2) = (length of the hypotenuse)
const popperArrowHeight = `${POPPER_ARROW_SIZE}em`;

export const RowSettingsPopover = observer(function RowSettingsPopover() {
  const { anchorEl, row } = CheckpointsStore.store.rowSettingsActive;
  // console.log('RowSettingsPopover', anchorEl);

  const { runsheet } = useRunsheetData(
    CheckpointsStore.store.selectedRunsheetId,
  );

  const {
    handleClose,
    handleDuplicateItem,
    handleToggleMultiSelect,
    handleSetColorItem,
    handleHideItem,
    handleDeleteItem,
  } = useRowSettingsMethods(row);
  const [isHiding, setIsHiding] = useState(false);
  const [anchor, setAnchor] = useState(undefined);
  const [movePopoverOpen, setMovePopoverOpen] = useState(false);
  const [duplicatePopoverOpen, setDuplicatePopoverOpen] = useState(false);
  // const [showProFeatures, setShowProFeatures] = useState(false);

  const checkpoints = CheckpointsStore.store.sortedCheckpoints;

  const multiSelectRootEl = document.querySelector('#multiSelect-root');
  const isMultiSelectionState = anchor === multiSelectRootEl;
  const multiSelectMode = CheckpointsStore.store.multiSelectMode ?? false;
  const multiSelected = CheckpointsStore.store.multiSelected;
  const multiSelectedChkpts = checkpoints.filter((item: { id: number }) =>
    multiSelected.includes(item.id),
  );

  const showingChkpts = multiSelectedChkpts.filter(
    (item: { active: boolean }) => item.active === true,
  );

  const hidingChkpts = multiSelectedChkpts.filter(
    (item: { active: boolean }) => item.active === false,
  );

  const { classes, cx } = useRowSettingsPopoverStyles({
    multiSelectMode: isMultiSelectionState,
  });

  const open = Boolean(row);
  const id = open ? 'RunsheetEditor-popper' : undefined;
  // const previousAnchorEl = usePrevious(anchorEl);
  const moveButtonRef = useRef(null);
  const duplicateButtonRef = useRef(null);
  const [arrowRef, setArrowRef] = useState(null);

  // check if all selected rows are active/inactive to disable hide/show all
  // buttons, as appropriate
  const allRowStatus = useMemo(() => {
    if (!multiSelected?.length) {
      // console.log('none selected');

      return 'none-selected';
    }

    // console.log('selected chkpts', chkpts);
    if (multiSelectedChkpts.length === showingChkpts.length) {
      // console.log('all showing');

      return 'showing';
    }
    if (multiSelectedChkpts.length === hidingChkpts.length) {
      // console.log('all hidden');

      return 'hidden';
    }
  }, [
    hidingChkpts.length,
    multiSelected?.length,
    multiSelectedChkpts.length,
    showingChkpts.length,
  ]);

  const showProFeatures = runsheet?.event?.plan === 'PRO';

  // make sure anchor state is updated whenever the passed anchorEl changes
  useEffect(() => {
    setAnchor(anchorEl);
  }, [anchorEl]);

  // manage hiding and showing multiselect popover
  useEffect(() => {
    if (multiSelectMode) {
      // console.log('multiSelect active');

      if (anchor !== multiSelectRootEl) {
        setIsHiding(true);

        setTimeout(() => {
          setAnchor(multiSelectRootEl);
          setIsHiding(false);
        }, 300);
      }
    } else {
      // console.log('multiSelect inactive');

      if (anchor !== anchorEl) {
        setIsHiding(true);

        setTimeout(() => {
          setAnchor(anchorEl);
          setIsHiding(false);
        }, 300);
      }
    }
  }, [anchor, anchorEl, multiSelectRootEl, multiSelectMode]);

  useEffect(() => {
    if (!open) {
      setMovePopoverOpen(false);
    }
  }, [open]);

  if (!anchor) {
    return null;
  }

  return !isMultiSelectionState ? (
    <Popper
      id={id}
      open={open && !isHiding}
      anchorEl={anchor}
      transition
      className={cx(classes.popper)}
      placement={isMultiSelectionState ? 'top' : 'right'}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
        {
          name: 'arrow',
          enabled: !isMultiSelectionState,
          options: {
            element: arrowRef,
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={280} unmountOnExit>
          <Paper
            sx={{
              boxShadow: 'none',
            }}
            elevation={4}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Paper className={cx(classes.popoverRoot)} role="presentation">
                <Typography
                  component="span"
                  className={cx(classes.arrow, 'arrow')}
                  ref={setArrowRef}
                />
                <Box className={cx(classes.content)}>
                  {showProFeatures && (
                    <>
                      <ColorPickerButton
                        selection={row?.color as RunsheetColor}
                        onSelect={handleSetColorItem}
                      />
                      <GrayDivider className={cx(classes.divider)} />
                    </>
                  )}
                  {showProFeatures && (
                    <>
                      <Button
                        startIcon={<ListIcon />}
                        onClick={handleToggleMultiSelect}
                        sx={{ flexShrink: 0 }}
                      >
                        Multi-select
                      </Button>
                      <GrayDivider className={cx(classes.divider)} />
                    </>
                  )}
                  {/* <ArrowedButton
                    ref={duplicateButtonRef}
                    onClick={() => {
                      setDuplicatePopoverOpen((s) => !s);
                    }}
                    arrowDirection="down"
                  >
                    <ListItemText primary="Duplicate" />
                  </ArrowedButton> */}
                  <DuplicatePopover
                    handleDuplication={(mode, id) => {
                      console.log('handleDuplication callback', mode, 'id', id);
                      // take row and mode
                      handleDuplicateItem(mode, id);
                    }}
                  />
                  <GrayDivider className={cx(classes.divider)} />
                  <MovePopover />
                  <GrayDivider className={cx(classes.divider)} />
                  <PaddedDiv>
                    <FormControlLabel
                      value="hide"
                      control={
                        <Switch
                          color="primary"
                          checked={Boolean(row) && !row.active}
                          onChange={() => handleHideItem()}
                        />
                      }
                      label="Hide"
                      labelPlacement="start"
                    />
                  </PaddedDiv>
                  <GrayDivider className={cx(classes.divider)} />
                  <SmallIconButton onClick={handleDeleteItem}>
                    <DeleteIcon color={'error'} />
                  </SmallIconButton>
                </Box>
              </Paper>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  ) : (
    <Portal
      container={() => {
        return document.querySelector('.no_scrollbar');
      }}
    >
      <Fade in={isMultiSelectionState}>
        <Box className={cx(classes.portalRoot)}>
          <Paper className={cx(classes.multiSelectBox)}>
            <Button
              variant="text"
              startIcon={<CancelRounded fontSize="small" />}
              className={cx(classes.multiSelectButton)}
              size="small"
              onClick={handleClose}
            >
              <Typography
                component="span"
                className={cx(classes.multiSelectBoxLabel)}
              >
                Cancel selection
              </Typography>
            </Button>
          </Paper>
          <Paper className={cx(classes.popoverRoot)} role="presentation">
            <Typography
              component="span"
              className={cx(classes.arrow, 'arrow')}
              ref={setArrowRef}
            />
            <Box className={cx(classes.content)}>
              {showProFeatures && (
                <>
                  <ColorPickerButton
                    selection={row?.color as RunsheetColor}
                    onSelect={handleSetColorItem}
                    disabled={!multiSelected?.length}
                  />
                  <GrayDivider className={cx(classes.divider)} />
                </>
              )}
                  <DuplicatePopover
                    handleDuplication={(mode, id) => {
                      console.log('handleDuplication callback', mode, 'id', id);
                      // take row and mode
                      handleDuplicateItem(mode, id);
                    }}
                  />
              <GrayDivider className={cx(classes.divider)} />
              <MovePopover />
              <GrayDivider className={cx(classes.divider)} />
              <Box sx={{ flexShrink: 0 }}>
                <Button
                  disabled={
                    allRowStatus === 'hidden' ||
                    allRowStatus === 'none-selected'
                  }
                  onClick={() => handleHideItem(false)}
                >
                  Hide all
                </Button>
                <Button
                  disabled={
                    allRowStatus === 'showing' ||
                    allRowStatus === 'none-selected'
                  }
                  onClick={() => handleHideItem(true)}
                >
                  Show all
                </Button>
              </Box>
              <GrayDivider className={cx(classes.divider)} />
              <SmallIconButton
                onClick={handleDeleteItem}
                disabled={!multiSelected?.length}
              >
                <DeleteIcon
                  color={!multiSelected?.length ? 'disabled' : 'error'}
                />
              </SmallIconButton>
            </Box>
          </Paper>
        </Box>
      </Fade>
    </Portal>
  );
});

function useRowSettingsMethods(row: ExtendedCheckpoint) {
  const handleClose = () => {
    CheckpointsStore.store.resetPopovers();
  };
  const [del] = useDeleteCheckpointMutation({ fetchPolicy: 'no-cache' });
  const [delMultiple] = useDeleteCheckpointsMutation({
    fetchPolicy: 'no-cache',
  });

  const [create] = useCreateCheckpointMutation({
    fetchPolicy: 'no-cache',
  });

  const [update] = useUpdateCheckpointMutation({
    fetchPolicy: 'no-cache',
  });
  const checkpoints = CheckpointsStore.store.sortedCheckpoints;
  const multiSelectMode = CheckpointsStore.store.multiSelectMode;
  const multiSelected = CheckpointsStore.store.multiSelected;

  const handleSetColorItem = async (selection: string) => {
    // console.log('handleSetColorItem');
    // console.log('multiSelectMode', multiSelectMode);

    const promises: Promise<any>[] = [];

    if (!multiSelectMode) {
      // console.log('adding', row.id);

      promises.push(
        update({
          variables: {
            body: {
              id: row.id,
              color: selection,
            },
          },
        }),
      );
    } else {
      multiSelected.forEach((item) => {
        // console.log('adding', item);
        promises.push(
          update({
            variables: {
              body: {
                id: item,
                color: selection,
              },
            },
          }),
        );
      });
    }

    await Promise.all(promises);
  };

  const handleHideItem = async (setActiveTo?: boolean) => {
    const promises: Promise<any>[] = [];

    if (!multiSelectMode) {
      // console.log('adding', row.id);

      promises.push(
        update({
          variables: {
            body: {
              id: row.id,
              active: setActiveTo ?? !row.active,
            },
          },
        }),
      );
    } else {
      const chkpts = checkpoints.filter((item: { id: number }) =>
        multiSelected.includes(item.id),
      );

      chkpts.forEach((item: { id: any; active: any }) => {
        // console.log('adding', item);
        promises.push(
          update({
            variables: {
              body: {
                id: item.id,
                active: setActiveTo ?? !item.active,
              },
            },
          }),
        );
      });
    }

    await Promise.all(promises);
    // handleClose();
  };

  const handleDeleteItem = async () => {
    const promises: Promise<any>[] = [];

    if (!multiSelectMode) {
      promises.push(del({ variables: { id: row.id } }));
    } else {
      const confirmMessage = `Are you sure you want to delete selected Checkpoints? This action cannot be reverted.`;

      const confirmed = await ModalStore.store.confirm(confirmMessage);
      if (!confirmed) return;
      promises.push(delMultiple({ variables: { ids: multiSelected } }));
      CheckpointsStore.store.multiSelected = [];
    }

    await Promise.all(promises);

    

   // if (!multiSelectMode) {
      handleClose();
   // }
  };

  const handleDuplicateItem = async (
    mode?: 'afterOne' | 'afterEach',
    index?: number,
  ) => {
    if (multiSelectMode) {

      if (mode === 'afterEach') {


      for (const item of multiSelected) {

        const row = checkpoints.find((chkpt) => chkpt.id === item);

        let { checkpoint: body, shouldReload } =
        CheckpointsStore.store.createDuplicateCheckpoint(row, true, item);

        await create({
          variables: {
            body,
          },
        })

      }

    } else {

      for (const item of multiSelected.reverse()) {


        console.log('item', item);
        console.log('index', index);

        const relative_row = checkpoints[index];

        const row = checkpoints.find((chkpt) => chkpt.id === item);

        let { checkpoint: body, shouldReload } =
        CheckpointsStore.store.createDuplicateCheckpoint(row, true, relative_row.id);

        await create({
          variables: {
            body,
          },
        })


      } 


    }


    } else {
      if (mode === 'afterEach') {

        const { checkpoint: body, shouldReload } =
        CheckpointsStore.store.createDuplicateCheckpoint(row, true, row.id);
        await create({
          variables: {
            body,
          },
        })


        handleClose();
      } else {

        const { checkpoint: body, shouldReload } =
          CheckpointsStore.store.createDuplicateCheckpoint(row, true, 0, index);

        await create({
          variables: {
            body,
          },
        });
        if (shouldReload) {
          await refreshCheckpointOrderIds(body.runsheetId);
        }
        handleClose();
      }
    }
  };

  const handleToggleMultiSelect = async () => {
    // console.log('handleToggleMultiSelect', multiSelectMode);

    if (!multiSelectMode) {
      // console.log('multiselected', multiSelected);

      CheckpointsStore.store.toggleMultiSelectMode(row.id);
    } else {
      CheckpointsStore.store.toggleMultiSelectMode();
    }
  };

  return {
    handleClose,
    handleDuplicateItem,
    handleToggleMultiSelect,
    handleSetColorItem,
    handleHideItem,
    handleDeleteItem,
  };
}

const useRowSettingsPopoverStyles = makeStyles<{ multiSelectMode: boolean }>({
  name: 'RowSettingsPopover',
})((theme, { multiSelectMode }, classes) => ({
  popper: {
    // zIndex: 10,
    ['&[data-popper-placement*="right"] .arrow']: {
      top: 0,
      left: 0,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      '&::before': {
        transformOrigin: '25% 0%',
      },
    },
  },
  popoverRoot: {
    backgroundColor: '#ffffff',
    zIndex: 5,
    color: 'black',
    padding: '6px 6px',
    // maxWidth: 500,
    filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.48))',
    boxShadow: '0px 0px 20px #00000026',
    ['& .MuiInputBase-input, & .MuiInputBase-root']: {
      color: 'black',
    },
    ['& .MuiFormLabel-root']: {
      backgroundColor: 'white',
      color: 'black',
    },
  },
  portalRoot: {
    top: '50px',
    position: 'fixed',
    left: 'calc(50% - 250px)',
    zIndex: 10,
  },
  multiSelectBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '150px',
    height: '20px',
    top: '-20px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    left: 'calc(50% - 75px)',
    color: 'black',
    cursor: 'pointer',

    ['&:hover span, &:hover button']: {
      opacity: 1,
    },
  },
  multiSelectButton: {
    width: '100%',
    height: '100%',
    opacity: 0.8,
    padding: 0,
  },
  multiSelectBoxLabel: {
    opacity: 0.8,
    fontSize: '11px',
    lineHeight: '12px',
  },
  content: {
    display: 'flex',
    padding: '2px',
    alignItems: 'center',
    color: 'black',
  },
  divider: {
    margin: '0px 8px',
  },
  arrow: {
    display: multiSelectMode && 'none',
    position: 'absolute',
    width: popperArrowWidth,
    height: popperArrowHeight,
    boxSizing: 'border-box',
    color: 'white',
    zIndex: -1,
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundColor: 'currentColor',
      transform: 'rotate(45deg)',
      boxShadow: '-3px 3px 2px 1px #0000001c',
    },
  },
}));
