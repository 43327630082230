import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import {
  AppUserFragment,
  useUpdateUserPaymentExpiryMutation,
} from '@checkpoints/shared';

import { styled } from '../../colors';
import { DeleteButton, WhiteContainedButton } from '../../components/button';
import { FlexBetween } from '../../components/common';
import { useLatestValue } from '../../components/hooks/useLatestValue';
import { DarkDialog } from '../../components/modals';
import { DateField } from '../../components/textfield';
import MainStore from '../../stores/Store';

const StyledAlertText = styled('div')`
  color: white;
  margin-bottom: 20px;
  white-space: pre-line;
`;

const StyledAlert = styled('div')`
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
`;

export const ChangeExpiryModal = observer(function NotPaidEventAlert({
  user,
  onClose,
}: {
  user: AppUserFragment & { paymentExpires: string };
  onClose: () => void;
}) {
  const [updateExpiry] = useUpdateUserPaymentExpiryMutation();
  const latestUser = useLatestValue(user);

  const [paymentExpires, setPaymentExpires] = useState(
    latestUser &&
      MainStore.store.formatPaymentExpires(latestUser.paymentExpires),
  );

  useEffect(() => {
    if (!latestUser) {
      return;
    }
    setPaymentExpires(
      MainStore.store.formatPaymentExpires(latestUser.paymentExpires),
    );
  }, [latestUser]);

  const handleSubmit = async (_e: any) => {
    const expiry = new Date(paymentExpires).getTime();

    await updateExpiry({
      variables: {
        paymentExpires: String(expiry),
        userId: user.id,
      },
    });
    onClose();
  };

  const handleRemovePlan = async () => {
    await updateExpiry({
      variables: {
        paymentExpires: null,
        userId: user.id,
      },
    });
    onClose();
  };

  return (
    <DarkDialog
      id={'NotPaidEventAlert'}
      open={!!user}
      fullWidth={false}
      onClose={onClose}
    >
      <StyledAlert>
        <StyledAlertText>
          Change plan expiry for: {(latestUser && latestUser.first_name) || ''}{' '}
          {(latestUser && latestUser.last_name) || ''}
        </StyledAlertText>
        <DateField
          autoFocus
          value={paymentExpires || ''}
          onChange={(val) => {
            setPaymentExpires(val);
          }}
        />
        <div style={{ height: 20 }}></div>
        <FlexBetween>
          <DeleteButton onClick={handleRemovePlan}>Remove expiry</DeleteButton>
          <div style={{ width: 10 }}></div>
          <WhiteContainedButton onClick={handleSubmit}>
            Update expiry
          </WhiteContainedButton>
        </FlexBetween>
      </StyledAlert>
    </DarkDialog>
  );
});
