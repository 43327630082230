import React, { useRef, useState } from 'react';

import { observer } from 'mobx-react';

import { CheckpointsStore } from '../../stores/checkpointsStore';
import { scrollToTracked } from '../../stores/helpers';
import { FlexRowCenter } from '../common';
import { WhiteSwitch } from '../switch';
import { Runsheet } from '../../pages/checkpoints/Runsheet';
import { useRunsheetData } from '../../pages/checkpoints/RunsheetHeader';

import { UserContent } from './Avatar';
import { ChangeEditorDropdown } from './ChangeEditorDropdown';
import { SmallRing, SmallRingButton } from './styles';

export const DirectorSwitch = observer(function DirectorSwitch() {
  const buttonRef = useRef(null);
  const director = CheckpointsStore.store.director;
  const selectedRunsheetId = CheckpointsStore.store.selectedRunsheetId;
  const { runsheet } = useRunsheetData(selectedRunsheetId);
  const runbytime = runsheet?.runbytime;
  const displayedAdmins = CheckpointsStore.store.displayedAdmins;
  const isAdmin = CheckpointsStore.store.isAdmin;

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.blur();
    CheckpointsStore.store.toggleTracking();
    const checked = event.target.checked;

    if (checked) {
      scrollToTracked();
    }
  };

  return (
    <FlexRowCenter>
      {isAdmin ? (
        <ChangeEditorDropdown anchorEl={buttonRef} admins={displayedAdmins} />
      ) : (
        <SmallRing>
          <UserContent user={director} runsheet={runsheet} />
        </SmallRing>
      )}
      <WhiteSwitch
        checked={CheckpointsStore.store.tracking}
        onChange={handleSwitch}
      />
    </FlexRowCenter>
  );
});
