import React from 'react';

import { observer } from 'mobx-react';

import { CheckpointsStore } from '../../stores/checkpointsStore';
import { useRunsheetData } from '../../pages/checkpoints/RunsheetHeader';

import { UserContent } from './Avatar';
import { LargeRing } from './styles';

export const TrackingDirectorAvatar = observer(function DirectorAvatar({
  onClick,
}) {
  const director = CheckpointsStore.store.director;
  const userIsDirector = CheckpointsStore.store.userIsDirector;
  const selectedRunsheetId = CheckpointsStore.store.selectedRunsheetId;
  const { runsheet } = useRunsheetData(selectedRunsheetId);

  if (!director) {
    return null;
  }

  return (
    <LargeRing onClick={onClick}>
      <UserContent
        user={director}
        runsheet={runsheet}
        showTooltip={!userIsDirector}
        toolTipPosition="right"
      />
    </LargeRing>
  );
});
