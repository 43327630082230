import {
  action,
  computed,
  flow,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
  set,
} from 'mobx';
import { ApolloError } from '@apollo/client';

import {
  AddUserToEventDocument,
  AddUserToEventMutation,
  AddUserToEventMutationVariables,
  CreateEventDocument,
  CreateEventMutation,
  CreateEventMutationVariables,
  CreateRunsheetDocument,
  CreateRunsheetMutation,
  CreateRunsheetMutationVariables,
  DashboardEventFragment,
  DeleteRunsheetDocument,
  DeleteRunsheetMutation,
  DeleteRunsheetMutationVariables,
  eventSort,
  EventWithRunsheetsDocument,
  EventWithRunsheetsQuery,
  EventWithRunsheetsQueryResult,
  EventWithRunsheetsQueryVariables,
  FetchEventsDocument,
  FetchEventsQueryResult,
  LatestEventsDocument,
  limitArray,
  RemoveEventImageMutation,
  RemoveEventImageMutationVariables,
  RunsheetInput,
  runsheetSort,
  RunsheetUpdate,
  UpdateCheckpointMutationVariables,
  UpdateEventDocument,
  UpdateEventMutation,
  UpdateRunsheetDocument,
  UpdateRunsheetMutation,
  UpdateRunsheetMutationVariables,
  UpdateUserEventPermissionDocument,
  UpdateUserEventPermissionMutation,
  UpdateUserEventPermissionMutationVariables,
  User,
  UserSearchDocument,
  UserSearchQueryResult,
  userSort,
  RemoveEventImageDocument,
  DuplicateRunsheetMutation,
  DuplicateRunsheetMutationVariables,
  DuplicateRunsheetDocument,
  encodeInteger,
} from '@checkpoints/shared';

import MainStore from '../../stores/Store';
import { dropUploader } from '../../utils/dropUploader';
import { CacheOperation, updateCache } from '../../utils/updateCache';
import { sub } from 'date-fns';
import { useNavigate } from 'react-router';

export enum AddModalType {
  None = 0,
  Admin,
  Subscriber,
  AddRunsheet,
  EditRunsheet,
}

// A runsheet that might not be created yet
export interface PartialRunsheet {
  id?: number;
  eventId?: number;
  title: string;
  startTime: string;
  startDate: string;
  showSeconds: boolean;
}

export interface UserPopoverProps {
  user: User;
  anchorEl: any;
  isAdmin?: boolean;
}

export interface RunsheetPopoverProps {
  runsheet: PartialRunsheet;
  anchorEl: any;
}

export class DashboardStore {
  private mainStore: MainStore;
  private static _store: DashboardStore = null;

  constructor(mainStore: MainStore) {
    this.mainStore = mainStore;
    DashboardStore._store = this;

    // @ts-expect-error For Debugging
    window.dashboardStore = this;

    makeObservable(this, {
      clearStore: action,
      completedFetchEvents: observable,
      error: observable,
      activeEventId: observable,
      connectedUsers: observable,
      userPopoverActive: observable,
      dashboardRunsheetPopover: observable,
      sortedEvents: computed,
      activateUserPopoverSettings: action,
      activateRunsheetPopover: action,
      resetPopovers: action,
      addEvent: action,
      removeEvent: action,
      setActiveEventId: action,
      activeEvent: computed,
      isAdminAtCurrentEvent: computed,
      sortedActiveRunsheets: computed,
      admins: computed,
      subscribers: computed,
      showingAddModal: observable,
      showingAddModalType: observable,
      hideModal: action,
      showRunsheetModal: action,
      touchedUsers: computed,
      updateEvent: action,
      removeImage: action,
      createEventLoading: observable,
      _createEvent: action,
      createRunsheet: action,
      updateRunsheet: action,
      updateRunsheetCache: action,
      deleteRunsheet: action,
      duplicateRunsheet: action,
      duplicateEvent: action,
    });
  }

  static get store() {
    return this._store;
  }

  clearStore() {
    this.error = null;
    this.connectedUsers = [];
    this.events.clear();
    this.completedFetchEvents = false;
    this.activeEventId = null;
  }

  completedFetchEvents = false;

  error: ApolloError;
  activeEventId: number = null;
  connectedUsers: User[] = [];

  userPopoverActive: UserPopoverProps = {
    user: null,
    anchorEl: null,
    isAdmin: false,
  };

  dashboardRunsheetPopover: RunsheetPopoverProps = {
    anchorEl: null,
    runsheet: null,
  };

  readonly events: IObservableArray<DashboardEventFragment> = observable.array(
    [],
  );

  get sortedEvents() {
    return this.events.slice().sort(eventSort);
  }

  activateUserPopoverSettings(activeProps: UserPopoverProps) {
    // console.log('activeProps', activeProps);
    const sameElement =
      activeProps.anchorEl === this.userPopoverActive.anchorEl;
    this.resetPopovers();

    if (sameElement) return;
    this.userPopoverActive = activeProps;
  }

  activateRunsheetPopover(activeProps: RunsheetPopoverProps) {
    const sameElement =
      activeProps.anchorEl === this.dashboardRunsheetPopover.anchorEl;
    this.resetPopovers();

    if (sameElement) return;
    this.dashboardRunsheetPopover = activeProps;
  }

  resetPopovers() {
    this.userPopoverActive = {
      user: null,
      anchorEl: null,
    };
    this.dashboardRunsheetPopover = {
      anchorEl: null,
      runsheet: null,
    };
  }

  fetchEvents = flow(function* (this: DashboardStore) {
    //We do this once;
    if (this.completedFetchEvents) {
      return;
    }

    console.log('Fetching events');
    const client = this.mainStore.client;
    this.error = null;

    const result: FetchEventsQueryResult = yield client.query({
      query: FetchEventsDocument,
      fetchPolicy: 'no-cache',
    });

    if (result.error) {
      this.error = result.error;
    }
    if (!result.data) {
      return;
    }
    const { eventsByUser, me } = result.data;
    const { connectedUsers } = me;
    this.events.replace(eventsByUser);
    if (connectedUsers) {
      this.connectedUsers = connectedUsers;
    }
    this.completedFetchEvents = true;
  });

  /** Called from subscription result */
  addEvent(event: DashboardEventFragment) {
    const exisiting = this.events.find((e) => e.id === event.id);

    if (exisiting) {
      return;
    }
    this.events.push(event);
    const client = this.mainStore.client;
    //Refetch lates events
    client.query({ query: LatestEventsDocument, fetchPolicy: 'network-only' });
  }

  /** Called from subscription result */
  removeEvent(event: DashboardEventFragment) {
    const removingEvent = this.events.find((e) => e.id === event.id);

    if (!removingEvent) {
      return;
    }
    this.events.remove(removingEvent);
    const client = this.mainStore.client;
    client.query({ query: LatestEventsDocument, fetchPolicy: 'network-only' });
  }

  setActiveEventId(id: number) {
    if (this.activeEventId !== id) {
      this.activeEventId = id;
    }
    this.fetchSelectedEventWithRunsheets();
  }

  // We want to refetch runsheets every time the user changes event
  // since other admins may have created runsheets and we dont have websocket support here
  fetchSelectedEventWithRunsheets = flow(function* (this: DashboardStore) {
    if (!this.activeEventId) {
      return;
    }
    const client = this.mainStore.client;
    this.error = null;

    const result: EventWithRunsheetsQueryResult = yield client.query<
      EventWithRunsheetsQuery,
      EventWithRunsheetsQueryVariables
    >({
      query: EventWithRunsheetsDocument,
      fetchPolicy: 'network-only',
      variables: {
        id: this.activeEventId,
      },
    });

    if (result.error) {
      this.error = result.error;
    } else {
      if (this.activeEvent) {
        this.activeEvent.runsheets = result.data.event.runsheets;
      }
    }
  });

  get activeEvent() {
    const activeEventId = this.activeEventId;

    if (!activeEventId || !this.events) {
      return null;
    }

    return this.events.find((e) => e.id === activeEventId);
  }

  get isAdminAtCurrentEvent() {
    const me = this.mainStore.me;
    const activeEvent = this.activeEvent;
    if (!me || !activeEvent) return false;

    return activeEvent.admins.findIndex((admin) => admin.id === me.id) !== -1;
  }

  get sortedActiveRunsheets() {
    const runsheets = this.activeEvent ? this.activeEvent.runsheets : [];

    return runsheets.slice().sort(runsheetSort);
  }

  get admins() {
    const admins = this.activeEvent ? this.activeEvent.admins : [];

    return admins.slice().sort(userSort);
  }

  get subscribers() {
    const subscribers = this.activeEvent ? this.activeEvent.subscribers : [];

    return subscribers.slice().sort(userSort);
  }

  activeUserList(isAdmin: boolean) {
    return (
      isAdmin ? this.activeEvent.admins : this.activeEvent.subscribers
    ) as IObservableArray<User>;
  }

  nonActiveUserList(isAdmin: boolean) {
    return (
      isAdmin ? this.activeEvent.subscribers : this.activeEvent.admins
    ) as IObservableArray<User>;
  }

  addModalMeta: PartialRunsheet;
  showingAddModal = false;
  showingAddModalType: AddModalType = AddModalType.None;

  hideModal() {
    this.showingAddModal = false;
  }

  showRunsheetModal(modalType: AddModalType, meta?: PartialRunsheet) {
    this.showingAddModal = true;
    this.showingAddModalType = modalType;
    this.addModalMeta = meta;
  }

  addUserToEvent = flow(function* (this: DashboardStore, user: User) {
    if (!this.activeEvent) return;
    const client = this.mainStore.client;
    const isAdmin = this.showingAddModalType === AddModalType.Admin;

    const otherList = this.nonActiveUserList(isAdmin);
    const activeList = this.activeUserList(isAdmin);

    const isUserInOtherList = otherList.find(
      (otherUser) => otherUser.id === user.id,
    );

    try {
      //If user already exists, we update their status;
      if (isUserInOtherList) {
        yield client.mutate<
          UpdateUserEventPermissionMutation,
          UpdateUserEventPermissionMutationVariables
        >({
          mutation: UpdateUserEventPermissionDocument,
          variables: {
            id: this.activeEvent.id,
            user_id: user.id,
            isAdmin,
          },
        });
        otherList.remove(isUserInOtherList);
        activeList.push(user);

        return;
      }

      //Add the user
      yield client.mutate<
        AddUserToEventMutation,
        AddUserToEventMutationVariables
      >({
        mutation: AddUserToEventDocument,
        variables: {
          id: this.activeEvent.id,
          user_id: user.id,
          email: user.email,
          isAdmin,
        },
      });
      //If no user id, the user will now be created. Clear the apollo search cache
      if (!user.id) {
        //TODO: Once apollo cache is updated, clear cache
        //Work around, use no cache on search queries
        // client.cache.evict({
        //   query: UserSearchDocument,
        //   variables: { email: user.email },
        // });
        // client.cache.writeQuery({
        //   query: UserSearchDocument,
        //   variables: { email: user.email },
        //   data: {
        //   }
        // });
      }
      activeList.push(user);
    } catch (e) {
      console.log('Error: ', e);
    }
  });

  readonly displayedSearchUsers = observable.array<User>([]);

  //Add User search
  findUsers = flow(function* (
    this: DashboardStore,
    search: string,
    existingUsers: User[],
  ) {
    if (!search || !existingUsers) {
      this.displayedSearchUsers.clear();

      return;
    }

    const lowerCaseSearch = search.toLowerCase();
    //Filter away existingUsers;
    let users = this.connectedUsers
      .filter((c) => !existingUsers.find((e) => e.id === c.id))
      .filter(
        (c) =>
          c.email.startsWith(lowerCaseSearch) ||
          ('' + c.first_name).toLowerCase().startsWith(lowerCaseSearch) ||
          ('' + c.last_name).toLowerCase().startsWith(lowerCaseSearch),
      )
      .sort(userSort);

    users = limitArray(users, 3);

    if (!users.length) {
      //Simple email regex https://stackoverflow.com/a/201447/719380
      if (search.match(/^\S+@\S+\.\S+$/g)) {
        //Start a global user search to se if this user exisits
        const client = this.mainStore.client;
        const result: UserSearchQueryResult = yield client.query({
          query: UserSearchDocument,
          //TODO: Once apollo cache is updated, clear cache instead
          fetchPolicy: 'no-cache',
          variables: {
            email: search,
          },
        });

        if (result.data && result.data.user) {
          console.log('result.data.user', result.data.user);

          this.connectedUsers.push(result.data.user);
          this.displayedSearchUsers.replace([result.data.user]);

          return;
        }
        console.log('no user found');
        //If no user exists, display an anonymous user

        this.displayedSearchUsers.replace([{ email: lowerCaseSearch } as any]);

        return;
      }
      this.displayedSearchUsers.clear();

      return;
    }
    this.displayedSearchUsers.replace(users);
  });

  // @observable subscribers: AppUserFragment[] = [];
  // @observable admins: AppUserFragment[] = [];

  get touchedUsers() {
    if (!this.activeEvent) return false;

    return (
      this.activeEvent.subscribers.length > 0 ||
      this.activeEvent.admins.length > 0
    );
  }

  async updateEvent(title: string, file?: any, crop?: any) {
    const createdEvent = this.activeEvent;

    if (!createdEvent) {
      //create event
      await this._createEvent(title, file, crop);

      return;
    }

    if (!title) {
      this.mainStore.displayError(TITLE_MISSING_ERROR);
      throw new Error(TITLE_MISSING_ERROR);
    }

    let imageId = createdEvent.image ? createdEvent.image.id : null;

    if (file) {
      const response = await dropUploader([file], crop);
      const imageResult = response[0];
      imageId = imageResult.id;
    }

    //If no changes return early
    if (!file && title === createdEvent.title) {
      return;
    }

    const body = {
      id: createdEvent.id,
      title,
      imageId,
      active: true,
    };

    const client = this.mainStore.client;

    const result = await client.mutate<
      UpdateEventMutation,
      UpdateCheckpointMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: UpdateEventDocument,
      variables: {
        body,
      },
    });

    const event = result.data.updateEvent;

    runInAction(() => {
      //Update dashboard event;

      const storeEvent = this.events.find((e) => event.id === e.id);
      set(storeEvent, event);
    });
  }

  async duplicateEvent() {
    if (this.createEventLoading) {
      throw new Error('Is currently creating event');
    }


    let newEventbody = {
        active: true,
        imageId: this.activeEvent.imageId,
        plan: this.activeEvent.plan,
        title: this.activeEvent.title + " copy",
    }

      const client = this.mainStore.client;

      let result = await client.mutate<
        CreateEventMutation,
        CreateEventMutationVariables
      >({
        fetchPolicy: 'no-cache',
        mutation: CreateEventDocument,
        variables: {
          body: newEventbody,
        },
      });

      let event = result.data.createEvent;

      for (let runsheet of this.activeEvent.runsheets) {
      
        result = await client.mutate<
      DuplicateRunsheetMutation,
      DuplicateRunsheetMutationVariables
    >({
      mutation: DuplicateRunsheetDocument,
      variables: {
        id: runsheet.id,
      },
      update: (proxy, { data: { duplicateRunsheet } }) => {
        
        this.moveRunsheet(duplicateRunsheet.id, event.id).then((result) => {});

          },
        });
      
      }


      let subscribers = this.activeEvent.subscribers;
      let admins = this.activeEvent.admins;


      for (let user of subscribers) {




        if (user.id === event.ownerId) {
          continue;
        }

        console.log('user', user.id, user.email, event.id);

        await client.mutate<
        AddUserToEventMutation,
        AddUserToEventMutationVariables
      >({
        mutation: AddUserToEventDocument,
        variables: {
          id: event.id,
          user_id: user.id,
          email: user.email,
          isAdmin: false,
        },
      });

      }

      for (let user of admins) {

        if (user.id === event.ownerId) {
          continue;
        }

        await client.mutate<
        AddUserToEventMutation,
        AddUserToEventMutationVariables
      >({
        mutation: AddUserToEventDocument,
        variables: {
          id: event.id,
          user_id: user.id,
          email: user.email,
          isAdmin: true,
        },
      });


       }

       location.replace(`/dashboard/edit/${encodeInteger(event.id)}`);
      

  }

  async removeImage() {
    const createdEvent = this.activeEvent;

    const client = this.mainStore.client;
    const result = await client.mutate<
      RemoveEventImageMutation,
      RemoveEventImageMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: RemoveEventImageDocument,
      variables: {
        id: createdEvent.id,
      },
    });
    const event = result.data.removeEventImage;

    runInAction(() => {
      const storeEvent = this.events.find((e) => event.id === e.id);
      set(storeEvent, event);
    });
  }

  createEventLoading = false;

  async _createEvent(title: string, file?: any, crop?: any) {
    if (this.createEventLoading) {
      throw new Error('Is currently creating event');
    }

    if (!title) {
      this.mainStore.displayError(TITLE_MISSING_ERROR);
      throw new Error(TITLE_MISSING_ERROR);
    }

    this.createEventLoading = true;

    try {
      let imageId: number;

      if (file) {
        const response = await dropUploader([file], crop);
        // console.log('imageResult', response);
        const imageResult = response[0];
        imageId = imageResult.id;
      }

      const body = {
        plan: this.mainStore.me.plan,
        title,
        active: false, //we dont activate the event at first. We do it after the user presses create event;
        imageId,
      };

      const client = this.mainStore.client;

      const result = await client.mutate<
        CreateEventMutation,
        CreateEventMutationVariables
      >({
        fetchPolicy: 'no-cache',
        mutation: CreateEventDocument,
        variables: {
          body,
        },
      });

      const event = result.data.createEvent;

      this.addEvent(event);
      this.setActiveEventId(event.id);
      // Add event implements race checks
      // The websocket adds the event to the event list;
      // we just need to make it active;
    } catch (e) {
    } finally {
      this.createEventLoading = false;
    }
  }

  async createRunsheet(body: RunsheetInput) {
    const client = this.mainStore.client;
    const result = await client.mutate<
      CreateRunsheetMutation,
      CreateRunsheetMutationVariables
    >({
      mutation: CreateRunsheetDocument,
      variables: {
        body,
      },
      update: (proxy, { data: { createRunsheet = {} as any } }) => {
        updateCache({
          proxy,
          method: CacheOperation.CREATE,
          mutationResultObject: createRunsheet,
          dataPath: 'runsheets',
          variables: {
            id: body.eventId,
          },
          query: EventWithRunsheetsDocument,
        });
      },
    });
    if (!result.data) return null;
    const created = result.data.createRunsheet;
    const selectedEvent = DashboardStore.store.activeEvent;

    runInAction(() => {
      selectedEvent.runsheets.push(created as any);
    });

    return created;
  }

  async updateRunsheet(body: RunsheetUpdate) {
    const client = this.mainStore.client;
    const result = await client.mutate<
      UpdateRunsheetMutation,
      UpdateRunsheetMutationVariables
    >({
      mutation: UpdateRunsheetDocument,
      variables: {
        body,
      },
    });
    if (!result.data) return null;
    const updated = result.data.updateRunsheet;
    const selectedEvent = DashboardStore.store.activeEvent;
    const runsheet = selectedEvent.runsheets.find((r) => r.id === body.id);

    runInAction(() => {
      set(runsheet, updated);
    });

    return updated;
  }

  updateRunsheetCache(
    eventId: number,
    runsheetId: number,
    updatedData: UpdateRunsheetMutation['updateRunsheet'],
  ) {
    const event = this.events.find((e) => e.id === eventId);

    if (!event) {
      return;
    }
    const runsheet = event.runsheets.find((r) => r.id === runsheetId);
    set(runsheet, updatedData);
  }

  async deleteRunsheet(runsheet: {
    id: number;
    eventId: number;
  }): Promise<any> {
    const client = this.mainStore.client;
    const result = await client.mutate<
      DeleteRunsheetMutation,
      DeleteRunsheetMutationVariables
    >({
      mutation: DeleteRunsheetDocument,
      variables: {
        id: runsheet.id,
      },
      update: (proxy) => {
        updateCache({
          proxy,
          query: EventWithRunsheetsDocument,
          method: CacheOperation.DELETE,
          id: runsheet.id,
          dataPath: 'runsheets',
          variables: {
            id: runsheet.eventId,
          },
        });
      },
    });
    if (!result.data) return null;
    runInAction(() => {
      const selectedEvent = DashboardStore.store.activeEvent;
      const currentRunsheet = selectedEvent.runsheets.find(
        (r) => r.id === runsheet.id,
      );
      (selectedEvent.runsheets as IObservableArray).remove(currentRunsheet);
    });
  }

  async duplicateRunsheet(id: number) {
    console.log('handleDuplicateRunsheet', id);

    const client = this.mainStore.client;
    const result = await client.mutate<
      DuplicateRunsheetMutation,
      DuplicateRunsheetMutationVariables
    >({
      mutation: DuplicateRunsheetDocument,
      variables: {
        id,
      },
      update: (proxy, { data: { duplicateRunsheet } }) => {
        updateCache({
          proxy,
          method: CacheOperation.CREATE,
          mutationResultObject: duplicateRunsheet,
          dataPath: 'runsheets',
          variables: {
            id: duplicateRunsheet.eventId,
          },
          query: EventWithRunsheetsDocument,
        });
      },
    });
    if (!result.data) return null;
    const created = result.data.duplicateRunsheet;
    const selectedEvent = DashboardStore.store.activeEvent;

    runInAction(() => {
      selectedEvent.runsheets.push(created as any);
    });

    return created;
  }

  async moveRunsheet(id: number, toEventWithId: number) {
    console.log('moveRunsheet', id, 'move to event', toEventWithId);

    const result = await this.updateRunsheet({
      id,
      eventId: toEventWithId,
    });
    if (!result) return null;

    // const created = result.data.duplicateRunsheet;
    DashboardStore.store.setActiveEventId(toEventWithId);
    const selectedEvent = DashboardStore.store.activeEvent;

    runInAction(() => {
      selectedEvent.runsheets.push(result);
    });

    return result;
  }
}

const TITLE_MISSING_ERROR = 'Please provide an event title!';

// export class CreateEventStore {
//   private static _store: CreateEventStore = null;
//   static get store() {
//     if (!this._store) {
//       this._store = new this();
//     }
//     return this._store;
//   }
// }
