import React, { ReactNode } from 'react';

import Button from '@mui/material/Button';

import colors, { styled } from '../../colors';

export function DeleteButton({
  onClick,
  className,
  children,
  disabled,
}: {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  children: ReactNode;
  disabled?: boolean;
}) {
  return (
    <StyledDeleteButton
      variant="contained"
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </StyledDeleteButton>
  );
}

const StyledDeleteButton = styled(Button)`
  background-color: ${colors.error};
  color: white;
  font-weight: 700;
  &:hover {
    background-color: ${colors.error};
  }
`;
