import { useEffect } from 'react';

import { useStripe } from '@stripe/react-stripe-js';

import { usePaymentUpdatedSubscription } from '@checkpoints/shared';

import { useBillingStore } from './BillingStore';

export const PaymentUpdatedListener = () => {
  const billingStore = useBillingStore();

  const stripe = useStripe();

  useEffect(() => {
    billingStore.setStripe(stripe);
  }, [billingStore, stripe]);

  const { data } = usePaymentUpdatedSubscription();

  useEffect(() => {
    if (data) {
      billingStore.paymentUpdated(data.paymentUpdated);
    }
  }, [billingStore, data]);

  return null;
};
