import React, { useEffect, useState } from 'react';
import { useRef } from 'react';

import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';

import { ExtendedCheckpoint } from '@checkpoints/shared';

import {
  CheckCircleIcon,
  RadioButtonUncheckedIcon,
  SettingsIcon,
} from '../common/Icons';
import { Handle } from '../table-cells';
import { CheckpointsStore } from '../../stores/checkpointsStore';
import { TrackingDirectorAvatar } from '../avatar';
import colors from '../../colors';
import { useRunsheetData } from '../../pages/checkpoints/RunsheetHeader';
import { Checkbox } from '../checkbox';
import { wait } from '../../utils/promise';

import { FIRST_CELL_WIDTH } from './StyledTableCells';

export const FirstCell = observer(function FirstCell({
  dragHandleProps,
  row,
  rowIndex,
}: {
  dragHandleProps: any;
  row: ExtendedCheckpoint;
  rowIndex: number;
}) {
  const editMode = CheckpointsStore.store.editMode;
  const multiSelectMode = CheckpointsStore.store.multiSelectMode;
  const multiSelected = CheckpointsStore.store.multiSelected;
  const [multiSelectTransitioned, setMultiSelectTransitioned] = useState(false);
  const trackedRow = CheckpointsStore.store.trackedRowId === row.id;
  const isBeforeTracked = CheckpointsStore.store.trackedRowIndex > rowIndex;
  const { classes, cx } = useTableStyles();

  const firstEditCell = useRef();

  const handleCheckboxClicked = () => {
    console.log('handleCheckboxClicked', row.id);
    const rowId = row.id;
    CheckpointsStore.store.toggleMultiSelection(rowId);
  };

  const handleSettingsClicked = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (CheckpointsStore.store.rowSettingsActive.row === row) {
      CheckpointsStore.store.clearRowSettings();

      return;
    }
    CheckpointsStore.store.activateRowSettings({
      row,
      anchorEl: firstEditCell.current,
    });
  };

  useEffect(() => {
    async function waitForTransition() {
      if (multiSelectMode) {
        await wait(300);
        setMultiSelectTransitioned(true);
      } else {
        setMultiSelectTransitioned(false);
      }
    }
    waitForTransition();
  }, [multiSelectMode]);

  return (
    <div className={cx(classes.FirstTableCell)}>
      {editMode ? (
        <div className={cx(classes.FirstEditCell)} ref={firstEditCell}>
          {multiSelectTransitioned ? (
            <Checkbox
              value={multiSelected.includes(row.id)}
              onChangeValue={handleCheckboxClicked}
            />
          ) : (
            <>
              <Handle dragHandleProps={dragHandleProps} />
              <IconButton
                color="inherit"
                onClick={handleSettingsClicked}
                size="large"
              >
                <SettingsIcon />
              </IconButton>
            </>
          )}
          <span>{rowIndex + 1}</span>
        </div>
      ) : (
        <div className={cx(classes.NonEditModeCell)}>
          <Handle dragHandleProps={dragHandleProps} hidden />
          {trackedRow ? (
            <div>
              <TrackingButton />
            </div>
          ) : (
            <div>
              <DirectorButton checkpointId={row.id}>
                {isBeforeTracked ? (
                  <CheckCircleIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </DirectorButton>
            </div>
          )}

          <span>{rowIndex + 1}</span>
        </div>
      )}
    </div>
  );
});

const TrackingButton = () => {
  const handleClick = () => {
    CheckpointsStore.store.updateTrackedCheckpoint({ checkpointId: null });
  };

  return <TrackingDirectorAvatar onClick={handleClick} />;
};

const DirectorButton = observer(function DirectorButton({
  children,
  checkpointId,
}: {
  children: JSX.Element;
  checkpointId: number;
}) {
  const director = CheckpointsStore.store.userIsDirector;
  const selectedRunsheetId = CheckpointsStore.store.selectedRunsheetId;
  const { runsheet } = useRunsheetData(selectedRunsheetId);

  const runbytime = runsheet?.runbytime;

  const handleClick = () => {
    CheckpointsStore.store.updateTrackedCheckpoint({ checkpointId });
  };

  if (director && !runbytime) {
    return (
      <IconButton onClick={handleClick} size="large">
        {children}
      </IconButton>
    );
  }

  return children;
});

const useTableStyles = makeStyles()(() => ({
  NonEditModeCell: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    minHeight: '48px',

    '> div': {
      width: '70px',
      flex: '0 0 70px',
      display: 'flex',
      justifyContent: 'center',
    },

    '> span': {
      color: colors.gray,
      textAlign: 'right',
      flex: '1 1 auto',
      marginRight: '20px',
    },

    svg: {
      color: colors.primary,
    },
  },
  FirstEditCell: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    '.handle': {
      marginLeft: '15px',
      display: 'flex',
      alignItems: 'center',
    },
    'button, span': {
      alignSelf: 'center',
    },
    '> span': {
      marginRight: '15px',
    },
  },
  FirstTableCell: {
    width: `${FIRST_CELL_WIDTH}px`,
    flex: '0 0 auto',
    padding: '4px 0',
    border: '0',
  },
}));
