import * as React from 'react';

import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';

import { styled } from '../../colors';

const DEFAULT_ROWS = 2;

export function AutoresizingInputField(props: {
  handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement>;
  handleBlur: React.FocusEventHandler<HTMLTextAreaElement>;
  value: string;
}) {
  const { handleKeyDown, handleBlur, value: node } = props;
  const [val, setVal] = React.useState(node);

  React.useEffect(() => {
    setVal(node);
  }, [node]);

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event,
  ) => {
    setVal(event.target.value);
  };

  const moveCaretAtEnd: React.FocusEventHandler<HTMLTextAreaElement> = (e) => {
    const temp_value = e.target.value;
    e.target.value = '';
    e.target.value = temp_value;
  };

  return (
    <TextareaAutosize
      className="cell__input"
      value={val}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      autoFocus
      onFocus={moveCaretAtEnd}
      // style={{
      //   outline: 0,
      // }}
    />
  );
}

export function AutoresizingInputField2(props: {
  handleKeyDown: (e: any) => void;
  handleBlur: (e: any) => void;
  value: string;
}) {
  const { handleKeyDown, handleBlur, value: node } = props;
  const [val, setVal] = React.useState(node);
  const [rows, setRows] = React.useState(1);
  const ref = React.useRef(null);

  const updateRows = (target: EventTarget & HTMLTextAreaElement) => {
    const textareaLineHeight = 19; //or 20
    // const previousRows = target.rows;
    // target.rows = DEFAULT_ROWS; // reset number of rows in textarea
    const currentRows = ~~(target.scrollHeight / textareaLineHeight); //~~ = math floor
    target.rows = Math.max(1, currentRows);
    console.log('setRows', currentRows);

    setRows(currentRows);
  };

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event,
  ) => {
    updateRows(event.target);
    setVal(event.target.value);
  };

  React.useEffect(() => {
    console.log('useEffect', ref.current);

    const textarea = ref.current.firstChild.firstChild;
    console.log('textarea', textarea);

    updateRows(textarea);
    textarea.setSelectionRange(textarea.value.length, textarea.value.length);
  }, []);

  React.useEffect(() => {
    setVal(node);
  }, [node]);

  return (
    <TableTextInput
      // ref={ref}
      InputProps={{
        inputRef: ref,
      }}
      multiline
      rows={rows}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      autoFocus
      value={val}
      onChange={handleChange}
    />
  );
}

export const TableTextInput = styled(TextField)`
  width: 100%;
  height: 100%;
  padding: 12px;
  > div {
    color: inherit;
    padding: 0;
  }
  /* input,
  textarea {
    color: inherit;
  } */
`;
