import { makeStyles } from 'tss-react/mui';

import colors from '../../colors';

export const useStyles = makeStyles<void, 'arrow'>({
  name: 'ColumnSettingsPopover',
})((theme, _params, classes) => ({
  popper: {
    zIndex: 10,
    ['&[data-popper-placement*="bottom"] .arrow']: {
      top: 0,
      marginTop: '-0.9em',
      left: '7px',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    ['&[data-popper-placement*="top"] .arrow']: {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    ['&[data-popper-placement*="right"] .arrow']: {
      top: '50%',
      transform: 'translate(0%, -50%)',
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    ['&[data-popper-placement*="left"] .arrow']: {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    display: 'none',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
}));

export const staticStyles = {
  delete: {
    padding: '6px',
    fontSize: '30px',
  },
};

export const usePopperBottomCenterStyle = makeStyles<void, 'arrow'>({
  name: 'PopperBottomCenter',
})((theme, _params, classes) => ({
  popper: {
    zIndex: 10,
    ['&[data-popper-placement*="right"] .arrow']: {
      top: '50%',
      transform: 'translate(0%, -50%)',
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    ['&[data-popper-placement*="bottom"] .arrow']: {
      top: 10,
      left: '50%',
      // transform: 'translate(-50%, 0)',
      transform: `translate(-50%, 0) rotate(45deg)`,
      width: 10,
      height: 10,
      backgroundColor: colors.primary,
      border: `1px solid white`,
      borderBottom: 0,
      borderRight: 0,
      opacity: 1,
    },
  },
  arrow: {
    position: 'absolute',
    width: 10,
    height: 10,
    '&.editMode': {
      backgroundColor: `${colors.dark} !important`,
      // border: `0 !important`,
    },
  },
}));

export const usePlainPopperBottomStyle = makeStyles<void, 'arrow'>({
  name: 'PlainPopperBottom',
})((theme, _params, classes) => ({
  button: {
    fontSize: '16px',
    textTransform: 'none',
    justifyContent: 'flex-start',
  },
  popper: {
    zIndex: 2000,
    paddingTop: '4px',
    [`&[data-popper-placement*="bottom"] .${classes.arrow}`]: {
      top: 0,
      // marginTop: '-5px',
      left: '50%',
      // transform: 'translate(-50%, 0)',
      transform: `translate(-50%, 0) rotate(45deg)`,
      width: 10,
      height: 10,
      backgroundColor: 'white',
      border: `1px solid white`,
      borderBottom: 0,
      borderRight: 0,
      opacity: 1,
    },
  },
  arrow: {
    position: 'absolute',
    width: 10,
    height: 10,
    // '&.editMode': {
    //   backgroundColor: `${colors.dark} !important`,
    //   // border: `0 !important`,
    // },
  },
}));

// export const PrimaryTextField = withStyles({
//   root: {
//     // '& label.Mui-focused': {
//     //   color: 'green',
//     // },
//     '& .MuiInput-underline:after': {
//       borderBottomColor: colors.primary,
//     },
//     // '& .MuiOutlinedInput-root': {
//     //   '& fieldset': {
//     //     borderColor: 'red',
//     //   },
//     //   '&:hover fieldset': {
//     //     borderColor: 'yellow',
//     //   },
//     //   '&.Mui-focused fieldset': {
//     //     borderColor: 'green',
//     //   },
//     // },
//   },
// })(TextField);
