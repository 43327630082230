import { withStyles } from 'tss-react/mui';
import Switch from '@mui/material/Switch';

import colors from '../../colors';

export const WhiteSwitch = withStyles(Switch, {
  switchBase: {
    color: '#fff',
    '&.Mui-checked': {
      color: '#fff',
    },
    '&.Mui-checked + $track': {
      backgroundColor: colors.primary,
    },
  },
  checked: {},
  track: {},
});
