import colors, { mediaTablet, styled } from '../../colors';

export const OuterContainer = styled('div')`
  min-height: 100vh;
  width: 100%;
  margin: 0;

  display: flex;
  flex-direction: column;

  /* Ensure this is in sync with dashboard.html */
  background-attachment: fixed;
  background: linear-gradient(
    171deg,
    ${colors.editModeBackgroundFirst},
    ${colors.editModeBackgroundSecond}
  );
  color: white;

  & a {
    color: white;
  }
  .MuiFormLabel-root.Mui-focused,
  .MuiInputLabel-root.Mui-focused {
    color: white;
  }
  .MuiInput-root::after {
    border-bottom: 2px solid white;
  }
`;

export const InnerContainer = styled('div')`
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;

  padding: 20px;

  & @media screen and (max-height: 600px) {
    padding: 20px;
  }
  & ${mediaTablet} {
    padding: 20px;
  }
`;
