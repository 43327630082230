import { FetchResult } from '@apollo/client';
import { runInAction } from 'mobx';

import {
  MutationType,
  OnCheckpointMutatedDocument,
  OnCheckpointMutatedSubscription,
  OnRunsheetReloadedDocument,
  RunsheetWithCheckpointsDocument,
  RunsheetWithCheckpointsQuery,
  RunsheetWithCheckpointsQueryVariables,
  OnRunsheetTrackingUpdatedDocument,
} from '@checkpoints/shared';

import { CheckpointsStore } from '../../stores/checkpointsStore';
import MainStore from '../../stores/Store';
import { scrollToTracked } from '../../stores/helpers/ScrollToTracked';

/**
 * CheckpointsListener listens for online users for the selected runsheet
 * This updates the green circles on the user avatars that become online
 * 
 *  This keeps track of the runsheet-users-list
  Adding or deleting users are recoreded
 */
export class CheckpointsListener {
  private static _store: CheckpointsListener = null;
  static get store() {
    if (!this._store) {
      this._store = new CheckpointsListener();
    }

    return this._store;
  }

  checkpointsListener: ZenObservable.Subscription;
  runsheetReloadedListener: ZenObservable.Subscription;
  runsheetTrackingListener: ZenObservable.Subscription;

  fetchInitialData = (selectedRunsheetId: number) => {
    if (!selectedRunsheetId) return;

    console.log('fetchInitialData - RunsheetWithCheckpointsQuery');
    MainStore.store.client
      .query<
        RunsheetWithCheckpointsQuery,
        RunsheetWithCheckpointsQueryVariables
      >({
        query: RunsheetWithCheckpointsDocument,
        variables: { id: selectedRunsheetId },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const data = result.data;

        if (!data || !data.runsheet) {
          return null;
        }
        const userRunsheetDetails = data.userRunsheetDetails || {};

        const {
          checkpoints,
          headers,
          startTime,
          trackedCheckpointId,
          showSeconds,
          title,
        } = data.runsheet;

        if (!startTime || !checkpoints) {
          return null;
        }

        const { headers: userHeaderOverrides, notes } = userRunsheetDetails;

        runInAction(function SetIntialData() {
          //Keep is admin in sync with store.
          CheckpointsStore.store.setAdmin(userRunsheetDetails.isAdmin);
          //Starttime is needed when cells are rendered.
          CheckpointsStore.store.setRunsheetStartTime(startTime);

          CheckpointsStore.store.setCheckpoints(checkpoints);
          CheckpointsStore.store.setTrackedRowId(trackedCheckpointId);
          CheckpointsStore.store.setShowSeconds(showSeconds);
          CheckpointsStore.store.runsheetTitle = title;

          // Set default header overrides to empty object to replace the last runsheet overrides;
          CheckpointsStore.store.setCustomHeaders({
            customHeaders: headers,
            userHeaderOverrides: userHeaderOverrides || {},
          });
          CheckpointsStore.store.setUserNotes(notes);
        });
      });
  };

  startListening = (runsheetId: number) => {
    if (!runsheetId) {
      return;
    }

    this.fetchInitialData(runsheetId);

    const client = MainStore.store.client;

    // Fired when Checkpoints are updated
    this.checkpointsListener = client
      .subscribe({
        query: OnCheckpointMutatedDocument,
        fetchPolicy: 'no-cache',
        variables: {
          runsheetId: runsheetId,
        },
      })
      .subscribe(this.onCheckpointMutated);

    // If should reload all runsheet data
    this.runsheetReloadedListener = client
      .subscribe({
        query: OnRunsheetReloadedDocument,
        variables: { id: runsheetId },
        fetchPolicy: 'no-cache',
      })
      .subscribe(() => {
        this.fetchInitialData(runsheetId);
      });

    // Active checkpoint
    this.runsheetTrackingListener = client
      .subscribe({
        query: OnRunsheetTrackingUpdatedDocument,
        variables: { id: runsheetId },
        fetchPolicy: 'no-cache',
      })
      .subscribe((result) => {
        const id = result.data.runsheetTrackingUpdated;
        CheckpointsStore.store.setTrackedRowId(id);

        //If no id, we dont scroll;
        if (!id) {
          return;
        }
        if (CheckpointsStore.store.tracking) {
          scrollToTracked();
        }
      });
  };

  onCheckpointMutated = (
    result: FetchResult<OnCheckpointMutatedSubscription>,
  ) => {
    const { mutation, node: checkpoint } = result.data.checkpointMutated;

    switch (mutation) {
      case MutationType.Created:
        CheckpointsStore.store.createCheckpoint(checkpoint);
        break;
      case MutationType.Updated:
        CheckpointsStore.store.updateCheckpointFromSocket(checkpoint);
        break;
      case MutationType.Deleted:
        CheckpointsStore.store.deleteCheckpoint(checkpoint);
        break;

      default:
        break;
    }
  };

  stopListening = () => {
    if (this.checkpointsListener) {
      this.checkpointsListener.unsubscribe();
      this.checkpointsListener = null;

      this.runsheetReloadedListener.unsubscribe();
      this.runsheetReloadedListener = null;

      this.runsheetTrackingListener.unsubscribe();
      this.runsheetTrackingListener = null;
    }
  };
}
