import React, {
  ReactNode,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from 'react';

import classNames from 'classnames';

import { styled } from '../../colors';

// import { useWindowWidth } from '../../../utils/useWindowListener';
export const DashedContainer = function DashedContainer({
  full = true,
  children,
  directChildren = null,
  className = '',
  reloadData,
}: {
  full?: boolean;
  children: ReactNode;
  directChildren?: ReactNode;
  className?: string;
  reloadData?: any;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    const measureSVG = () => {
      if (!containerRef.current) {
        return;
      }

      const { width, height } = containerRef.current.getBoundingClientRect();

      setSize(() => ({
        width,
        height,
      }));
    };

    setTimeout(() => {
      measureSVG();
    }, 50);
    window.addEventListener('resize', measureSVG);

    return () => window.removeEventListener('resize', measureSVG);
  }, [reloadData]);

  return (
    <StyledDashedContainer
      ref={containerRef}
      full={full}
      className="dashed_container"
    >
      <svg
        className={classNames({
          dashed_svg: true,
          'radial-cutout': Boolean(directChildren),
        })}
        xmlns="http://www.w3.org/2000/svg"
        width={size.width}
        height={size.height}
      >
        {/* {innerSvg && ( */}
        <g
          id="Rectangle_354"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          strokeDasharray="10"
          width={size.width}
          height={size.height}
          // This is to make the g tag react to the height
          className={'absolute'}
        >
          <rect
            // width="calc(100% - 2px)"
            // height="calc(100% - 2px)"
            width={size.width}
            height={size.height}
            rx="7"
            stroke="none"
          />
          <rect
            // width="calc(100% - 2px)"
            // height="calc(100% - 2px)"
            width={size.width}
            height={size.height}
            x="1"
            y="1"
            rx="3"
            fill="none"
          />
        </g>
        {/* )} */}
      </svg>
      <ScrollView className="scrollbar">
        <ItemContainer className={className}>{children}</ItemContainer>
      </ScrollView>
      {directChildren}
    </StyledDashedContainer>
  );
};

export const SimpleDashedContainer = ({
  children,
  className = '',
}: {
  children: ReactNode;
  className?: string;
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [size, setSize] = useState({ width: 0, height: 300 });

  useEffect(() => {
    const measureSVG = () => {
      if (!svgRef.current) {
        return;
      }

      const { width, height } = svgRef.current?.getBoundingClientRect();

      setSize(() => ({
        width,
        height,
      }));
    };

    measureSVG();
    window.addEventListener('resize', measureSVG);

    return () => window.removeEventListener('resize', measureSVG);
  }, []);

  return (
    <StyledDashedContainer auto={true} className="dashed_container">
      <svg
        className="dashed_svg"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
        width={size.width}
        height={size.height}
      >
        <g
          id="Rectangle_354"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          strokeDasharray="10"
          width={size.width}
          height={size.height}
        >
          <rect
            // width="calc(100% - 2px)"
            // height="calc(100% - 2px)"
            width={size.width}
            height={size.height}
            rx="7"
            stroke="none"
          />
          <rect
            // width="calc(100% - 2px)"
            // height="calc(100% - 2px)"
            width={size.width}
            height={size.height}
            x="1"
            y="1"
            rx="3"
            fill="none"
          />
        </g>
      </svg>
      <ItemContainer className={className}>{children}</ItemContainer>
    </StyledDashedContainer>
  );
};
interface StyledDashedContainerProps {
  full?: boolean;
  auto?: boolean;
}
const StyledDashedContainer = styled('div')<StyledDashedContainerProps>`
  /* height: 100%; */
  ${(p) => (p.auto ? `height: auto;` : `height: 100%;`)}

  ${(p) =>
    p.full
      ? `@media screen and (max-width: 800px) {
    height: 500px;
  }`
      : ''}
  
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  > svg.dashed_svg {
    /* z-index: -1; */
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    g {
      width: 100%;
      height: 100%;
      &.absolute {
        position: absolute;
      }
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    rect {
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  /* border: 2px dashed white; */
`;

const ScrollView = styled('div')`
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  display: flex;
`;

const ItemContainer = styled('div')`
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
`;
