import React, { useCallback, useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { parse } from 'query-string';
import {
  useGoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import { Box } from '@mui/material';

import { useCreateUserMutation } from '@checkpoints/shared';

import { styled } from '../../colors';
import { ContainedButton } from '../../components/button';
import { InnerContainer, OuterContainer } from '../../components/layouts';
import { Logo } from '../../components/server-image';
import MainStore from '../../stores/Store';
import { Header3 } from '../Dashboard/DashboardHeader';
import { useFormStyles } from '../../components/form';

const { VITE_RECAPTCHA_KEY } = import.meta.env;

const default_user = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  repeat_password: '',
};

const variants = {
  open: { opacity: 1, height: 'auto', overflow: 'hidden' },
  closed: { opacity: 0, height: 0, overflow: 'hidden' },
};

export const Signup = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={VITE_RECAPTCHA_KEY}
      language="en"
      useRecaptchaNet
      scriptProps={{ async: true, defer: true, appendTo: 'head' }}
    >
      <Wrapped />
    </GoogleReCaptchaProvider>
  );
};

const Wrapped = () => {
  const [signup] = useCreateUserMutation();
  const location = useLocation();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { classes, cx } = useFormStyles();

  const [state, setState] = useState<typeof default_user>(default_user);
  const [success, setSuccess] = useState(false);
  const { code, email } = parse(location.search);

  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback((e) => {
    const name = e.target.name;
    const value = e.target.value;
    setState((s) => ({ ...s, [name]: value }));
  }, []);

  const handleSubmit = useCallback(() => {
    signup({
      variables: {
        body: state,
      },
    }).then(({ data }) => {
      const verifiedEmail = data.createUser.verifiedEmail;

      if (verifiedEmail) {
        navigate('/welcome');

        return;
      }
      setSuccess(true);
    });

    return false;
  }, [navigate, signup, state]);

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');

      return;
    }

    const token = await executeRecaptcha('checkpoints_signup');
    console.log('token', token);

    handleSubmit();
    // Do whatever you want with the token
  }, [executeRecaptcha, handleSubmit]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  // useEffect(() => {
  //   handleReCaptchaVerify();
  // }, [handleReCaptchaVerify]);

  useEffect(() => {
    MainStore.store.logout(false);
  }, []);

  useEffect(() => {
    if (!code) {
      return;
    }
    setState((s) => ({
      ...s,
      email: email as string,
    }));
  }, [code, email]);

  return (
    <OuterContainer>
      <InnerContainer>
        <div className="flex">
          <form
            className={cx(classes.borderedForm)}
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();

              handleReCaptchaVerify();
            }}
          >
            <div className={cx(classes.logoImageDiv)}>
              <Logo variant="white" />
            </div>
            <motion.div
              variants={variants}
              animate={!success ? 'open' : 'closed'}
            >
              <div className={cx(classes.flexFormDiv)}>
                <Header3>Create a new account</Header3>
                <TextField
                  label={'First name'}
                  name="first_name"
                  autoComplete="given-name"
                  value={state.first_name}
                  onChange={handleChange}
                />
                <TextField
                  label={'Last name'}
                  name="last_name"
                  autoComplete="family-name"
                  value={state.last_name}
                  onChange={handleChange}
                />
                <TextField
                  type="email"
                  label={'Email'}
                  name="email"
                  autoComplete="email"
                  disabled={Boolean(email)}
                  value={state.email}
                  onChange={handleChange}
                />
                <TextField
                  name="password"
                  type="password"
                  label={'Password'}
                  autoComplete="new-password"
                  value={state.password}
                  onChange={handleChange}
                />

                <TextField
                  type="password"
                  name="repeat_password"
                  label={'Repeat password'}
                  value={state.repeat_password}
                  onChange={handleChange}
                  autoComplete="new-password"
                />
                <Box sx={{ display: 'flex' }}>
                  {/* <Checkbox
                    value={validated}
                    onChangeValue={handleReCaptchaVerify}
                    props={{
                      inputProps: {
                        'aria-label': 'Verify that you are human',
                      },
                    }}
                  /> */}
                  <div id="captcha-button"></div>
                </Box>
                <p>
                  {`By signing up, you confirm that you've read and accepted our `}
                  <StyledLink
                    href="https://checkpoints.se/terms-and-conditions"
                    target="_blank"
                    className=""
                    rel="noreferrer"
                  >
                    Terms of Service
                  </StyledLink>
                  {` and `}
                  <StyledLink
                    href="https://checkpoints.se/privacypolicy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </StyledLink>
                </p>
                <div style={{ height: 20 }}></div>
                <ContainedButton type="submit">Create account</ContainedButton>
              </div>
            </motion.div>
            <motion.div
              animate={success ? 'open' : 'closed'}
              variants={variants}
              initial={false}
            >
              <Header3>Your account has been created</Header3>
              <p>
                Before logging in, please click the verification link that has
                been sent to your email.
              </p>
              <ContainedButton
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/login');

                  return false;
                }}
              >
                Login
              </ContainedButton>
            </motion.div>
          </form>
        </div>
      </InnerContainer>
    </OuterContainer>
  );
};

const StyledLink = styled('a')`
  text-decoration: none;
  font-weight: 700;
`;
