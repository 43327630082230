import React from 'react';

import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import 'formdata-polyfill';
import * as mobx from 'mobx';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { TssCacheProvider } from 'tss-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import sv from 'date-fns/locale/sv';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

import { theme } from './colors';
import { ConfirmDialog, PromptDialog } from './components/modals';
import { Notifications } from './components/notifications/Snackbar';
import Routes from './routes';
import { MainStoreContext } from './stores';
import { createApolloClient } from './stores/apollo';
import MainStore from './stores/Store';
import './styles/app.scss';
import { GlobalStyle } from './styles/GlobalStyle';

const { VITE_APP_NAME } = import.meta.env;

//Initialize client
const store = MainStore.store;

if (!store.client) {
  console.log('Creating apollo client');

  const { client, wsLink } = createApolloClient(store);

  store.client = client;
  store.wsLink = wsLink;

  if (import.meta.env.MODE === 'development') {
    // Adds messages only in a dev environment
    loadDevMessages();
    loadErrorMessages();
  }
}

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});
const tssCache = createCache({
  key: 'tss',
});

function App() {
  // const mainStore = useMainStore();
  const client = store.client;
  let title = 'Checkpoints';

  if (VITE_APP_NAME) {
    title = VITE_APP_NAME;
  }

  return (
    <React.StrictMode>
      <HelmetProvider>
        <ApolloProvider client={client}>
          <CacheProvider value={muiCache}>
            <TssCacheProvider value={tssCache}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={sv}
              >
                <ThemeProvider theme={theme}>
                  <BrowserRouter>
                    <CssBaseline />
                    <GlobalStyle />
                    <Helmet titleTemplate={title + ' - %s'} title={title} />
                    <Routes />
                    <ConfirmDialog />
                    <PromptDialog />
                    <Notifications />
                  </BrowserRouter>
                </ThemeProvider>
              </LocalizationProvider>
            </TssCacheProvider>
          </CacheProvider>
        </ApolloProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
}

export default function WrappedApp() {
  return (
    <MainStoreContext.Provider value={store}>
      <App />
    </MainStoreContext.Provider>
  );
}
