import React, { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react';

import {
  useDeleteUserMutation,
  useRemoveImageMutation,
  useUpdateUserMutation,
  useUpdateUserSecurityMutation,
} from '@checkpoints/shared';

import { styled } from '../../colors';
import { ContainedButton, DeleteButton } from '../../components/button';
import { FlexBetween, InfoIcon } from '../../components/common';
import { TooltipWrapper } from '../../components/tooltip';
import { ModalStore } from '../../stores/ModalStore';
import MainStore from '../../stores/Store';
import { Header3 } from '../Dashboard/DashboardHeader';
import { useFormStyles } from '../../components/form';

import { ProfileImage } from './ProfileDiv';

const default_user = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  new_password: '',
  repeat_password: '',
};

function useDeleteAccount() {
  const [del] = useDeleteUserMutation();

  const handleDeleteAccount = async () => {
    const result = await ModalStore.store.confirm(
      'Are you sure you want to delete your account? This action can not be reversed.',
    );
    if (!result) return;
    await del();
    await MainStore.store.logout();
  };

  return { handleDeleteAccount };
}

export const Profile = observer(function Profile() {
  return (
    <>
      <AccountProfile />
      <AccountSecurity />
    </>
  );
});

const AccountProfile = observer(function AccountProfile() {
  const me = MainStore.store.me;
  const [state, setState] = useState<typeof default_user>({
    ...default_user,
    ...me,
  });
  const { classes, cx } = useFormStyles();

  useEffect(() => {
    if (me) {
      setState((s) => ({ ...s, ...me }));
    }
  }, [me]);

  const onChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    setState((s) => ({ ...s, [name]: value }));
  };

  const [update] = useUpdateUserMutation();

  const [removeImage, removeImgResult] = useRemoveImageMutation();
  const { handleDeleteAccount } = useDeleteAccount();

  const updateForm: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { first_name, last_name } = state;

    update({
      variables: {
        body: {
          first_name,
          last_name,
        },
      },
    }).then(() => {
      MainStore.store.displayNotification({
        message: 'Profile updated!',
      });
    });

    return false;
  };

  const onUpdateImage = async (imageId: number) => {
    const { first_name, last_name } = state;

    await update({
      variables: {
        body: {
          first_name,
          last_name,
          imageId,
        },
      },
    });
  };

  const onRemoveImage = async () => {
    const result = await ModalStore.store.confirm(
      'Are you sure you want to remove your profile picture?',
    );

    if (result) {
      await removeImage();
    }
  };

  return (
    <form className={cx(classes.flexForm)}>
      <Header3>Profile:</Header3>
      <ProfileImage
        user={me}
        onUpdateImage={onUpdateImage}
        onRemoveImage={onRemoveImage}
      />
      <TextField
        label={'First name'}
        name="first_name"
        value={state.first_name}
        onChange={onChange}
      />
      <TextField
        label={'Last name'}
        name="last_name"
        value={state.last_name}
        onChange={onChange}
      />
      <div className="spacer" />
      <FlexBetween>
        <DeleteButton onClick={handleDeleteAccount}>
          Delete account
        </DeleteButton>
        <ContainedButton onClick={updateForm}>Update profile</ContainedButton>
      </FlexBetween>
    </form>
  );
});

const AccountSecurity = observer(function AccountSecurity() {
  const [updateSecuriry] = useUpdateUserSecurityMutation({
    fetchPolicy: 'no-cache',
  });
  const { classes, cx } = useFormStyles();

  const me = MainStore.store.me;
  const [state, setState] = useState({
    password: '',
    new_password: '',
    repeat_password: '',
    email: me.email,
  });

  const onChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    setState((s) => ({ ...s, [name]: value }));
  };

  const updateSecurityForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { password, new_password, repeat_password } = state;

    updateSecuriry({
      variables: {
        body: {
          password,
          new_password,
          repeat_password,
        },
      },
    }).then(() => {
      MainStore.store.displayNotification({
        message: 'Password updated!',
      });
      setState((s) => ({
        ...s,
        password: '',
        new_password: '',
        repeat_password: '',
      }));
    });

    return false;
  };

  return (
    <form className={cx(classes.flexForm)} onSubmit={updateSecurityForm}>
      <Header3>Security:</Header3>
      <DisabledEmailField>
        <TextField
          type="email"
          label={'Email'}
          name="email"
          disabled
          autoComplete="username"
          value={state.email}
        />
        <TooltipWrapper
          title={
            <div>
              Please contact us if you wish <br />
              to change your email adress.
            </div>
          }
          aria-label="email_disabled"
        >
          <InfoIcon />
        </TooltipWrapper>
      </DisabledEmailField>
      <TextField
        name="password"
        type="password"
        label={'Current password'}
        autoComplete="current-password"
        value={state.password}
        onChange={onChange}
      />
      <TextField
        name="new_password"
        type="password"
        label={'New password'}
        autoComplete="new-password"
        value={state.new_password}
        onChange={onChange}
      />
      <TextField
        type="password"
        name="repeat_password"
        label={'Repeat password'}
        value={state.repeat_password}
        onChange={onChange}
        autoComplete="new-password"
      />
      <div className="spacer" />
      <ContainedButton>Update Security</ContainedButton>
    </form>
  );
});

const DisabledEmailField = styled('div')`
  position: relative;

  svg {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(0, -40%);
    opacity: 0.8;
  }
`;
