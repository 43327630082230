import React, { forwardRef } from 'react';

import { serverImgUrl } from '../../config';

interface ServerImageProps {
  src: string;
  [prop: string]: string;
}

export const ServerImage = forwardRef(
  (props: ServerImageProps, ref: React.Ref<HTMLImageElement>) => {
    const { src, ...rest } = props;

    const imgUrl = serverImgUrl(src);

    return <img ref={ref} src={imgUrl} {...rest} />;
  },
);
