import { styled } from '../../colors';

export const Header = styled('h1')`
  color: white;
  font-size: 10vw;
  font-weight: 100;
  margin: 20px 0 0 0;
  width: 100%;
  @media screen and (min-width: 800px) {
    font-size: 70px;
  }
`;

export const Header2 = styled('h3')`
  color: white;
  font-size: 32px;
  font-weight: 100;
  margin: 10px 0 8px 0;
`;

export const Header3 = styled('h3')`
  color: white;
  font-size: 22px;
  font-weight: 100;
  margin: 10px 0 8px 0;
`;

export const Header4 = styled('h4')`
  color: white;
  font-size: 18px;
  font-weight: 100;
  margin: 10px 0 8px 0;
`;
