import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  ClickAwayListener,
  Fade,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
} from '@mui/material';
import { observer } from 'mobx-react';

import { CheckpointsStore } from '../../stores/checkpointsStore';

import { ArrowedButton } from './PopoverUtils';

type DuplicateMode = 'afterEach' | 'afterOne' | null;

export const DuplicatePopover = observer(
  ({
    handleDuplication,
  }: {
    handleDuplication: (mode: DuplicateMode, id?: number) => void;
  }) => {

    const { row } = CheckpointsStore.store.rowSettingsActive;
    const checkpoints = CheckpointsStore.store.sortedCheckpoints;
    const multiSelectMode = CheckpointsStore.store.multiSelectMode;

    const duplicateButtonRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [openSpecificCkptMenu, setOpenSpecificCkptMenu] = useState(false);
    const subMenuRef = useRef<HTMLDivElement>(null);

    const handleToggleOpen = useCallback(() => {
      console.log('handleToggleMenu');

      setOpen((curr) => !curr);
    }, []);

    const handleToggleMenu = useCallback(() => {
      console.log('handleToggleMenu');

      setOpenSpecificCkptMenu((curr) => !curr);
    }, []);

    useEffect(() => {
      if (!open) {
        setOpenSpecificCkptMenu(false);
      }
    }, [open]);

    const id = open && 'duplicateCheckpointPopover';
    const specificCkptMenuId =
      openSpecificCkptMenu && 'specificCkptPopoverList';

    return (
      <>
        <ArrowedButton
          ref={duplicateButtonRef}
          onClick={() => {
            handleToggleOpen();
          }}
          arrowDirection="down"
        >
          <ListItemText primary="Duplicate" />
        </ArrowedButton>
        {open && duplicateButtonRef.current && (
          <Popper
            id={id}
            open={open}
            anchorEl={duplicateButtonRef.current}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps}>
                <Paper elevation={4}>
                  <ClickAwayListener
                    onClickAway={() => {
                      setOpen(false);
                    }}
                  >
                    <List>
                      <ListItemButton
                        onClick={() => {
                          handleDuplication('afterEach');
                        }}
                      >
                        {multiSelectMode && (<ListItemText primary="After each Checkpoint" />)}
                        {!multiSelectMode && (<ListItemText primary="After this Checkpoint" />)}
                                       
                      </ListItemButton>
                      <ArrowedButton
                        ref={subMenuRef}
                        onClick={handleToggleMenu}
                        arrowDirection="right"
                      >
                        <ListItemText primary="After specific Checkpoint" />
                      </ArrowedButton>
                      {openSpecificCkptMenu && (
                        <Popper
                          id={specificCkptMenuId}
                          open={openSpecificCkptMenu}
                          anchorEl={subMenuRef.current}
                          placement="right-start"
                          transition
                        >
                          {({ TransitionProps }) => (
                            <Fade {...TransitionProps}>
                              <Paper elevation={4}>
                                <List
                                  sx={{ maxHeight: '300px', overflowY: 'auto' }}
                                >
                                  {checkpoints
                                    // .filter((ckpt) => ckpt.id !== row.id)
                                    .map((c, idx) => (
                                      <ListItemButton
                                        key={c.id}
                                        data-index={idx}
                                        onClick={(e) => {
                                          const buttonIdx = parseInt(
                                            (e.currentTarget as HTMLDivElement)
                                              .dataset.index,
                                          );

                                          handleDuplication(
                                            'afterOne',
                                            buttonIdx,
                                          );
                                        }}
                                      >
                                        <ListItemText
                                          primary={`${idx + 1}. ${
                                            c.title || 'Untitled Checkpoint'
                                          }`}
                                        />
                                      </ListItemButton>
                                    ))}
                                </List>
                              </Paper>
                            </Fade>
                          )}
                        </Popper>
                      )}
                    </List>
                  </ClickAwayListener>
                </Paper>
              </Fade>
            )}
          </Popper>
        )}
      </>
    );
  },
);
