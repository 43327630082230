// eslint-disable-next-line import/no-unresolved
const { VITE_SERVER_IP, VITE_FORCE_HTTP } = import.meta.env;

let serverUrl = `https://${VITE_SERVER_IP}`;
let wsurl = `wss://${VITE_SERVER_IP}/graphql`;
let httpurl = `https://${VITE_SERVER_IP}/graphql`;

if (VITE_FORCE_HTTP === 'true') {
  serverUrl = `http://${VITE_SERVER_IP}`;
  wsurl = `ws://${VITE_SERVER_IP}/graphql`;
  httpurl = `http://${VITE_SERVER_IP}/graphql`;
}

//serverUrl = `http://localhost:9000`;
//wsurl = `ws://localhost:9000/graphql`;
//httpurl = `http://localhost:9000/graphql`;

export { serverUrl, wsurl, httpurl };

console.log({ serverUrl, wsurl, httpurl });

export function serverImgUrl(imgUrl: string) {
  const url = `${serverUrl}${imgUrl}`;

  return url;
}
