import React, { useCallback, useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'query-string';

import {
  useIsResetLinkValidMutation,
  useResetPasswordMutation,
} from '@checkpoints/shared';

import { ContainedButton } from '../../components/button';
import { InnerContainer, OuterContainer } from '../../components/layouts';
import MainStore from '../../stores/Store';
import { Header3 } from '../Dashboard/DashboardHeader';
import { Logo } from '../../components/server-image';
import { useFormStyles } from '../../components/form';

const default_user = {
  password: '',
  repeat_password: '',
  email: '',
};

export function ForgotPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const { classes, cx } = useFormStyles();

  const { v } = parse(location.search);
  const [reset] = useResetPasswordMutation();
  const [isLinkValid] = useIsResetLinkValidMutation();
  const [state, setState] = useState<typeof default_user>(default_user);

  useEffect(() => {
    if (!v) {
      navigate('/login');

      return;
    }
  }, [navigate, v]);

  useEffect(() => {
    if (!v) {
      return;
    }
    isLinkValid({
      variables: {
        token: v as string,
      },
    })
      .then(() => {
        // execute
      })
      .catch(() => {
        // navigate('/login');

        return;
      });
  }, [isLinkValid, navigate, v]);

  const handleReset: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    reset({
      variables: {
        token: v as string,
        password: state.password,
        repeatPassword: state.repeat_password,
      },
    })
      .then(() => MainStore.store.getMe('network-only'))
      .then(() => {
        navigate('/dashboard');
      })
      .catch((_e) => {
        // catch
      });
    // .catch(e => {
    //   console.log('e', e);
    //   navigate('/login');
    // });

    return false;
  };

  const onChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback((e) => {
    const name = e.target.name;
    const value = e.target.value;
    setState((s) => ({ ...s, [name]: value }));
  }, []);

  return (
    <OuterContainer>
      <InnerContainer>
        <div className="flex">
          <form className={cx(classes.borderedForm)} onSubmit={handleReset}>
            <div className={cx(classes.logoImageDiv)}>
              <Logo variant="white" />
            </div>
            <Header3>Reset password</Header3>
            {/* <TextField
              type="email"
              label={'Email'}
              name="email"
              disabled
              autoComplete="email"
              value={state.email}
              onChange={onChange}
            /> */}
            <TextField
              name="password"
              type="password"
              label={'Password'}
              autoComplete="new-password"
              value={state.password}
              onChange={onChange}
            />

            <TextField
              type="password"
              name="repeat_password"
              label={'Repeat password'}
              value={state.repeat_password}
              onChange={onChange}
              autoComplete="new-password"
            />
            <div className="spacer" />
            <ContainedButton>Reset password</ContainedButton>
          </form>
        </div>
      </InnerContainer>
    </OuterContainer>
  );
}
