import React from 'react';

import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react';

import {
  Constants,
  TableColumn,
  useCreateRunsheetHeaderMutation,
} from '@checkpoints/shared';
import {
  START_TIME_ACCESSOR,
  END_TIME_ACCESSOR,
} from '@checkpoints/shared/src/constants';

import colors, { styled } from '../../colors';
import { AddCircleIcon, ExpandMoreIcon } from '../common';
import { Resizer } from '../table-cells';
import { CheckpointsStore } from '../../stores/checkpointsStore';
import { useUpdateUserHeader } from '../../stores/helpers';
import MainStore from '../../stores/Store';

import { TableHead, FIRST_CELL_WIDTH } from './StyledTableCells';

const { USER_NOTES_ACCESSOR } = Constants;

export const MIN_SMALL_COL_WIDTH = 72;

export const MIN_COL_WIDTH = 95;

export const HeaderRow = observer(function HeaderRow() {
  const columns = CheckpointsStore.store.activeHeaders;
  const editMode = CheckpointsStore.store.editMode;
  const updateHeader = useUpdateUserHeader();

  const handleResize = (header: TableColumn, width: number) => {
    updateHeader(header, { width });
  };

  return (
    <>
      <TableHead className="tableHead">
        <StyledHeaderRow className="header-row" editMode={editMode}>
          <HeaderCell style={{ width: FIRST_CELL_WIDTH }}></HeaderCell>
          {columns.map((header, idx) => {
            const isUserNote = header.accessor === USER_NOTES_ACCESSOR;

            if (isUserNote) {
              return (
                <UserNoteHeaderCell
                  key={header.accessor}
                  header={header}
                  idx={idx}
                  handleResize={handleResize}
                  isEditMode={editMode}
                />
              );
            }

            const title = header.title;
            const displayHeaderEdit = editMode && header.isCustomHeader;
            // const disableSeparator = editMode && idx === columns.length - 1;

            const isSmallHeader =
              header.accessor === START_TIME_ACCESSOR ||
              header.accessor === END_TIME_ACCESSOR;

            const minimumColumnWidth = isSmallHeader
              ? MIN_SMALL_COL_WIDTH
              : MIN_COL_WIDTH;

            return (
              <HeaderCell
                key={header.accessor}
                style={{
                  width: header.width || 200,
                  flex: `0 0 auto`,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {displayHeaderEdit ? (
                  <HeaderEdit title={title} header={header} />
                ) : (
                  <span>{title}</span>
                )}
                <Resizer
                  columnClass={`column${idx}`}
                  minimumColumnWidth={minimumColumnWidth}
                  resize={(width) => {
                    header.accessor;
                    handleResize(header, Math.max(minimumColumnWidth, width));
                  }}
                />
                <div className="separator" />
              </HeaderCell>
            );
          })}
          <LastColumnHeader />
        </StyledHeaderRow>
      </TableHead>
      <div
        style={{
          // Margin top is needed on edit mode for the + buttons on thr right
          marginTop: editMode ? 20 : 5,
          transition: 'margin-top 0.3s ease-in-out',
        }}
      />
    </>
  );
});

const HeaderEdit = ({
  title,
  header,
}: {
  header: TableColumn;
  title: string;
}) => (
  <div
    className="header"
    id={`header_edit_${header.accessor}`}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();

      const currentAnchor =
        CheckpointsStore.store.columnSettingsActive.anchorEl;

      if (currentAnchor && e.currentTarget.id === currentAnchor.id) {
        return;
      }
      CheckpointsStore.store.activateColumnSettings({
        column: header,
        anchorEl: e.currentTarget as HTMLDivElement,
      });
    }}
  >
    {title}
    <br />
    <ExpandMoreIcon />
  </div>
);

const UserNoteHeaderCell = observer(function UserNoteHeaderCell({
  header,
  idx,
  handleResize,
  isEditMode,
}: {
  header: TableColumn;
  idx: number;
  handleResize: (header: TableColumn, width: number) => void;
  isEditMode: boolean;
}) {
  const fullName = MainStore.store.meFullName;

  return (
    <HeaderCell
      style={{
        width: header.width || 200,
        flex: `0 0 auto`,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      <UserNameDisplayer {...{ isEditMode }}>{fullName}</UserNameDisplayer>
      <Resizer
        columnClass={`column${idx}`}
        minimumColumnWidth={MIN_COL_WIDTH}
        resize={(width) => {
          handleResize(header, Math.max(MIN_COL_WIDTH, width));
        }}
      />
      <div className="separator" />
    </HeaderCell>
  );
});

const LastColumnHeader = observer(function LastColumnHeader() {
  const [createHeader] = useCreateRunsheetHeaderMutation({
    fetchPolicy: 'no-cache',
  });

  const handleCreateColumn = () => {
    createHeader({
      variables: {
        runsheetId: CheckpointsStore.store.selectedRunsheetId,
        title: 'Untitled',
        order: CheckpointsStore.store.headers.length,
      },
    });
  };

  const editMode = CheckpointsStore.store.editMode;

  return (
    <StyledLastHeader>
      {editMode && (
        <IconButton
          color="inherit"
          onClick={handleCreateColumn}
          title="Add column"
          size="large"
        >
          <AddCircleIcon />
        </IconButton>
      )}
    </StyledLastHeader>
  );
});

const StyledHeaderRow = styled('div')<{ editMode?: boolean }>`
  display: flex;
  height: 40px;

  background: ${(p) =>
    p.editMode ? colors.editModeTableHeader : colors.tableHeader};

  color: ${(p) => (p.editMode ? '#ffffff' : colors.gray)};

  .separator {
    position: absolute;
    /* height: 80%; */
    height: calc(100vh - 192px);
    width: 1px;
    background-color: ${(p) =>
      p.editMode ? 'rgba(255,255,255,.4)' : '#707070'};
    right: 0;
    top: -15px;
    z-index: 10;
  }

  &::after {
    pointer-events: none;
    content: '';
    position: absolute;
    width: 100%;
    height: 10px;
    z-index: -1;
    left: 0;
    bottom: -10px;
    background: ${(p) =>
      p.editMode
        ? `linear-gradient(${colors.editModeTableHeaderFadeFirst}, ${colors.editModeTableHeaderFadeSecond})`
        : `linear-gradient(${colors.tableHeaderFadeFirst}, ${colors.tableHeaderFadeSecond})`};
  }
`;
type HeaderCellProps = { firstCell?: boolean };

export const HeaderCell = styled('div')<HeaderCellProps>`
  flex: 0 0 auto;
  /* ${(p) => p.firstCell && 'width: 120px'}; */

  display: flex;
  align-items: center;

  padding: 0 12px;
  overflow: visible;

  position: relative;
  font-weight: 700;

  z-index: 10;

  pointer-events: none;
  & > * {
    pointer-events: auto;
  }

  .header {
    text-align: center;
    margin-top: 21px;
    cursor: pointer;

    svg {
      position: relative;
      top: -5px;
      margin: 0 auto;
    }
  }

  &:first-of-type,
  &:nth-last-of-type(-n + 2) {
    .separator {
      display: none;
    }
  }

  /* box-shadow: 0 8px 8px rgba(51,51,51,.8); */
`;

const UserNameDisplayer = styled('span')<{ isEditMode?: boolean }>(
  ({ theme, isEditMode = false }) => ({
    border: `2px solid ${isEditMode ? 'white' : theme.palette.colors.primary}`,
    color: 'white',
    padding: '6px 12px',
    borderRadius: '4px',
    maxHeight: '55px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
);

const StyledLastHeader = styled('div')`
  padding: 0;
  width: 120px;
  display: flex;
  align-items: center;

  button {
    margin-left: -50px;
  }
`;
