import React, { useCallback, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import AppStoreImage from '../../assets/welcomescreen-App.png';
import DashboardImage from '../../assets/welcomescreen-Dashboard.png';
import { mediaMobile, styled } from '../../colors';
import { InnerContainer, OuterContainer } from '../../components/layouts';
import { getMobileOperatingSystem } from '../../utils/getMobileOperatingSystem';

// To avoid the "protocol not supported" alert, fail must open another app.
const appstoreLink =
  'itms-apps://itunes.apple.com/us/app/checkpoints-event-tracking/id1486146390?ls=1';

const playstoreLink =
  'https://play.google.com/store/apps/details?id=se.checkpoints.checkpointsapp';

const OS = getMobileOperatingSystem();
const isUnsupported = !/iOS|Android/i.test(OS);

export function WelcomeScreen() {
  const location = useLocation();
  const navigate = useNavigate();

  const goToAppStore = useCallback(() => {
    console.log('open app store');
    if (OS === 'iOS') {
      window.location.href = appstoreLink;
    } else if (OS === 'Android') {
      window.location.href = playstoreLink;
    } else {
      navigate('/dashboard');
    }
  }, [navigate]);

  useEffect(() => {
    if (isUnsupported) {
      navigate('/dashboard');

      return;
    }
    if (location.hash === '#app') {
      goToAppStore();
    }
  }, [goToAppStore, location, navigate]);

  return (
    <OuterContainer>
      <InnerContainer>
        <StyledImageHolder>
          <img
            src={AppStoreImage}
            alt="Go to App Store"
            onClick={goToAppStore}
            role="button"
          />
          <img
            src={DashboardImage}
            alt="Go to Dashboard"
            onClick={() => {
              navigate('/dashboard');
            }}
            role="button"
          />
        </StyledImageHolder>
      </InnerContainer>
    </OuterContainer>
  );
}

const StyledImageHolder = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  flex: 1 1 auto;

  ${mediaMobile} {
    flex: 0;
  }

  img {
    margin: 10px;
    width: 400px;
    max-width: 80%;
  }
`;
