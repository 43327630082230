import React from 'react';

import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react';

import { useOrdersQuery } from '@checkpoints/shared';

import { styled } from '../../colors';
import { DownloadIcon } from '../../components/common';
import { serverUrl } from '../../config';
import { Header3 } from '../Dashboard/DashboardHeader';

import { currencyFormatter } from './BillingStore';

function getReceiptUrl(receiptId: string) {
  return `${serverUrl}/receipt/${receiptId}`;
}

export const Receipts = observer(function Receipts() {
  return <div></div>;

  const { data, loading } = useOrdersQuery();

  if (!data) {
    if (loading) {
      return <div>Loading...</div>;
    }

    return null;
  }

  if (data.orders.length === 0) {
    return <div></div>;
  }

  return (
    <div>
      <Header3>Receipts:</Header3>
      <StyledList>
        {data.orders.map((order) => (
          <li key={order.id}>
            <div className="date">
              {new Date(Number(order.createdAt)).toLocaleString()}
            </div>

            <div className="flex-between border-white">
              <span className="padding-12">
                Amount: {currencyFormatter.format(order.amount * 0.01)}
              </span>
              {order.receiptId && (
                <IconButton
                  color="default"
                  title={'Download receipt'}
                  aria-label={getReceiptUrl(order.receiptId)}
                  onClick={() => window.open(getReceiptUrl(order.receiptId))}
                  size="large"
                >
                  <DownloadIcon />
                </IconButton>
              )}
            </div>
          </li>
        ))}
      </StyledList>
    </div>
  );
});

const StyledList = styled('ul')`
  list-style: none;
  max-height: 60vh;
  overflow: auto;
  margin: 0;
  padding: 0;
  .date {
    font-size: 12px;
    opacity: 0.6;
    /* padding: 0 6px; */
  }
  button {
    color: white;
  }
  li {
    margin: 0;
    padding: 8px 0;
  }

  .padding-12 {
    padding: 12px 0;
  }
  .border-white {
    border-bottom: 1px solid #ffffff55;
  }
`;
