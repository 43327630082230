import React from 'react';

import { observer } from 'mobx-react';
import { Schedule } from '@mui/icons-material';
import { TooltipProps } from '@mui/material/Tooltip';

import {
  AppRunsheetFragment,
  AppUserFragment,
  getFullName,
  getInitials,
  Runsheet,
} from '@checkpoints/shared';

import MainStore from '../../stores/Store';
import { ServerImage } from '../server-image';
import { TooltipWrapper } from '../tooltip';

export const UserContent = observer(function UserContent({
  user,
  runsheet,
  showTooltip = true,
  toolTipPosition = 'bottom',
  toolTipProps,
}: {
  user: AppUserFragment;
  runsheet?: AppRunsheetFragment;

  /** Defaults to true */
  showTooltip?: boolean;
  toolTipPosition?: 'bottom' | 'right';
  toolTipProps?: Partial<TooltipProps>;
}) {
  if (!user) {
    return null;
  }
  //React to changes to me;
  const me = MainStore.store.me;

  if (me && user.id === me.id) {
    user = me;
  }
  const imageSrc = user.image && user.image.thumbnail;
  let tooltipText = getFullName(user);

  let content = imageSrc ? (
    <ServerImage src={imageSrc} alt={user.first_name} />
  ) : (
    <div>{getInitials(user)}</div>
  );

  if (runsheet && runsheet.runbytime) {
    content = (
      <Schedule
        style={{ color: 'white', height: '100%', width: '100%' }}
        aria-label="Clock producer"
      />
    );
    tooltipText = 'Clock producer';
  }

  return (
    <TooltipWrapper
      placement={toolTipPosition}
      title={tooltipText}
      show={showTooltip}
      {...toolTipProps}
    >
      {content}
    </TooltipWrapper>
  );
});
