import React, { useMemo } from 'react';

import EnterIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { styled } from '../../colors';
import { AddIcon } from '../../components/common';
import { ModalStore } from '../../stores/ModalStore';
import MainStore from '../../stores/Store';

// const config = { mass: 5, tension: 2000, friction: 200 };
const initial = Array(8).fill({ active: false });

const ANIMATION_DELAY = 100;

class CircleStore {
  constructor() {
    makeObservable(this, {
      circles: observable,
      animationActive: observable,
      performUpdate: action,
      start: action,
    });
  }

  circles = initial;
  animationActive = false;

  performUpdate = () => {
    const nextTarget = this.circles.find((c) => c.active === false);

    if (!nextTarget) {
      // this.active = false;
      this._resolve();

      return;
    }

    nextTarget.active = true;
    setTimeout(this.performUpdate, ANIMATION_DELAY);
  };

  _resolve: any;

  start = () => {
    this.animationActive = true;

    return new Promise((res) => {
      this._resolve = res;
      this.performUpdate();
    });
  };
}

const ButtonCircles = observer(function ButtonCircles({
  store,
}: {
  store: CircleStore;
}) {
  const navigate = useNavigate();
  // const trail = useTrail(circles.length, {
  //   config,
  //   opacity: active ? 0 : 1,
  //   from: { opacity: 1 },
  // });

  return (
    <CircleContainer>
      {store.circles.map(({ active }, i) => {
        // const invertedI = store.circles.length - 1 - i;
        const size = 100 - i * (100 / store.circles.length);
        const defaultOpacity = (100 - size) * 0.01;

        return (
          <Circle
            key={`c-${i}`}
            style={{
              zIndex: 10 - i,
              width: `${size}%`,
              height: `${size}%`,
              opacity: active ? 0 : defaultOpacity,
              transition: `opacity ${0.01 * size * 500}ms ease-in-out`,
              // opacity: active ? 0 : defaultOpacity,
            }}
          ></Circle>
        );
      })}
      <button
        onClick={(e) => {
          if (!MainStore.store.hasActivePaidAccount) {
            ModalStore.store.setCreateEventNoPlan(true);

            return;
          }
          // navigate('/dashboard/create');
          const target = e.target as HTMLButtonElement;

          setTimeout(() => {
            target.style.opacity = '0';
          }, 300);
          store.start().then(() => {
            setTimeout(() => {
              navigate('/dashboard/create', {
                state: { animate: true },
              });
            }, ANIMATION_DELAY);
          });
        }}
      >
        <AddIcon />
      </button>
    </CircleContainer>
  );
});

export const Tutorial = observer(function Tutorial() {
  const store = useMemo(() => new CircleStore(), []);

  return (
    <StyledTutorialContainer>
      <ButtonCircles store={store} />

      <TextDiv style={{ opacity: store.animationActive ? 0 : 1 }}>
        <h1>Welcome to Checkpoints!</h1>
        <h2>
          Click on the plus icon to create
          <br /> your first event
        </h2>
        <EnterIcon />
      </TextDiv>
    </StyledTutorialContainer>
  );
});

const TextDiv = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: end;
  text-align: right;
  width: 60%;
  flex-shrink: 1;
  transition: opacity 700ms ease-in-out;

  h1 {
    margin-top: 60px;
    padding: 0px 0 20px 0;
    font-size: 60px;
    font-weight: 100;
  }
  h2 {
    padding: 10px 0;
    font-size: 40px;
    font-weight: 100;
  }
  svg {
    width: 60px;
    height: 60px;
  }
`;

const CircleContainer = styled('div')`
  flex-shrink: 0;
  width: 40%;
  height: 0;
  padding-bottom: 40%;
  position: relative;
  button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 80px;
    height: 80px;
    z-index: 10;
    background: #0278d2;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity 400ms ease-in-out;
    svg {
      width: 80%;
      height: 80%;
      transition: opacity 400ms ease-in-out;
    }
    &:focus {
      outline: 0;
      /* outline: 1px solid white; */
    }
  }
`;

const Circle = styled('div')`
  background-color: white;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

  opacity: 1;
  will-change: opacity;
  transition: opacity ${ANIMATION_DELAY}ms ease-in-out;
`;

const StyledTutorialContainer = styled('div')`
  display: flex;
  width: 100%;
  margin-top: 60px;
`;
