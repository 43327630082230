import React from 'react';

import { observer } from 'mobx-react';

import { ExtendedAppUser, getFullName } from '@checkpoints/shared';

import { styled } from '../../colors';
import { TooltipWrapper } from '../tooltip';

import { UserContent } from './Avatar';
import { StyledOnlineRing } from './styles';

export const OnlineAvatar = observer(function OnlineAvatar({
  user,
}: {
  user: ExtendedAppUser;
}) {
  if (!user) return null;

  // console.log('user.isOnline', user.email, user.isOnline);
  return (
    <StyledOnlineRing isMe={user.isMe} active={user.isOnline}>
      <UserContent user={user} />
    </StyledOnlineRing>
  );
});

export const ShowMoreUsersAvatar = observer(function ShowMoreUsersAvatar({
  title,
  users,
}: {
  title: string;
  users: ExtendedAppUser[];
}) {
  const toolTip = (
    <StyledToolTip>
      {users.map((u) => (
        <StyledUserRow key={u.id}>
          <StyledOnlineRing isMe={u.isMe} active={u.isOnline} noMargin={true}>
            <UserContent user={u} showTooltip={false} />
          </StyledOnlineRing>
          <span>{getFullName(u)}</span>
        </StyledUserRow>
      ))}
    </StyledToolTip>
  );

  return (
    <StyledOnlineRing isMe={false} active={false}>
      <TooltipWrapper title={toolTip}>
        <div>{title}</div>
      </TooltipWrapper>
    </StyledOnlineRing>
  );
});

const StyledUserRow = styled('div')`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  span {
    padding: 0 4px;
  }
`;
const StyledToolTip = styled('div')``;
