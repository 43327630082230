import { css } from '@mui/material';
import Button from '@mui/material/Button';

import colors, { styled } from '../../colors';

export const LargeRing = styled(Button)<{ color?: string }>`
  min-width: 0;
  padding: 0 !important;
  color: white !important;
  flex-shrink: 0;
  flex-grow: 0;

  border-radius: 50%;
  border: 2px solid ${(p) => p.color || colors.primary};
  color: white;
  font-size: 20px;
  width: 44px;
  height: 44px;
  user-select: none;

  overflow: hidden;

  /* display: flex;
  justify-content: center;
  align-items: center; */

  /* margin: -12px 0; */
`;

const SmallRingStyle = css`
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid ${colors.primary};
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  width: 30px;
  height: 30px;
  overflow: hidden;
  user-select: none;
`;

export const SmallRing = styled('div')`
  ${SmallRingStyle}
`;

export const SmallRingButton = styled(Button)`
  ${SmallRingStyle}
  min-width: 0;
  padding: 0 !important;
  color: white !important;
`;

interface Props {
  active: boolean;
  isMe: boolean;
  noMargin?: boolean;
}

export const StyledOnlineRing = styled(SmallRing, {
  shouldForwardProp: (propName) => {
    if (propName !== 'active' && propName !== 'isMe') {
      return true;
    }
  },
})<Props>`
  flex-shrink: 0;
  position: relative;
  margin-left: ${(p) => (p.noMargin ? '0' : '-5px')};

  background-color: ${colors.nav};
  transition: border 0.3s ease-in-out;
  border: 2px solid
    ${(p) => (p.isMe ? colors.primary : p.active ? colors.online : colors.gray)};
`;
