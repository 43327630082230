import { makeStyles } from 'tss-react/mui';

import { mediaTablet } from '../../colors';

export const splitDivStyles = {
  display: 'flex',
  margin: '-20px',
  '> div, form': {
    width: '50%',
    padding: '20px',
  },
  [mediaTablet]: {
    flexDirection: 'column',
    margin: '0',
    '> div, form': {
      padding: '0',
      width: '100%',
    },
  },
} as const;

export const useSplitDivStyles = makeStyles()(() => ({
  root: {
    ...splitDivStyles,
  },
}));
