import React, { useState } from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react';
import { DropzoneProps, useDropzone } from 'react-dropzone';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Crop } from 'react-image-crop';

import { AppUserFragment } from '@checkpoints/shared';

import { styled } from '../../colors';
import { ProfileAvatar } from '../../components/avatar';
import {
  convertFileSizeToBytes,
  dropUploader,
  formatBytes,
} from '../../utils/dropUploader';
import { ResizeDialogRaw } from '../../components/modals';
import { DeleteIcon, EditIcon } from '../../components/common';
import { TooltipWrapper } from '../../components/tooltip';
import MainStore from '../../stores/Store';

const UPLOAD_FILESIZE_LIMIT = '3mb';

export const ProfileImage = observer(function ProfileImage({
  onUpdateImage,
  onRemoveImage,
  user,
}: {
  onUpdateImage: (imageId: number) => Promise<void>;
  onRemoveImage: () => Promise<void>;
  user: AppUserFragment;
}) {
  const mainStore = MainStore.store;
  const [file, setFile] = useState<File>(null);

  const { classes, cx } = useStyles();

  const onCrop = async (crop: Crop) => {
    if (crop && file) {
      console.log('cropped', crop);
      crop.aspect = 1;
      const response = await dropUploader([file], crop);
      const imageResult = response[0];
      const imageId = imageResult.id;
      await onUpdateImage(imageId);
      setFile(null);
    } else {
      setFile(null);
    }
  };

  const onDrop: DropzoneProps['onDrop'] = (files, rejected, event) => {
    console.log('files', files);
    console.log('rejected', rejected);

    if (rejected.length) {
      rejected[0].errors.forEach((err) => {
        let message = err.message;
        const bytesRegex = /(\d+)\sbytes/;
        const match = message.match(bytesRegex);

        if (match) {
          const bytes = parseInt(match[1]);
          const formattedBytes = formatBytes(bytes);
          message = message.replace(bytesRegex, formattedBytes);
          console.log(message);
        }

        mainStore.displayNotification({
          message: message,
          options: { variant: 'error', timeout: 6000 },
        });
        throw new Error(message);
      });
    }
    setFile(files[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: convertFileSizeToBytes(UPLOAD_FILESIZE_LIMIT),
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
  });

  const handleDelete: React.MouseEventHandler<HTMLButtonElement> = async (
    e,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    await onRemoveImage();
  };

  const { ref, ...rootProps } = getRootProps();
  const inputProps = getInputProps();

  return (
    <>
      <StyledProfileView className={classNames({ isDragActive })}>
        <TooltipWrapper
          title={`Upload an image (max ${UPLOAD_FILESIZE_LIMIT})`}
        >
          <ProfileAvatar user={user}>
            <div
              ref={ref}
              {...rootProps}
              className={classNames({
                dropHolder: true,
                isDragActive,
              })}
            >
              <input {...inputProps} />
              <div className="image_hint">
                {user.image && (
                  <IconButton
                    className={cx(classes.Button)}
                    onClick={handleDelete}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
                <IconButton className={cx(classes.Button)}>
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          </ProfileAvatar>
        </TooltipWrapper>
      </StyledProfileView>
      <ResizeDialogRaw
        file={file}
        open={!!file}
        onClose={onCrop}
        cropProps={{ circularCrop: true }}
        // initialCrop={{ unit: 'px', width: 400 }}
      />
    </>
  );
});

const StyledProfileView = styled('div')`
  display: flex;

  &.isDragActive {
    .ring {
      background: #555;
      opacity: 0.5;
    }
  }

  .dropHolder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    cursor: pointer;

    .image_hint {
      display: flex;

      button {
        border: 0;
        background: none;
        cursor: pointer;

        svg {
          font-size: 20px;
          color: white;
        }
      }

      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, 0);
      background-color: rgba(0, 0, 0, 0.3);
      padding: 10px 40px 30px;
      font-size: 10px;
      opacity: 0;
      transition: all 0.1s ease-in-out;
      transition-property: opacity, transform;
    }

    &:hover,
    &:focus {
      .image_hint {
        opacity: 1;
        transform: translate(-50%, -10%);
      }
    }
  }
`;

const useStyles = makeStyles({
  name: 'ProfileDiv',
})((theme, _params, classes) => ({
  Button: {
    paddingTop: '1px',
    paddingBottom: '1px',
    paddingLeft: '6px',
    paddingRight: '6px',
    // fontWeight: 600,
    // color: theme.palette.colors.dimText,
  },
}));
