import React from 'react';

import {
  FormControl,
  InputLabel,
  Typography,
  Checkbox as MUICheckbox,
  FormControlLabel,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { styled } from '../../colors';

export function Checkbox({
  value,
  onChangeValue,
  label,
}: {
  label?: string;
  value: boolean;
  onChangeValue: (checked: boolean) => void;
}) {
  const { classes, cx } = useStyles();

  return (
    <FormControl variant="standard" style={label && { minWidth: '80px' }}>
      {label && (
        <InputLabel
          htmlFor="styled-checkbox"
          className={cx(classes.label)}
          shrink
          hidden={Boolean(label) === false}
        >
          <Typography component="span" className={cx(classes.title)}>
            {label}
          </Typography>
        </InputLabel>
      )}
      <MUICheckbox
        id="styled-checkbox"
        className={cx(classes.checkbox)}
        style={
          label && {
            marginTop: '16px',
          }
        }
        checked={value}
        onChange={(_, checked) => {
          onChangeValue(checked);
        }}
        disableRipple
      />
    </FormControl>
  );
}

const useStyles = makeStyles<void, 'label' | 'title' | 'checkbox'>({
  name: 'Checkbox',
})((theme, _params, classes) => ({
  label: {
    [`&.Mui-focused .${classes.title}`]: {
      opacity: 1,
    },
  },
  title: {
    fontSize: '16px',
    opacity: 0.8,
    // fontWeight: 600,
    // color: theme.palette.colors.dimText,
  },
  checkbox: {
    color: 'inherit',
    ['&.Mui-checked']: {
      color: 'inherit',
    },
  },
}));

const StyledLabel = styled('label')`
  cursor: pointer;
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
  font-size: 1rem;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledCheckbox = styled(MUICheckbox)`
  color: inherit;
  &.Mui-checked {
    color: inherit;
  }
`;
