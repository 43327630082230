import React from 'react';

import differenceInSeconds from 'date-fns/differenceInSeconds';
import { observer } from 'mobx-react';

import colors, { styled } from '../../colors';
import { useWindowWidth } from '../../utils/useWindowListener';
import { CheckpointsStore } from '../../stores/checkpointsStore';
import { RadioButtonCheckedIcon } from '../common';

const MINUS_FACTOR = 240 + 16 + 20;

export const Minimap = observer(function Minimap() {
  const editMode = CheckpointsStore.store.editMode;
  const checkpoints = CheckpointsStore.store.sortedCheckpoints;
  const windowWidth = useWindowWidth();

  if (!checkpoints || !checkpoints.length) return null;
  if (CheckpointsStore.store.trackedRowIndex == -1) {
    return null;
  }
  const lastCheckpointIdx = checkpoints.length - 1;

  const startDate = CheckpointsStore.store.checkpointMeta(0).startDate;
  const endDate =
    CheckpointsStore.store.checkpointMeta(lastCheckpointIdx).startDate; //If we reach the last point, we're done
  const totalSeconds = differenceInSeconds(startDate, endDate) || 1;

  const activeIdx = CheckpointsStore.store.trackedRowIndex;
  const activeDate = CheckpointsStore.store.checkpointMeta(activeIdx).startDate;

  const secondsBefore = differenceInSeconds(startDate, activeDate);
  const secondsAfter =
    activeIdx === lastCheckpointIdx
      ? 0
      : differenceInSeconds(activeDate, endDate);

  const maxWidth = Math.min(
    windowWidth - MINUS_FACTOR,
    CheckpointsStore.store.activeHeaderWidth - MINUS_FACTOR + 240,
  );
  const widthBefore = (maxWidth * secondsBefore) / totalSeconds;
  const widthAfter = (maxWidth * secondsAfter) / totalSeconds;

  return (
    <MinimapContainer editMode={editMode}>
      <StyledMinimap>
        <span
          className={`line active ${editMode && 'editMode'}`}
          style={{ width: widthBefore }}
        ></span>
        <RadioButtonCheckedIcon
          className={`active ${editMode && 'editMode'}`}
        />
        <span
          className={`line ${editMode && 'editMode'}`}
          style={{ width: widthAfter }}
        ></span>
      </StyledMinimap>
      {/* {checkpoints.map((c, rowIndex) => {
        const timeComponents = getTimeComponents(c.duration);
        const seconds =
          timeComponents[0] * 360 + timeComponents[1] * 60 + timeComponents[2];
        const width = (seconds / eventDurationSeconds) * windowWidth;
        const trackedRow = CheckpointsStore.store.trackedRowId === c.id;
        const isBeforeTracked =
          CheckpointsStore.store.trackedRowIndex > rowIndex;
        return (
          <MinimapCheckpoint key={c.id}>
            {isBeforeTracked ? (
              <CheckCircleIcon className="active" />
            ) : trackedRow ? (
              <RadioButtonChecked className="active" />
            ) : (
              <RadioButtonUnChecked />
            )}
            {rowIndex !== checkpoints.length - 1 && (
              <span className="line" style={{ width }}></span>
            )}
          </MinimapCheckpoint>
        );
      })} */}
    </MinimapContainer>
  );
});

const StyledMinimap = styled('div')`
  margin-left: 120px;
  display: flex;
  align-items: center;

  .line {
    color: ${colors.gray};
    &.active {
      color: ${colors.primary};
      &.editMode {
        color: white;
      }
    }

    transition: width 0.3s ease-in-out;

    border-bottom: 1px solid;
    margin: 0 4px;
  }
  svg {
    width: 20px;
    height: 20px;
    &.active {
      color: ${colors.primary};
      &.editMode {
        color: white;
      }
    }
  }
`;

const MinimapContainer = styled('div')<{ editMode?: boolean }>`
  position: relative;
  height: 40px;
  width: 100%;
  display: flex;
  /* overflow: scroll; */

  &::-webkit-scrollbar {
    display: none;
  }

  /* gradient */
  &::after {
    pointer-events: none;
    content: '';
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    z-index: 10;
    top: -20px;
    background: ${(p) =>
      p.editMode
        ? `linear-gradient(${colors.editModeMinimapFadeFirst}, ${colors.editModeMinimapFadeSecond})`
        : `linear-gradient(${colors.minimapFadeFirst}, ${colors.minimapFadeSecond})`};
  }
`;
