import React, { useCallback, useEffect } from 'react';

import classNames from 'classnames';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProvided,
  DropResult,
} from '@hello-pangea/dnd';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { makeStyles } from 'tss-react/mui';

import {
  DraggableProps,
  ExtendedCheckpoint,
  getCheckpointRowValue,
  InputType,
  RowProps,
} from '@checkpoints/shared';

import { CheckpointsStore } from '../../stores/checkpointsStore';
import {
  handleReorder,
  scrollToTracked,
  useCreateNewRow,
  useTrackNextRow,
} from '../../stores/helpers';
import { AddButton } from '../button';
import { RowSettingsPopover } from '../popovers';
import { runsheetColors } from '../../colors';

import { ActiveCell } from './ActiveCell';
import { DisplayCell } from './DisplayCell';
import { FirstCell } from './FirstCell';
import { HeaderRow } from './HeaderRow';
import {
  LastTableRow,
  StyledAddRowAbove,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from './StyledTableCells';

export const CheckpointsTable = observer(() => {
  const handleDragEnd = (result: DropResult) => {
    // dropped outside the list or same pos
    if (!result.destination) return;
    const from = result.source.index;
    const to = result.destination.index;
    handleReorder(from, to);
  };

  console.log('rendering CheckpointsTable');

  const checkpoints = CheckpointsStore.store.sortedCheckpoints;
  const skip = checkpoints.length === 0;
  const uniqueRunsheetId = skip ? 'unknown' : checkpoints[0].runsheetId;

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div key={`${uniqueRunsheetId}`} className="fadeIn">
        <TableContainer>
          <Table className={`table`}>
            <div
              id="multiSelect-root"
              style={{ position: 'absolute', top: '30px', left: '50%' }}
            ></div>
            <HeaderRow />
            <Body />
          </Table>
        </TableContainer>
      </div>
    </DragDropContext>
  );
});

const Body = observer(function Body() {
  const checkpoints = CheckpointsStore.store.sortedCheckpoints;
  const skip = checkpoints.length === 0;
  useTrackNextRow(skip);

  useEffect(() => {
    console.log('Rendered Body');
    scrollToTracked();
  }, []);

  // if (skip) {
  //   return null;
  // }

  const uniqueRunsheetId = skip ? 'unknown' : checkpoints[0].runsheetId;

  // const checkpointsLength = checkpoints.length;
  // console.log('rendering table body');

  return (
    <div className="table-body-wrapper">
      <Droppable droppableId="table">
        {(droppableProvided: DroppableProvided) => (
          <TableBody
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
          >
            <TransitionGroup
              className="checkpoints"
              key={`${uniqueRunsheetId}`}
            >
              {checkpoints.map((checkpoint, rowIdx) => (
                <DraggableCheckpointsRow
                  key={checkpoint.id}
                  checkpoint={checkpoint}
                  rowIndex={rowIdx}
                  // isLastRow={rowIdx === checkpointsLength - 1}
                />
              ))}
              {droppableProvided.placeholder}
              <LastRow key={'last_row'} />
            </TransitionGroup>
          </TableBody>
        )}
      </Droppable>
      <RowSettingsPopover />
    </div>
  );
});

const DraggableCheckpointsRow = observer(function DraggableCheckpointsRow(
  props: RowProps,
) {
  const { checkpoint, rowIndex } = props;

  const checkpoints = CheckpointsStore.store.sortedCheckpoints;

  let found = false;

  for(let c of checkpoints)
    {
      if (c.id == checkpoint.id)
      {
        found = true;
        break;
      }
    }

  if (!found)
    {
      return null;
    }

  return (
    <Draggable
      draggableId={String(checkpoint.id)}
      index={rowIndex}
      key={checkpoint.id}
    >
      {(provided, snapshot) => {
        const { dragHandleProps, draggableProps, innerRef } = provided;
        const { isDragging } = snapshot;

        return (
          <CSSTransition
            timeout={500}
            className="checkpoint"
            nodeRef={typeof innerRef === 'object' ? innerRef : undefined}
          >
            <CheckpointsRow
              {...{
                ...props,
                dragHandleProps,
                draggableProps,
                innerRef,
                isDragging,
              }}
            />
          </CSSTransition>
        );
      }}
    </Draggable>
  );
});

const useStyles = makeStyles({ name: 'CheckpointsRow' })((theme) => ({
  root: Object.entries(runsheetColors).reduce(
    (obj, pair) => ({
      ...obj,
      [`&.${pair[0]} .columnCell > div`]: {
        backgroundColor: pair[1],
        ['*']: {
          color: theme.palette.getContrastText(pair[1]),
        },
      },
    }),
    {},
  ),
}));

const CheckpointsRow = observer(function CheckpointsRow({
  checkpoint,
  rowIndex,
  dragHandleProps,
  draggableProps,
  innerRef,
  isDragging,
}: RowProps & DraggableProps) {
  const editMode = CheckpointsStore.store.editMode;

  const isTrackedRow =
    !editMode && CheckpointsStore.store.trackedRowId === checkpoint.id;

  const { classes, css } = useStyles();
  const colorClass = checkpoint.color || 'gray';

  useEffect(() => {
    const id = setTimeout(() => {
      if (!checkpoint.active) return;
      runInAction(() => {
        checkpoint.modifiedRow = false;
      });
    }, 300);

    return () => clearTimeout(id);
  }, [checkpoint, checkpoint.modifiedRow, checkpoint.active]);

  // Rerender tracked row to avoid transition
  return (
    <TableRow
      key={`${checkpoint.id}-${isTrackedRow}`}
      className={
        classNames('row', {
          modifiedRow: !!checkpoint.modifiedRow,
          inactive: editMode && !checkpoint.active,
          tracked: isTrackedRow,
          dragging: isDragging,
        }) +
        ` ${colorClass}` +
        ` ${css(classes.root)}`
      }
      ref={innerRef}
      {...draggableProps}
    >
      <FirstCell {...{ dragHandleProps, row: checkpoint, rowIndex }} />
      <Cells rowIndex={rowIndex} checkpoint={checkpoint} />
      <AddRowAbove row={checkpoint} />
    </TableRow>
  );
});

const Cells = observer(function Cells({
  rowIndex,
  checkpoint,
}: {
  rowIndex: number;
  checkpoint: ExtendedCheckpoint;
}) {
  const activeHeaders = CheckpointsStore.store.activeHeaders;

  const checkpointMeta = CheckpointsStore.store.checkpointMeta(rowIndex);

  if (!checkpointMeta) {
    return null;
  }

  // const editingColumn = checkpoint.editingColumn;
  // console.log('editingColumn', editingColumn);
  const handleUnSelect = () => {
    runInAction(() => {
      // set(checkpoint, { editingColumn: -1 });
      checkpoint.editingColumn = -1;
    });
  };

  const onSelect = (colIdx: number) => {
    runInAction(() => {
      // console.log('store.rowSettingsActive.row', store.rowSettingsActive.row);
      if (CheckpointsStore.store.rowSettingsActive.row) {
        return;
      }
      if (checkpoint.editingColumn !== colIdx) {
        checkpoint.editingColumn = colIdx;
        // if (checkpoint.editingColumn === undefined) {
        //   // extendObservable(checkpoint, { editingColumn: colIdx });
        // } else {
        //   set(checkpoint, { editingColumn: colIdx });
        // }
        // store.resetPopovers();
      }
    });
  };

  return (
    <>
      {activeHeaders.map((header, colIdx) => {
        let cellValue = checkpointMeta[header.accessor];

        if (!cellValue) {
          cellValue = getCheckpointRowValue(checkpoint, header);
        }

        const activeCell = checkpoint.editingColumn === colIdx;

        if (activeCell) {
          return (
            <ActiveCell
              className={`columnCell`}
              key={header.accessor}
              {...{
                header,
                cellValue,
                row: checkpoint,
                handleUnSelect,
              }}
            />
          );
        }

        return (
          <DisplayCell
            className={`columnCell column${colIdx}`}
            header={header}
            key={header.accessor}
            colIdx={colIdx}
            cellValue={cellValue}
            onSelect={onSelect}
          />
        );
      })}
    </>
  );
});

const AddRowAbove = observer(function AddRowBelow({
  row,
  hidden,
}: {
  row: ExtendedCheckpoint;
  hidden?: boolean;
}) {
  const editMode = CheckpointsStore.store.editMode;

  const handleCreateRow = useCreateNewRow({ checkpoint: row, below: false });

  if (hidden) {
    return <div />;
  }

  return (
    <StyledAddRowAbove>
      {editMode && <AddButton onClick={handleCreateRow} />}
    </StyledAddRowAbove>
  );
});

const LastRow = observer(function LastRow() {
  const editMode = CheckpointsStore.store.editMode;
  const tableWrapperEl = CheckpointsStore.store.tableWrapperEl;

  const handleCreateRow = useCreateNewRow({});

  const withScroll = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // console.log('withScroll', e);

      handleCreateRow(e).then(() => {
        if (tableWrapperEl) {
          // console.log('tableWrapperEl exists', tableWrapperEl);

          tableWrapperEl.scrollTo({
            top: tableWrapperEl.scrollHeight,
            behavior: 'smooth',
          });
        }
      });
    },
    [handleCreateRow, tableWrapperEl],
  );

  return (
    <LastTableRow>
      {editMode && <AddButton onClick={withScroll} />}
    </LastTableRow>
  );
});
