import { styled } from '../../colors';

export const SpacerFull = styled('div')`
  flex: 1 1 100%;
`;

export const SpacerAuto = styled('div')`
  flex: 1 1 auto;
`;

export const FlexColumn = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const FlexColumnCenter = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FlexRowCenter = styled('div')`
  display: flex;
  align-items: center;
`;

export const FlexBetween = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FlexEnd = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const FullWidthForm = styled('form')`
  width: 100%;
  margin-top: 8px;
`;
