import React, { ReactNode } from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import colors, { styled } from '../../colors';

const PREFIX = 'DarkDialog';

const classes = {
  paper: `${PREFIX}-paper`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    background: colors.dark,
    border: '2px solid white',
  },
}));

export interface DarkDialogProps extends DialogProps {
  // value: string;
  id: string;
  open: boolean;
  onClose: () => void;

  isAdmin?: boolean;
  onSubmit?: () => void;
  title?: string;
  children?: ReactNode;
  dialogActions?: ReactNode;
  keepMounted?: boolean;
  fullWidth?: boolean;
}

export function DarkDialog(props: DarkDialogProps) {
  const {
    id,
    onClose,
    open,
    title,
    children,
    dialogActions,

    ...other
  } = props;

  const handleCancel = () => {
    onClose();
  };

  // const _handleOk = () => {
  //   onSubmit();
  // };

  return (
    <StyledDialog
      fullWidth
      maxWidth="md"
      // onEntering={handleEntering}
      onClose={handleCancel}
      aria-labelledby={`${id}-title`}
      open={open}
      id={id}
      classes={classes}
      {...other}
    >
      {title && <DialogTitle id={`${id}-title`}>{title}</DialogTitle>}
      <DialogContent dividers={false}>{children}</DialogContent>
      {dialogActions && (
        <StyledDialogActions>{dialogActions}</StyledDialogActions>
      )}
    </StyledDialog>
  );
}

const StyledDialogActions = styled(DialogActions)`
  button {
    color: white;
  }
`;
