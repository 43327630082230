import { DataProxy } from '@apollo/client';

export enum CacheOperation {
  DELETE,
  CREATE,
}

export function updateCache<T, V>(props: {
  variables: any;
  query: any;
  proxy: DataProxy;
  method: CacheOperation;

  /** Data key event.runsheets */
  dataPath: string;

  /** If create */
  mutationResultObject?: any; //if create
  /** IF delete */
  id?: string | number;
  // order?: 'asc'| 'desc'
}) {
  const {
    variables,
    query,
    proxy,
    method,
    dataPath,
    mutationResultObject = null,
    id,
  } = props;

  const data = proxy.readQuery({
    query,
    variables,
  });
  const key = Object.keys(data)[0];
  const dataObject = data[key];
  let modifyingObject = dataObject;
  if (dataPath) {
    modifyingObject = dataObject[dataPath];
  }

  const newItem = performUpdate(
    modifyingObject,
    method,
    mutationResultObject,
    id,
  );
  let finalResult = newItem;
  if (dataPath) {
    finalResult = { ...dataObject, [dataPath]: newItem };
  }

  proxy.writeQuery<any, V>({
    query,
    variables,
    data: {
      [key]: finalResult,
    },
  });
}
function performUpdate<T extends { id: any }>(
  item: T[],
  method: CacheOperation,
  mutationResultObject: any,
  id: string | number,
) {
  let newItem: typeof item;
  if (method === CacheOperation.CREATE) {
    if (!item) item = [];
    newItem = item.concat([mutationResultObject]);
  } else if (method === CacheOperation.DELETE) {
    newItem = item.filter((e) => e.id !== id);
  }

  return newItem;
}
