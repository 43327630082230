import React, { memo } from 'react';

import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import {
  AppEventFragment,
  AppRunsheetFragment,
  encodeInteger,
  Event,
  runsheetSort,
  useEventWithRunsheetsQuery,
  useLatestEventsQuery,
} from '@checkpoints/shared';

import { mediaMobile, styled } from '../../colors';
import { RunsheetDateCircle } from '../../pages/Dashboard/RunsheetDateCircle';
import { StyledEventCell } from '../../pages/Dashboard/StyledEventCell';
import { CheckpointsStore } from '../../stores/checkpointsStore';

function useCurrentEvent(eventId: number) {
  const { data } = useEventWithRunsheetsQuery({
    variables: {
      id: eventId,
    },
    skip: !eventId,
    // fetchPolicy: 'cache-first',
  });

  return data ? data.event : null;
}

function useLatestEvents(selectedEventId: number) {
  const currentEvent = useCurrentEvent(selectedEventId);

  const { data } = useLatestEventsQuery();
  if (!data || !data.eventsByUser || !currentEvent) return [];

  const currentIndex = data.eventsByUser.findIndex(
    (e) => e.id === selectedEventId,
  );

  const copiedEvents = [...data.eventsByUser];

  if (currentIndex !== -1) {
    copiedEvents.splice(currentIndex, 1);
  }
  copiedEvents.unshift(currentEvent);
  copiedEvents.length = 3;

  return copiedEvents;
}

export const EventList = observer(function EventList() {
  const selectedEventId = CheckpointsStore.store.selectedEventId;
  const events = useLatestEvents(selectedEventId);

  return (
    <GridContainer>
      {/* For some reason react cant handle key={event.id}. It spawns duplicates */}
      {events.map((event, idx) => (
        <EventContainer key={idx} event={event as Event} />
      ))}
    </GridContainer>
  );
});

const EventContainer = memo(function EventContainer({
  event,
}: {
  event: Event;
}) {
  const runsheets: AppRunsheetFragment[] = event.runsheets
    .slice()
    .sort(runsheetSort);

  return (
    <StyledEventContainer key={event.id}>
      <StyledEventCell event={event as AppEventFragment} />
      <RunsheetsDiv className="scrollbar">
        {runsheets.map((r) => (
          <Runsheet key={r.id} runsheet={r} />
        ))}
      </RunsheetsDiv>
    </StyledEventContainer>
  );
});

function Runsheet({ runsheet }: { runsheet: AppRunsheetFragment }) {
  const date = runsheet.startDate && new Date(runsheet.startDate);

  return (
    <Link
      to={`/dashboard/runsheets/${encodeInteger(runsheet.id)}`}
      onClick={() => {
        CheckpointsStore.store.setSelectedRunsheetId(runsheet.id);
      }}
    >
      <RunsheetContainer>
        <RunsheetDateCircle>{date && date.getDate()}</RunsheetDateCircle>
        <span>{runsheet.title}</span>
      </RunsheetContainer>
    </Link>
  );
}

const RunsheetsDiv = styled('div')`
  margin: 15px 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: 165px;
`;

const StyledEventContainer = styled('div')`
  img {
    /* height: 90px; */
    width: 100%;
    background: #ddd;
    object-fit: cover;
  }
  a {
    text-decoration: none;
  }
`;

const GridContainer = styled('div')`
  padding: 26px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-gap: 20px;

  ${mediaMobile} {
    > div {
      display: none;
    }
    > div:first-of-type {
      display: block;
    }
  }
`;

const RunsheetContainer = styled('div')`
  display: flex;
  font-size: 0.9rem;
  align-items: center;
  padding-bottom: 15px;

  color: white;
`;
