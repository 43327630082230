export function copyToClipboard(copyTextElement: HTMLInputElement) {
  /* Select the text field */
  copyTextElement.select();
  copyTextElement.setSelectionRange(0, 99999); /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand('copy');

  return copyTextElement.value;
}

// Thanks to https://stackoverflow.com/a/30810322
function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  const successful = document.execCommand('copy');

  if (!successful) {
    throw new Error('Failed to copy text!');
  }

  document.body.removeChild(textArea);
}

export async function copyTextToClipboard(text: string) {
  if (!navigator['clipboard']) {
    fallbackCopyTextToClipboard(text);

    return;
  }

  return navigator['clipboard'].writeText(text);
}
