import React, {
  forwardRef,
  useCallback,
  useLayoutEffect,
  useMemo,
} from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

import { AnimatePresence, LayoutGroup, motion, Variants } from 'framer-motion';
import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';

import {
  getInitials,
  NotificationQueryDataFragment,
  NotificationType,
} from '@checkpoints/shared';

import { StyledOnlineRing } from '../avatar/styles';
import { TooltipWrapper } from '../tooltip';
import { CustomDialog } from '../modals';
import { ServerImage } from '../server-image';

import {
  NotificationTab,
  runsheetNotificationColors,
} from './NotificationsOverlay';

const NEW_TAB_NMBR_OF_ITEMS = 5;
const ALL_TAB_NMBR_OF_ITEMS = 5;

const NOTIFS_OVERLAY_WIDTH = 400;
const PILL_WIDTH = 50;
const ITEM_PADDING = 12;
const ITEM_GAP = 12;

const ITEM_WIDTH = NOTIFS_OVERLAY_WIDTH - PILL_WIDTH - ITEM_PADDING * 3;
const ITEM_HEIGHT = 112 - ITEM_GAP;

export const useEffectOnce = (effect: () => void | (() => void)) => {
  const destroyFunc = useRef<void | (() => void)>();
  const effectCalled = useRef(false);
  const renderAfterCalled = useRef(false);
  const [val, setVal] = useState<number>(0);

  if (effectCalled.current) {
    renderAfterCalled.current = true;
  }

  useEffect(() => {
    // only execute the effect first time around
    if (!effectCalled.current) {
      destroyFunc.current = effect();
      effectCalled.current = true;
    }

    // this forces one render after the effect is run
    setVal((val) => val + 1);

    return () => {
      // if the comp didn't render since the useEffect was called,
      // we know it's the data React cycle
      if (!renderAfterCalled.current) {
        return;
      }
      if (destroyFunc.current) {
        destroyFunc.current();
      }
    };
  }, []);
};

const animationsScroller: Variants = {
  initial: {
    opacity: 0,
    // y: -100,
    scale: 0.6,
  },
  show: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: 'spring',
      duration: 0.6,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.6,
    // y: -100,
    transition: {
      type: 'spring',
      duration: 0.3,
    },
  },
};

interface NotificationsFeedProps {
  data: NotificationQueryDataFragment[];
  tab: NotificationTab;
  hasSeenFn: (id: number) => void;
  hideFn: (id: number) => void;
}

/**
 * **NotificationsFeed**
 * @description Feed of runsheet Notification items.
 */
const NotificationsFeed = ({ data, tab, hideFn }: NotificationsFeedProps) => {
  const [showNotifs, setShowNotifs] = useState<boolean>(false);

  const [newItems, setNewItems] = useState<NotificationQueryDataFragment[]>([]);

  const { classes, cx } = useFeedContainerStyles({
    tab,
  });

  const hideItem = (id: number) => {
    console.log('in removeFn', id);

    let index = 0;
    for (const notification of data) {
      if (notification.id == id) {
        data[index].hidden = true;
        break;
      }
      index++;
    }

    setNewItems(() => {
      return data.filter((item) => item.hidden !== true);
    });

    hideFn(id);
  };

  useEffectOnce(() => {
    // wait before showing notifs

    setTimeout(() => {
      console.log('timeout');
      setShowNotifs(true);
    }, 3000);
  });

  useLayoutEffect(() => {
    console.log('NotifsFeed data changed', data);

    setNewItems(() => {
      const items = data.filter((item) => item.hidden !== true);

      return items;
    });
  }, [data]);

  return (
    <FeedContainer className={cx(classes.FeedContainer)}>
      <NotificationsScroller
        items={showNotifs && (tab === 'new' ? newItems : data)}
        tab={tab}
        removeItemFromNewFn={hideItem}
      />
    </FeedContainer>
  );
};

export const NotificationsScroller = ({
  items,
  removeItemFromNewFn,
  tab,
}: {
  items?: NotificationQueryDataFragment[];
  removeItemFromNewFn?: (id: number) => void;
  tab: NotificationTab;
}) => {
  const [size, setSize] = useState(3);
  const scrollRef = useRef<HTMLDivElement>();
  const { classes, cx } = useScrollerStyles({
    tab,
    size,
  });

  const scrollerSize = useMemo(() => {
    const maxItems = 5; 
      // tab === 'all' ? ALL_TAB_NMBR_OF_ITEMS : NEW_TAB_NMBR_OF_ITEMS;

    return items.length < maxItems ? items.length : maxItems;
  }, [items.length, tab]);

  const reSize = useCallback(() => {
    if (size !== scrollerSize) {
      setSize(scrollerSize);
    }
  }, [scrollerSize, size]);

  useEffect(() => {
    // console.log('tab changed', tab);
    scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight);

 //   if (tab === 'all') {
      reSize();
 //   }
  }, [reSize, tab]);

  return (
    <Scroller ref={scrollRef} layoutScroll className={cx(classes.Scroller)}>
      <LayoutGroup>
        <AnimatePresence
          mode="popLayout"
          initial={false}
          onExitComplete={() => {
            reSize();
          }}
        >
          {items &&
            items.slice(0).reverse().map((item, index) => {
              return (
                <NotificationItem
                  key={item.id}
                  item={item}
                  tab={tab}
                  handleRemoveFromNew={removeItemFromNewFn}
                  scrollRef={scrollRef}
                />
              );
            })}
        </AnimatePresence>
      </LayoutGroup>
    </Scroller>
  );
};

const NotificationItem = forwardRef<
  HTMLDivElement,
  {
    item: NotificationQueryDataFragment;
    tab: NotificationTab;
    handleRemoveFromNew: (id: number) => void;
    scrollRef: React.MutableRefObject<HTMLDivElement>;
  }
>(({ item, tab, handleRemoveFromNew, scrollRef }, ref) => {
  // for testing
  const isOnline = true;
  const isMe = false;

  const [isTruncated, setIsTruncated] = useState<boolean>(false);
  const [isShowingContentDialog, setShowContentDialog] =
    useState<boolean>(false);
  const textRef = useRef<HTMLParagraphElement>();

  const { classes: notificationItemClasses, cx } = useNotificationItemStyles();
  const { classes: columnBoxClasses, cx: ccx } = useColumnBoxStyles({
    type: item.type,
  });

  const openTruncated = useCallback(() => {
    // console.log('in openTruncated');
    setShowContentDialog(true);
  }, []);

  useLayoutEffect(() => {
    // returns true if the element is truncated by css
    if (textRef?.current?.scrollHeight > textRef?.current?.clientHeight) {
      setIsTruncated(true);
    }
  }, [textRef]);

  return (
    <NotificationBox
      layout
      initial={'initial'}
      animate={'show'}
      exit={'exit'}
      variants={animationsScroller}
      viewport={{ once: true, root: scrollRef }}
      className={cx(notificationItemClasses.NotificationBox)}
      ref={ref}
    >
      <ColumnBox className={ccx(columnBoxClasses.ColumnBox)}>
        <InnerBox className={cx(notificationItemClasses.InnerBox)}>
          <AvatarContainer
            className={cx(notificationItemClasses.AvatarContainer)}
          >
            <StyledOnlineRing
              isMe={isMe}
              active={isOnline}
              style={{ margin: 0 }}
            >
              <TooltipWrapper
                title={item.sender.first_name + ' ' + item.sender.last_name}
              >
                {item.sender.image && item.sender.image.thumbnail ? (
                  <ServerImage
                    src={item.sender.image.thumbnail}
                    alt={item.sender.first_name}
                  />
                ) : (
                  <div>{getInitials(item.sender)}</div>
                )}
              </TooltipWrapper>
            </StyledOnlineRing>
          </AvatarContainer>
          <TextContainer
            style={{
              cursor: isTruncated ? 'pointer' : 'default',
            }}
            className={cx(notificationItemClasses.TextContainer)}
            onClick={() => isTruncated && openTruncated()}
          >
            <Content
              ref={textRef}
              className={cx(notificationItemClasses.Content)}
            >
              {item.content}
            </Content>
          </TextContainer>
          <RightBox className={cx(notificationItemClasses.RightBox)}>
            {tab === 'new' && (
              <IconButton onClick={() => handleRemoveFromNew(item.id)}>
                <Close />
              </IconButton>
            )}
          </RightBox>
        </InnerBox>
        <DateString className={cx(notificationItemClasses.DateString)}>
          {new Date(Number(item.createdAt)).toLocaleString('se', {
            timeStyle: 'long',
          })}
        </DateString>
      </ColumnBox>
      {isShowingContentDialog && (
        <CustomDialog
          open={isShowingContentDialog}
          PaperProps={{ style: { padding: '12px' } }}
          onClose={() => setShowContentDialog(false)}
        >
          <Content>{item.content}</Content>
        </CustomDialog>
      )}
    </NotificationBox>
  );
});

const useNotificationItemStyles = makeStyles({ name: { NotificationItem } })(
  (theme) => ({
    NotificationBox: {
      width: `${ITEM_WIDTH}px`,
      height: `${ITEM_HEIGHT}px`,
      padding: `0`,
      flex: 0,
      flexBasis: `${ITEM_HEIGHT}px`,
      flexShrink: 0,
      margin: 0,
    },
    InnerBox: {
      width: '100%',
      height: '100%',
      padding: '2px 12px 2px 12px',
      display: 'flex',
      alignItems: 'center',
    },
    AvatarContainer: {
      width: '15%',
      display: 'inline-flex',
      margin: 0,
    },
    TextContainer: {
      width: '70%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '3',
      WebkitBoxOrient: 'vertical',
    },
    Content: {
      margin: 0,
      fontSize: '0.9rem',
    },
    RightBox: {
      width: '15%',
    },
    DateString: {
      margin: 0,
      color: 'gray',
      fontSize: '0.7rem',
      lineHeight: '1.0',
      textAlign: 'right',
      userSelect: 'none',
      clear: 'both',
    },
  }),
);

const useFeedContainerStyles = makeStyles<{ tab: NotificationTab }>({
  name: 'FeedContainer',
})((theme, { tab }) => {
  return {
    FeedContainer: {
      width: `${ITEM_WIDTH + ITEM_PADDING * 2}px`,
      margin: 0,
      overflowY: 'hidden',
      overflowX: 'hidden',
      position: 'relative',
    },
  };
});

const useScrollerStyles = makeStyles<{
  tab: NotificationTab;
  size: number;
}>({
  name: 'Scroller',
})((theme, { tab, size }) => {
  const scrollerHeight = size * (ITEM_HEIGHT + ITEM_GAP) - ITEM_GAP + 6;

  return {
    Scroller: {
      width: `${ITEM_WIDTH + ITEM_PADDING}px`,
      height: `${scrollerHeight}px`,
      paddingLeft: `${ITEM_PADDING * 2}px`,
      display: 'flex',
      flexDirection: 'column-reverse',
      rowGap: `${ITEM_PADDING}px`,
      overflowY: 'auto',
      overflowX: 'hidden',
      margin: 0,
      scrollbarGutter: 'stable',
      ['&::-webkit-scrollbar']: {
        width: '0.4em',
      },
      ['&::-webkit-scrollbar-track']: {
        marginTop: '2px',
        marginBottom: `2px`,
        borderRadius: '4px',
        boxShadow: 'inset 0 0 6px #000000b8',
        webkitBoxShadow: 'inset 0 0 6px #000000b8',
        transition: 'box-shadow 0.5s ease',
      },
      ['&::-webkit-scrollbar-thumb']: {
        border: '1px solid transparent',
        borderRadius: '4px',
        outline: '1px solid #708090ba',
        background: theme.palette.colors.dimText,
        boxShadow: 'inset 0px 0px 1px 0px #00000040',
        transition: 'background 0.5s ease',
      },
    },
  };
});

const useColumnBoxStyles = makeStyles<{ type: NotificationType }>({
  name: 'ColumnBox',
})((theme, { type }) => {
  const color = runsheetNotificationColors[type];

  return {
    ColumnBox: {
      width: `${ITEM_WIDTH - ITEM_PADDING * 2}px`,
      height: `${ITEM_HEIGHT}px`,
      margin: 0,
      padding: `${ITEM_PADDING}px 4px`,
      border: `1px solid ${color}`,
      background: theme.palette.background.linearGradient,
      boxShadow: '0px 0px 6px 0px grey',
    },
  };
});

const FeedContainer = motion.div;

const Scroller = motion.div;

const NotificationBox = motion.div;

const ColumnBox = motion.div;

const InnerBox = motion.div;

const AvatarContainer = motion.div;

const TextContainer = motion.div;

const Content = motion.p;

const RightBox = motion.div;

const DateString = motion.p;

export default NotificationsFeed;
