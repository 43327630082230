import React, { useCallback, useRef, useState } from 'react';

import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  Grow,
  IconButton,
  MenuItem,
  Paper,
  Popper,
  Select,
  SelectChangeEvent,
  SvgIcon,
  Tooltip,
} from '@mui/material';
import { Send } from '@mui/icons-material';

import {
  AppRunsheetFragment,
  RunsheetUpdate,
  NotificationType,
  useUpdateRunsheetMutation,
  useAddNotificationMutation,
} from '@checkpoints/shared';

import { CheckpointsStore } from '../../stores/checkpointsStore';
import { useEscapeKey } from '../../utils/useWindowListener';
import { ReactComponent as Bullhorn } from '../../assets/bullhorn.svg';
import { TextFieldWithCharacterCount } from '../textfield';
import MainStore from '../../stores/Store';

import { GrayDivider } from './PopoverUtils';

const POPPER_ARROW_SIZE = 1.41;
const popperArrowWidth = `${POPPER_ARROW_SIZE}em`;
// = width / sqrt(2) = (length of the hypotenuse)
const popperArrowHeight = `${POPPER_ARROW_SIZE}em`;

const NotifTypes = Object.getOwnPropertyNames(NotificationType).map((item) => {
  // console.log('item', item);
  // if (!isNaN(Number(item))) {
  //   return null;
  // }

  return item;
});

export const RunsheetNotificationPopover = observer(
  function RunsheetNotificationPopover({
    runsheet,
  }: {
    runsheet: AppRunsheetFragment;
  }) {
    const editMode = CheckpointsStore.store.editMode;
    const isAdmin = CheckpointsStore.store.isAdmin;

    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const anchorEl = useRef<HTMLButtonElement>(null);
    const [arrowRef, setArrowRef] = React.useState(null);
    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    const { classes, cx } = useNotifPopoverStyles();

    const [createNotification] = useAddNotificationMutation({
      fetchPolicy: 'no-cache',
    });

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      // setAnchorEl(event.currentTarget);
      setOpen((previousOpen) => {
        if (!previousOpen) {
          setTooltipVisible(false);
        }

        return !previousOpen;
      });
    };

    const handleClose = useCallback((event: MouseEvent | TouchEvent) => {
      // console.log('in handleClose', event);
      // console.log('document', document.activeElement);

      if (anchorEl.current.contains(document.activeElement)) {
        console.log('anchorEl contains active el, returning');

        return;
      }
      if ((event.target as Node).nodeName === 'body') {
        console.log('event target is body, returning');

        return;
      }
      if (NotifTypes.includes(document.activeElement.id)) {
        // console.log('active element is legit, returning');

        return;
      }
      setOpen(false);

      window.focus();
    }, []);

    useEscapeKey(handleClose);

    //inputs
    const [isInputDisabled, setInputDisabled] = useState<boolean>(false);

    // select box
    const [notifType, setNotifType] =
      useState<keyof typeof NotificationType>('Info');

    const handleChangeType = (
      event: SelectChangeEvent<keyof typeof NotificationType>,
      child: React.ReactNode,
    ) => {
      setNotifType(event.target.value as keyof typeof NotificationType);
    };
    // text input
    const [notifText, setNotifText] = useState<string>('');

    //runsheet update logic
    const [update] = useUpdateRunsheetMutation();

    const updateRunsheet = async (updates: RunsheetUpdate) => {
      // if (updates.showSeconds !== undefined) {
      //   // If we are updating showseconds, our headers have been updated
      //   await saveCurrentUserHeaders(MainStore.store.client);
      // }
      // await update({
      //   variables: {
      //     body: {
      //       id: runsheet.id,
      //       ...updates,
      //     },
      //   },
      //   update: (cache, result) => {
      //     if (!updates) {
      //       return;
      //     }
      //     // These is updated via websockets in Listeners.txt
      //     DashboardStore.store.updateRunsheetCache(
      //       runsheet.eventId,
      //       runsheet.id,
      //       result.data.updateRunsheet,
      //     );
      //   },
      // });
    };

    const resetInputs = () => {
      setNotifType('Misc');
      setNotifText('');
    };

    const handleSend = async () => {
      if (notifText.length === 0) {
        return;
      }
      setInputDisabled(true);

      /* console.log('in handleSend');
      console.log('type:', notifType);
      console.log('msg:', notifText); */

      const body = {
        runsheetId: runsheet.id,
        type: notifType.toString().toLocaleLowerCase(),
        content: notifText,
      };

      await createNotification({
        variables: {
          body,
        },
      });

      setInputDisabled(false);
      resetInputs();
      setOpen(false);
    };

    return (
      <>
        <Tooltip
          title="Send a message to the crew"
          open={tooltipVisible}
          onOpen={(e) => {
            if (open) {
              return;
            }
            setTooltipVisible(true);
          }}
          onClose={(e) => {
            setTooltipVisible(false);
          }}
        >
          <IconButton
            aria-describedby={id}
            type="button"
            onClick={handleClick}
            ref={anchorEl}
          >
            <SvgIcon component={Bullhorn} fontSize="large" inheritViewBox />
          </IconButton>
        </Tooltip>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl.current}
          className={cx(classes.popper)}
          transition
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 20],
              },
            },
            {
              name: 'arrow',
              enabled: true,
              options: {
                element: arrowRef,
              },
            },
          ]}
          placement={'bottom'}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={350}>
              <Paper
                sx={{
                  boxShadow: 'none',
                }}
                elevation={4}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Paper
                    className={cx(classes.popoverRoot)}
                    role="presentation"
                  >
                    <span
                      className={cx(classes.arrow, 'arrow')}
                      ref={setArrowRef}
                    />

                    <Box className={cx(classes.content)}>
                      <FormControl>
                        {/* <InputLabel id="select-label">Type</InputLabel> */}
                        <Select
                          // labelId="select-label"
                          value={notifType}
                          onChange={handleChangeType}
                          disabled={isInputDisabled}
                          MenuProps={{
                            sx: (theme) => ({
                              '& .MuiPaper-root': {
                                backgroundColor: 'white',
                                color: 'black',
                              },
                            }),
                          }}
                        >
                          <MenuItem id={'Misc'} key={'Misc'} value={'Misc'}>
                            {'Message'}
                          </MenuItem>
                          <MenuItem
                            id={'Info'}
                            key={'Info'}
                            value={'Info'}
                            style={{ backgroundColor: '#ffd077' }}
                          >
                            {'Heads up!'}
                          </MenuItem>
                          <MenuItem
                            id={'Alert'}
                            key={'Alert'}
                            value={'Alert'}
                            style={{ backgroundColor: '#ff5f5f' }}
                          >
                            {'Alert'}
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <GrayDivider className={cx(classes.divider)} />
                      <TextFieldWithCharacterCount
                        label="Send a message to the crew"
                        characterCountValue={30}
                        value={notifText}
                        onChange={(val) => setNotifText(val.target.value)}
                        disabled={isInputDisabled}
                      />
                      <GrayDivider className={cx(classes.divider)} />
                      <IconButton
                        type="submit"
                        disabled={notifText.length === 0 || isInputDisabled}
                        onClick={handleSend}
                      >
                        {/* TODO: only display spinner upon actually loading */}
                        {isInputDisabled ? (
                          <CircularProgress size="1em" />
                        ) : (
                          <Send color="primary" />
                        )}
                      </IconButton>
                    </Box>
                  </Paper>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  },
);

const useNotifPopoverStyles = makeStyles({ name: 'NotifPopover' })(
  (theme, _params, classes) => ({
    popper: {
      zIndex: 10,
      ['&[data-popper-placement*="bottom"] .arrow']: {
        top: 0,
        left: 0,
        marginTop: `-${popperArrowHeight}`,
        marginLeft: 0,
        marginRight: 0,
        '&::before': {
          transformOrigin: '0 100%',
        },
      },
    },
    popoverRoot: {
      backgroundColor: '#ffffff',
      color: 'black',
      padding: '6px 6px',
      maxWidth: 500,
      filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.48))',
      boxShadow: '0px 0px 20px #00000026',
      ['& .MuiInputBase-input, & .MuiInputBase-root']: {
        color: 'black',
      },
      ['& .MuiFormLabel-root']: {
        backgroundColor: 'white',
        color: 'black',
      },
    },
    content: {
      display: 'flex',
      padding: '2px',
      alignItems: 'center',
      color: 'black',
    },
    divider: {
      margin: '0px 8px',
    },
    arrow: {
      position: 'absolute',
      width: popperArrowWidth,
      height: popperArrowHeight,
      boxSizing: 'border-box',
      color: 'white',
      zIndex: -1,
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundColor: 'currentColor',
        transform: 'rotate(45deg)',
        boxShadow: '-3px -3px 2px 1px #0000001c',
      },
    },
  }),
);
