import Button from '@mui/material/Button';

import colors, { styled } from '../../colors';

export const CapitalizedButton = styled(Button)`
  text-transform: capitalize;
  font-size: inherit;
`;

export const WhiteContainedButton = styled(Button, { name: 'contained' })(
  () => ({
    color: `${colors.dark}`,
    backgroundColor: '#e0e0e0',
    fontWeight: 700,
  }),
);
WhiteContainedButton.defaultProps = {
  ...WhiteContainedButton.defaultProps,
  variant: 'contained',
};

export const WhiteOutlinedButton = styled(Button, { name: 'outlined' })(
  ({ theme }) => ({
    backgroundColor: 'transparent',
    color: 'white',
    border: '1px solid white',
    // marginRight: '10px',
  }),
);
WhiteOutlinedButton.defaultProps = {
  ...WhiteOutlinedButton.defaultProps,
  variant: 'outlined',
};
