import React, { useMemo } from 'react';

import { observer } from 'mobx-react';
import { Padding } from '@mui/icons-material';

import { Constants, TableColumn, InputType } from '@checkpoints/shared';

import { CheckpointsStore } from '../../stores/checkpointsStore';

import { InvisibleButton, StyledCell } from './StyledTableCells';
const { USER_NOTES_ACCESSOR } = Constants;

interface CellRenderProps {
  // width?: number;
  // inputType: InputType;
  // disabled?: boolean;
  header: TableColumn;
  colIdx: number;
  cellValue: any;
  onSelect?: (colIdx: number) => void;
  onUpdate?: (newValue: any) => void;
  className?: string;
}

export const DisplayCell = observer(function DisplayCell({
  header,
  colIdx,
  cellValue,
  onSelect,
  className,
}: CellRenderProps) {
  const { width, inputType } = header;

  const editMode = CheckpointsStore.store.editMode;
  const showSeconds = CheckpointsStore.store.showSeconds;
  const isUserNote = header.accessor === USER_NOTES_ACCESSOR;
  const disabled = (!editMode && !isUserNote) || header.inputDisabled;

  let innerNode = cellValue;

  const isInputTypeText = inputType === InputType.time;

  const handleSelect = () => {
    onSelect(colIdx);
  };

  const formatValue = useMemo(() => {
    if (isInputTypeText) {
      if (!showSeconds) {
        // if (!this.showSeconds) {
        //remove seconds;
        return cellValue.slice(0, cellValue.length - 3);
        // }
      }
    }

    return cellValue;
  }, [cellValue, isInputTypeText, showSeconds]);

  switch (inputType) {
    case InputType.text:
    case InputType.time:
    case InputType.textarea: {
      // let str: string = value || '';
      // str = str.length > 300 ? str.substr(0, 300) + '...' : str;

      if (disabled) {
        innerNode = (
          <div
            className={isInputTypeText ? 'bold' : undefined}
            style={{ padding: '12px' }}
          >
            {formatValue || ' '}
          </div>
        );
      } else {
        innerNode = (
          <InvisibleButton
            disabled={disabled}
            // onFocus={handleSelect}
            onClick={handleSelect}
            className={isInputTypeText ? 'bold' : undefined}
          >
            <span>{formatValue || ' '}</span>
          </InvisibleButton>
        );
      }

      break;
    }
    // case InputType.boolean:
    //   innerNode = (
    //     <Switch
    //       checked={cellValue}
    //       onChange={event => {
    //         onUpdate(event.target.checked);
    //       }}
    //     />
    //   );
    //   break;
  }

  return (
    <StyledCell
      className={className}
      style={{
        width: width || 200,
        flex: `0 0 auto`,
      }}
    >
      <div className={isInputTypeText ? 'bold' : undefined}>{innerNode}</div>
    </StyledCell>
  );
});
