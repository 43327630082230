import * as React from 'react';

import { ApolloClient, FetchPolicy } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import {
  action,
  computed,
  configure,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
} from 'mobx';
import { Navigate, NavigateFunction } from 'react-router';

import {
  AppCurrentUserFragment,
  formatDate,
  getFullName,
  getInitials,
  LogoutDocument,
  MeDocument,
} from '@checkpoints/shared';

import { DashboardStore } from '../pages/Dashboard/DashboardStore';

const ERROR_TIMEOUT = 5000;

configure({ enforceActions: 'observed' });

export interface Notification {
  message: string;
  options?: {
    variant: 'basic' | 'error' | 'success' | 'undo';
    timeout?: number;
    buttonTitle?: string;
    handlePrimaryAction?: () => void;
  };
}
class MainStore {
  private static _store: MainStore = null;

  static get store() {
    if (!this._store) {
      this._store = new MainStore();
      window['store'] = this._store;
    }

    return this._store;
  }

  wsListener: IReactionDisposer;

  dashboardStore: DashboardStore;

  state = { login: null, dashboard: null };

  constructor() {
    this.dashboardStore = new DashboardStore(this);

    makeObservable(this, {
      navigateTo: action,
      notificationProps: observable,
      displayNotification: action,
      removeNotification: action,
      activeWebsocketLink: observable,
      me: observable,
      meFullName: computed,
      meInitials: computed,
      setMe: action,
      hasActivePaidAccount: computed,
      // TODO: think these should be action
      getMe: action,
      resetStore: action,
      logout: action,
    });
  }

  navigateTo(to: 'login' | 'dashboard') {
    if (to === 'login') {
      this.state.login = true;
    } else if (to === 'dashboard') {
      this.state.dashboard = true;
    }
  }

  notificationProps: Notification = null;

  displayConnectingMessage() {
    setTimeout(() => {
      if (this.me && !this.activeWebsocketLink) {
        this.displayNotification({
          message: 'Attempting to connect to server',
          options: {
            variant: 'basic',
            timeout: -1,
          },
        });
      }
    }, 2000);

    const displaySucessIfConnecting = () => {
      if (!this.notificationProps) return;
      this.displayNotification({
        options: { variant: 'success' },
        message: 'Connected',
      });
      setTimeout(() => {
        this.removeNotification();
      }, 1000);
    };

    this.wsListener = reaction(
      () => this.activeWebsocketLink,
      () => {
        const activeWebsocketLink = this.activeWebsocketLink;

        if (activeWebsocketLink) {
          displaySucessIfConnecting();
        }
      },
    );
  }

  displayError(error: string) {
    this.displayNotification({
      message: error,
      options: {
        variant: 'error',
        timeout: ERROR_TIMEOUT,
      },
    });
  }

  displayNotification(notification: Notification) {
    this.notificationProps = {
      options: {
        variant: 'basic',
      },
      ...notification,
    };
  }

  removeNotification() {
    this.notificationProps = null;
  }

  client: ApolloClient<any>;
  wsLink: WebSocketLink;
  previousPathBeforeLogin: string;
  activeWebsocketLink?: boolean = false;

  me?: AppCurrentUserFragment = null;

  get meFullName() {
    return getFullName(this.me);
  }

  get meInitials() {
    return getInitials(this.me);
  }

  setMe(user: AppCurrentUserFragment) {
    this.me = user;
  }

  get hasActivePaidAccount(): string {
    const paymentExpires = this.me?.paymentExpires;

    const unixTime = Math.round(new Date().getTime() / 1000);

    if (!paymentExpires) {
      return null;
    }

    if (unixTime <= parseInt(paymentExpires)) {
      return this.me.plan;
    } else {
      return null;
    }
  }

  formatPaymentExpires(timeStamp: number | string) {
    const timeStampNumber = Number(timeStamp);
    const planExpires = timeStampNumber ? new Date(timeStampNumber) : null;
    const planExpiresDateString = planExpires
      ? formatDate(planExpires, `yyyy-MM-dd`)
      : '';

    return planExpiresDateString;
  }

  async getMe(fetchPolicy: FetchPolicy = 'no-cache') {
    try {
      const result = await this.client.query({
        fetchPolicy: fetchPolicy,
        query: MeDocument,
      });
      this.setMe(result.data.me);

      return true;
    } catch (e) {
      this.state.login = true;

      return false;
    }
  }

  resetStore = async (navigateToLogin = true) => {
    try {
      this.dashboardStore.clearStore();

      await this.client.clearStore();

      this.wsLink['subscriptionClient'].close();
      this.setMe(null);

      if (navigateToLogin) {
        this.navigateTo('login');
      }
    } catch (e) {
      console.log('ERROR Resetting store', e);
    }
  };

  logout = async (navigateToLogin = true) => {
    await this.client.mutate({
      mutation: LogoutDocument,
    });

    this.resetStore(navigateToLogin);
  };

  render() {
    const { login, dashboard } = this.state;

    return login ? (
      <Navigate to="/login" replace />
    ) : dashboard ? (
      <Navigate to="/dashboard" replace />
    ) : null;
  }
}

export default MainStore;
