import React from 'react';

import { observer } from 'mobx-react';

import { AppUserFragment } from '@checkpoints/shared';

import colors, { styled } from '../../colors';

import { UserContent } from './Avatar';
import { SmallRing } from './styles';

export const DasboardAvatar = observer(function Avatar({
  user,
}: {
  user: AppUserFragment;
}) {
  if (!user) return null;

  return (
    <DashboardUserRowRing className="ring">
      <UserContent user={user} showTooltip={false} />
    </DashboardUserRowRing>
  );
});

export const DashboardUserRowRing = styled(SmallRing)`
  color: ${colors.dimText};
  background: ${colors.dark};
  border: 1px solid white;
  font-weight: bold;
  width: 40px;
  height: 40px;
  font-size: 16px;
  position: relative;
`;
