import React, { useCallback, useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from 'tss-react/mui';
import { Option, optionClasses, Select } from '@mui/base';

import {
  useUpdateDirectorMutation,
  AppUserFragment,
} from '@checkpoints/shared';

import { styled } from '../../colors';
import { Schedule } from '../common/Icons';
import { CheckpointsStore } from '../../stores/checkpointsStore';
import { TooltipWrapper } from '../tooltip';
import { useRunsheetData } from '../../pages/checkpoints/RunsheetHeader';

import { UserContent } from './Avatar';
import { SmallRingButton } from './styles';

const FIELD_WIDTH = 30;
const FIELD_INNER_PADDING = (FIELD_WIDTH - 30) / 2;

interface Props {
  anchorEl: any;
  admins: AppUserFragment[];
}

export const ChangeEditorDropdown = observer(function Dropdown({
  anchorEl,
  admins,
}: Props) {
  // const admins = CheckpointsStore.store.displayedAdmins;
  const allAdmins: number[] = admins.map((item) => item.id);

  const [isHovering, setIsHovering] = useState(false);

  const offset = anchorEl.current ? anchorEl.current.offsetLeft - 4 : 0;
  const selectedEvent = CheckpointsStore.store.selectedEvent;
  const selectedRunsheetId = CheckpointsStore.store.selectedRunsheetId;
  const { runsheet } = useRunsheetData(selectedRunsheetId);
  const runbytime = runsheet?.runbytime ?? false;

  const [updateDirector, { loading, error }] = useUpdateDirectorMutation();
  const [showProFeatures, setShowProFeatures] = useState(false);
  const { classes, cx } = useChangeEditorStyles();

  const director = runsheet?.director;
  // console.log('director', director);

  if (director && !allAdmins.includes(director?.id)) {
    allAdmins.push(director?.id);
  }
  if (runbytime || showProFeatures) {
    allAdmins.unshift(0);
  }
  // console.log('allAdmins', allAdmins);

  const currentDirector = runbytime
    ? 0
    : allAdmins.find((item) => item === director?.id);
  // console.log('currentDirector', currentDirector);

  const handleChangeDirector = useCallback(
    (id: number) => {
      console.log('handleChangeDirector', id);

      // do nothing if selection is same as current director
      if ((runbytime && id === 0) || (!runbytime && id === director.id)) {
        // console.log('selection same as current director');

        return;
      }

      updateDirector({
        variables: {
          runsheetId: CheckpointsStore.store.selectedRunsheetId,
          userId: id,
        },
      });

      // console.log('Change director!');
    },
    [director?.id, runbytime, updateDirector],
  );

  useEffect(() => {
    // console.log('plan changed', selectedEvent);

    if (selectedEvent?.plan === 'PRO') {
      setShowProFeatures(true);
    }
  }, [selectedEvent]);

  const RenderListItem = useCallback(
    ({ id }: { id: number }) => {
      // console.log('RenderListItem', id);

      const user = (() => {
        if (id === 0) {
          return 0;
        }

        const inAdmins = admins.find((item) => {
          // console.log('comparing', item?.id, 'and', id);

          return item?.id === id;
        });

        if (inAdmins) {
          return inAdmins;
        }
        if (!inAdmins && allAdmins.includes(id)) {
          return director;
        }

        return;
      })();

      if (typeof user !== 'object' && isNaN(user)) {
        console.error('passed id does not match a known user');

        return <span></span>;
      }

      const isClockProducer = user === 0;

      return (
        <SmallRingButton
          sx={{
            border: isClockProducer && 'none',
            background: 'none',
            backgroundColor: 'transparent',
          }}
        >
          {isClockProducer ? (
            <TooltipWrapper placement={'right'} title="Clock producer">
              <Schedule
                style={{ color: 'white', height: '100%', width: '100%' }}
                aria-label="Select clock producer"
              />
            </TooltipWrapper>
          ) : (
            <UserContent user={user} toolTipPosition={'right'} />
          )}
        </SmallRingButton>
      );
    },
    [admins, allAdmins, director],
  );

  if (!allAdmins?.length || typeof currentDirector === 'undefined') {
    return null;
  }

  return (
    <FormControl className={cx(classes.root)} variant="outlined">
      <InputLabel
        disableAnimation
        htmlFor="change-editor-input"
        sx={{
          maxWidth: '100%',
          transform: 'translate(0px, 0px) scale(1)',
          padding: `0 ${FIELD_INNER_PADDING}px`,
        }}
      >
        {!loading && (
          <SmallRingButton
            sx={{
              border: runbytime && 'none',
              width: '30px',
              height: '30px',
            }}
          >
            <>
              {runbytime && (
                <TooltipWrapper
                  placement={'right'}
                  title="Clock producer"
                  open={isHovering}
                >
                  <Schedule
                    style={{
                      color: 'white',
                      height: '100%',
                      width: '100%',
                    }}
                    aria-label="Clock producer"
                  />
                </TooltipWrapper>
              )}
              {!runbytime && director && (
                <UserContent
                  user={director}
                  toolTipPosition={'right'}
                  toolTipProps={{
                    open: isHovering,
                  }}
                />
              )}
              {!runbytime && !director && <span>Unknown producer</span>}
            </>
          </SmallRingButton>
        )}
      </InputLabel>
      <Select
        id="change-editor-input"
        className={cx(classes.button)}
        slotProps={{
          listbox: (props) => {
            return { className: cx(classes.popoverRoot) };
          },
          root: (props) => {
            return {
              className: cx(classes.root),
              onMouseEnter: (e) => {
                setIsHovering(true);
              },
              onMouseLeave: (e) => {
                setIsHovering(false);
              },
            };
          },
        }}
        value={currentDirector}
        onChange={(e, val) => {
          // console.log('onChange', e);
          if (typeof val === 'number') {
            // console.log('onChange val', val);
            handleChangeDirector(val);
          }
        }}
      >
        {allAdmins
          .filter((item) => (runbytime ? item !== 0 : item !== director.id))
          .map((item) => {
            return (
              <StyledOption
                key={item}
                style={{
                  padding: `${FIELD_INNER_PADDING}px`,
                  margin: 0,
                  width: `${FIELD_WIDTH}px`,
                  height: `${FIELD_WIDTH}px`,
                  // background: selected && 'white',
                  // backgroundColor: selected && 'white',
                }}
                value={item}
              >
                <RenderListItem id={item} />
              </StyledOption>
            );
          })}
      </Select>
    </FormControl>
  );
});

const useChangeEditorStyles = makeStyles({
  name: 'ChangeEditor',
})((theme, _params, classes) => ({
  root: {
    width: `${FIELD_WIDTH}px`,
  },
  button: {
    height: `30px`,
    border: 0,
    padding: 0,
    background: 'transparent',
  },
  popoverRoot: {
    backgroundColor: theme.palette.colors.nav,
    zIndex: 5,
    color: 'black',
    width: `${FIELD_WIDTH}px`,
    padding: 0,
    margin: 0,
    // maxWidth: 500,
    filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.48))',
    boxShadow: '0px 0px 20px #00000026',
    listStyleType: 'none',
  },
  paper: {
    backgroundColor: theme.palette.colors.nav,
    padding: 0,
    margin: 0,
    width: `${FIELD_WIDTH}px`,
    overflow: 'hidden',
    // color: 'white',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
  },
}));

const StyledOption = styled(Option)(({ theme }) => {
  const midBgColor = theme.palette.colors.backgroundFirst;
  const darkBgColor = theme.palette.colors.backgroundSecond;
  const lightBgColor = theme.palette.background.paper;

  return `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${midBgColor};
  }

  &.${optionClasses.highlighted} {
    background-color: ${darkBgColor};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${midBgColor};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${
      theme.palette.mode === 'dark' ? midBgColor : midBgColor
    };
  }
  `;
});
