import React, { ReactNode } from 'react';

import Button, { ButtonProps } from '@mui/material/Button';

import colors, { styled } from '../../colors';

export const StyledWhiteButton = styled(Button)`
  color: ${colors.primary};
  background-color: #e0e0e0;
  font-weight: 700;
  &:hover {
    background-color: #e0e0e0;
  }
`;

export const ContainedButton = ({
  children,
  ...props
}: { children: ReactNode } & ButtonProps) => {
  return (
    <StyledWhiteButton type="submit" variant="contained" {...props}>
      {children}
    </StyledWhiteButton>
  );
};
