import React from 'react';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';

const staticStyles = {
  small: {
    padding: '6px',
    fontSize: '30px',
  },
  reallySmall: {
    padding: '0px',
    fontSize: '30px',
  },
};

export function SmallIconButton({
  reallySmall,
  children,
  ...props
}: IconButtonProps & {
  reallySmall?: boolean;
}) {
  return (
    <IconButton
      style={reallySmall ? staticStyles.reallySmall : staticStyles.small}
      size="large"
      {...props}
    >
      {children}
    </IconButton>
  );
}
