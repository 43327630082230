import React, { ButtonHTMLAttributes, useEffect, useRef } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import { animate, motion, Variants } from 'framer-motion';

import { styled } from '../../colors';
import { CheckpointsStore } from '../../stores/checkpointsStore';

export const FIRST_CELL_WIDTH = 110;

const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

const variants = {} as Variants;

export const TableContainer = observer(
  ({ children }: { children: JSX.Element }) => {
    const ckptStore = CheckpointsStore.store;
    const tableWrapperEl = ckptStore.tableWrapperEl;

    return (
      <TableWrapper
        className="no_scrollbar"
        id="table-container"
        onScroll={(e) => {
          // CheckpointsStore.store.resetPopovers();
          // if (CheckpointsStore.store.rowSettingsActive.anchorEl) {
          //   CheckpointsStore.store.clearRowSettings();
          // }
          if (isFirefox) {
            firefoxStickyScrollFix(e);
          }
        }}
        ref={(el) => {
          if (!el) return;
          if (tableWrapperEl !== el) {
            // console.log('table container ref', el);
            ckptStore.setTableWrapperEl(el);
          }
        }}
      >
        {children}
      </TableWrapper>
    );
  },
);

export const TableWrapper = styled('div', { name: 'TableWrapper' })`
  width: 100%;
  font-size: 16px;

  position: relative;
  height: calc(100vh - 180px);

  /* header = 88px footer = 92px */

  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export const Table = styled('div', { name: 'Table' })`
  /* flex: auto 1; */
  display: inline-flex;
  flex-direction: column;

  align-items: stretch;
  /* width: 100%; */

  .table-body-wrapper {
    /* overflow: auto; */
  }
  /* border-collapse: collapse; */
  /* border-spacing: 0px 0px; */
  /* table-layout: fixed; */

  /* &.dragging .row {
    height: 50px;
  } */
`;

export const TableBody = styled('div', { name: 'TableBody' })`
  display: flex;
  flex-direction: column;
`;

export const TableHead = styled('div', { name: 'TableHead' })`
  /* -1 is for retina artifacts */
  /* top: -1px; */
  top: 0;
  position: sticky;
  z-index: 10;
`;
type TableRowProps = {
  isDragging?: boolean;
  // editMode?: boolean;
};

export const TableRow = styled(motion.div, { name: 'TableRow' })(
  ({ theme, style }) => ({
    display: 'flex',
    height: '100%',
    width: '100%',
    position: 'relative',

    ['&.tracked:before']: {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '110px',
      right: '120px',
      top: '4px',
      bottom: '4px',
      overflow: 'hidden',
      borderRadius: '5px',
      opacity: 0.9,
      zIndex: -1,
      animation: 'shine 3s linear infinite',
      background:
        'linear-gradient(to right, #3a3a3a00 20%,#dedede 50%, #dedede 60%, #3e3e3e00 100%)',
      backgroundSize: '200% auto',
      ['@keyframes shine']: { '100%': { backgroundPosition: '200% center' } },
      ['& > div']: {
        position: 'relative',
      },
    },
    ['&.inactive']: {
      opacity: 0.5,
    },
    ['&.dragging']: {
      background: 'rgba(0, 0, 0, 0.2)',
    },
  }),
);

export const LastTableRow = styled('div', { name: 'LastTableRow' })`
  display: flex;
  justify-content: center;
  padding-bottom: 12px;
  button {
    z-index: 10;
  }
`;

export const StyledAddRowAbove = styled('div', { name: 'StyledAddRowAbove' })`
  /* display: flex;˝
  align-items: center;
  justify-content: center; */
  /* text-align: center; */
  width: 120px;
  flex: 0 0 auto;

  padding: 4px 0;
  border: 0;
  position: relative;
  button {
    z-index: 1;
    position: absolute;
    top: -22px;
  }
`;

const defaultOutlineColor = '#858585cc';

export const StyledCell = styled('div', { name: 'StyledCell' })<{
  active?: boolean;
}>(({ active }) => ({
  lineHeight: '1.1875em',
  color: 'inherit',
  padding: '4px 0',

  ['& > div']: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    transition: 'background-color 0.3s ease-in-out',

    /* padding: ${(p) => (p.active ? '7px 12px' : '12px 12px')}; */
    borderTop: `1px solid ${defaultOutlineColor}`,
    borderBottom: `1px solid ${defaultOutlineColor}`,
    background: '#535353cc',
  },
  ['&:nth-of-type(2) > div']: {
    borderLeft: `1px solid ${defaultOutlineColor}`,
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
  },
  ['&:nth-last-of-type(-n + 2) > div']: {
    borderRight: `1px solid ${defaultOutlineColor}`,
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
  },
}));

export const SimpleTableTextInput = ({
  props,
}: {
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
}) => {
  return (
    <div>
      <input {...props} className="cell__input--bold" />
    </div>
  );
};

export const TableTextInput = styled(TextField, { name: 'TableTextInput' })`
  padding: 12px;
  width: 100%;
  height: 100%;
  > div {
    color: inherit;
    padding: 0;
  }
  /* input,
  textarea {
    color: inherit;
  } */
`;

export const InvisibleButton = styled('button', { name: 'InvisibleButton' })`
  width: 100%;
  height: 100%;
  border: 0;
  background: 0;
  font-family: inherit;
  font-size: inherit;
  text-align: left;
  /* margin: -4px; */
  /* margin-top: -4px; */
  margin-left: 0px;
  padding: 0;
  /* padding: 6px 0px 7px; */
  /* display: flex;
  align-items: center; */
  /* min-height: 40px; */
  /* max-height: 165px; */
  /* overflow: hidden; */
  transition: color 0.3s ease-in-out;
  color: white;

  overflow-wrap: break-word;
  white-space: pre-wrap;
  /* the text */
  span {
    display: block;
    height: 100%;
    width: 100%;
    padding: 12px;
    line-height: 1.1875em;
  }
  &:focus {
    box-shadow: inset 0px 0px 0px 1px white;
  }
  &:focus-visible {
    outline: 0;
  }
  cursor: pointer;
  &:disabled {
    cursor: auto;
  }
  /* display: inline; */
`;

export const EditPostButton = styled(Button, { name: 'EditPostButton' })``;

export const AddRowButton = styled(Button, { name: 'AddRowButton' })`
  margin: 0;
`;

//TODO: test this on differnt firefox versions and OS's
function firefoxStickyScrollFix(e: any) {
  const height = e.target.children[0].clientHeight - 700;

  if (e.target.scrollTop > height) {
    e.target.scrollTop = height;
    e.preventDefault();
  }
}
