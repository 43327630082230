import {
  InMemoryCache,
  ApolloClient,
  ApolloLink,
  split,
  HttpLink,
  ServerError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
// Setup the network "links"
import { WebSocketLink } from '@apollo/client/link/ws';
import { runInAction } from 'mobx';
import { getMainDefinition } from '@apollo/client/utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { httpurl, wsurl } from '../config';

const NETWORK_ERROR =
  'Server/Network error. There might be an issue with your internet connection. If the issue persists, please contact support!';

export const NETWORK_ERROR_SERVER =
  'Server/Network error. There might be an issue with our servers. If the issue persists, please contact support!';

const allowedUnauthenticatedRoutes = [
  '/login',
  '/signup',
  '/verify',
  '/reset_password',
];

export function createApolloClient(store: import('./Store').default) {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);

      if ((networkError as ServerError).statusCode === 400) {
        store.displayError('Invalid request!');
      } else {
        if (navigator.onLine) {
          store.displayError(NETWORK_ERROR_SERVER);
        } else {
          store.displayError(NETWORK_ERROR);
        }
      }
    } else if (graphQLErrors) {
      for (const err of graphQLErrors) {
        console.log(
          `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`,
        );

        if (err.extensions && err.extensions.code === 'UNAUTHENTICATED') {
          //User is logged out.
          if (
            !allowedUnauthenticatedRoutes.includes(window.location.pathname)
          ) {
            store.previousPathBeforeLogin = window.location.href;
            console.warn('Need nav fix');
            // navigate('/login');
            store.navigateTo('login');
            break;
          }
        } else if (err.extensions && err.extensions.code === 'FORBIDDEN') {
          store.displayError(err.message);

          //User isnt allowed to view the current event or runsheet, redirect to dashboard
          if (window.location.pathname !== '/dashboard') {
            console.warn('Need nav fix');
            // navigate('/dashboard');
            store.navigateTo('dashboard');
            break;
          }
        } else {
          //Dont display UNAUTHENTICATED errors
          store.displayError(err.message);
        }
      }
    }
  });

  const httpLink = new HttpLink({
    uri: httpurl,
    credentials: 'include',
  });

  // const wsLink = new WebSocketLink({
  //   uri: wsurl,
  //   options: {
  //     reconnect: true,

  //   },
  // });

  // const wsClient: SubscriptionClient = wsLink['subscriptionClient'];

  const subscriptionClient = new SubscriptionClient(wsurl, {
    // lazy: true - We dont want to connect before logging in
    lazy: true,
    reconnect: true,

    connectionCallback: (err, result) => {
      let error: Error;

      if (err && err.length !== undefined) {
        error = err[0];
      } else {
        error = err as unknown as Error;
      }

      if (!error) {
        console.log('Websocket connection active');
        runInAction(() => {
          store.activeWebsocketLink = true;
        });
      } else {
        if (error && error.message === 'You must be signed in.') {
          store.resetStore();
        }
        console.log(`err: ${JSON.stringify(err)}, result: ${result}`);
      }
    },
  });

  subscriptionClient.onConnecting((e) => {
    console.log(`WS onConnecting`, e);
    runInAction(() => {
      store.activeWebsocketLink = false;
      store.displayConnectingMessage();
    });
  });

  subscriptionClient.on('disconnected', () => {
    console.log(`WS onDisconnected`);
  });
  subscriptionClient.on('error', () => {
    console.log(`WS onError`);
  });
  subscriptionClient.on('connected', () => {
    console.log(`WS connected`);
  });

  const wsLink = new WebSocketLink(subscriptionClient);

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);

      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );
  const link = ApolloLink.from([errorLink, splitLink]);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache({
      addTypename: true,
      typePolicies: {
        Event: {
          fields: {
            runsheets: {
              merge(_existing = [], incoming: any[]) {
                // replace the Event.runsheets with any incoming array
                return incoming;
              },
            },
          },
        },
      },
      // cacheRedirects: {
      //   Query: {
      //     event: (_, args, { getCacheKey }) => {
      //       console.log('Fetching cached event');
      //       return getCacheKey({ __typename: 'Event', id: args.id });
      //     },
      //     // runsheet: (_, args, { getCacheKey }) => {
      //     //   console.log('Fetching cached runsheet');
      //     //   return getCacheKey({ __typename: 'Runsheet', id: args.id });
      //     // },
      //   },
      // },
    }),
  });

  return { client, wsLink };
}
