import * as React from 'react';

import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { observer } from 'mobx-react';

import { ModalStore } from '../../stores/ModalStore';

export const ConfirmDialog = observer(function ConfirmDialog() {
  return (
    <CustomConfirmDialog
      prompt={ModalStore.store.confirmPrompt}
      open={ModalStore.store.showingConfirmDialog}
      onSubmit={() => ModalStore.store.submitConfirm(true)}
      onCancel={() => ModalStore.store.submitConfirm(false)}
    />
  );
});

interface CustomConfirmDialogProps extends DialogProps {
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  prompt: string;
}

export function CustomConfirmDialog({
  open,
  onSubmit,
  onCancel,
  prompt,
  ...props
}: CustomConfirmDialogProps) {
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={onCancel}
      aria-labelledby="status-confirmation"
    >
      <DialogTitle id="status-confirmation">{prompt}</DialogTitle>
      <DialogActions>
        <Button onClick={onCancel} color="secondary" type="button">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary" type="submit">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
