import React, { useRef, useState } from 'react';

import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import {
  Button,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItem,
} from '@mui/material';
import { ArrowRight, SwapHoriz } from '@mui/icons-material';

import {
  useRemoveUserFromEventMutation,
  useUpdateUserEventPermissionMutation,
  useGetUserInvitationLinkMutation,
} from '@checkpoints/shared';

import {
  AddModalType,
  DashboardStore,
} from '../../pages/Dashboard/DashboardStore';
import { ModalStore } from '../../stores/ModalStore';
import MainStore from '../../stores/Store';
import { copyTextToClipboard } from '../../utils/clipboard';
import { useEscapeKey } from '../../utils/useWindowListener';
import { DeleteIcon, EditIcon, FileCopyIcon } from '../common';

import { usePlainPopperBottomStyle } from './popoverStyles';
import {
  InnerPopover,
  GrayDivider,
  InnerPopoverVertical,
} from './PopoverUtils';
import { SmallIconButton } from './SmallIconButton';

export const DashboardUserPopover = observer(function DashboardUserPopover() {
  const { anchorEl, user, isAdmin } = DashboardStore.store.userPopoverActive;

  const isAdminAtCurrentEvent = DashboardStore.store.isAdminAtCurrentEvent;
  const isSelf = user && user.id === MainStore.store.me.id;

  const handleClose = () => {
    DashboardStore.store.resetPopovers();
  };
  useEscapeKey(handleClose);

  const [del] = useRemoveUserFromEventMutation({ fetchPolicy: 'no-cache' });
  const [move] = useUpdateUserEventPermissionMutation({
    fetchPolicy: 'no-cache',
  });

  const [getInvitationLink] = useGetUserInvitationLinkMutation({
    fetchPolicy: 'no-cache',
  });

  const handleDeleteItem = async () => {
    const activeEvent = DashboardStore.store.activeEvent;

    const confirmMessage = isSelf
      ? `You are about to remove yourself from this event. In doing so, you will loose access to "${activeEvent.title}". 
      Are you sure you want to do this?`
      : `Are you sure you want to remove ${
          user.first_name ? user.first_name : user.email
        } from "${activeEvent.title}"?`;

    const confirmed = await ModalStore.store.confirm(confirmMessage);
    if (!confirmed) return;

    await del({
      variables: {
        id: activeEvent.id,
        user_id: user.id,
        email: user.email,
      },
    });
    if (isSelf) {
      runInAction(function RemoveEventCache() {
        DashboardStore.store.events.remove(activeEvent);
        DashboardStore.store.setActiveEventId(null);
      });

      return;
    }
    runInAction(function DeleteUser() {
      const currentList = DashboardStore.store.activeUserList(isAdmin);
      const observableUser = currentList.find(
        (otherUser) => otherUser.id === user.id,
      );
      currentList.remove(observableUser);
    });
    handleClose();
  };

  const handleMoveUser = async () => {
    await move({
      variables: {
        id: DashboardStore.store.activeEvent.id,
        user_id: user.id,
        isAdmin: !isAdmin,
      },
    });
    runInAction(function MoveUserPermissions() {
      const currentList = DashboardStore.store.activeUserList(isAdmin);
      const otherList = DashboardStore.store.nonActiveUserList(isAdmin);
      const observableUser = currentList.find(
        (otherUser) => otherUser.id === user.id,
      );
      DashboardStore.store.resetPopovers();
      currentList.remove(observableUser);
      otherList.push(observableUser);
    });
  };

  const handleCopyInvitationLink = async () => {
    // TODO: We dont want to have this feature since it is a security issue. However it is needed during beta testing
    const activeEvent = DashboardStore.store.activeEvent;
    const result = await getInvitationLink({
      variables: { email: user.email, eventId: activeEvent.id },
    });
    const invitationLink = result.data.getUserInvitationLink;
    copyTextToClipboard(invitationLink);
    MainStore.store.displayNotification({
      message: 'Invitation link copied to clipboard!',
    });
  };

  const handleClickaway = (e: MouseEvent | TouchEvent) => {
    if (!(e.target as HTMLElement).closest('button.popover')) {
      handleClose();
    }
  };

  const isKnownUser = user && user.first_name;

  const open = !!anchorEl;
  const id = open ? 'simple-popper' : undefined;

  const arrowRef = useRef(null);
  const { classes } = usePlainPopperBottomStyle();

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      // transition
      className={classes.popper}
      placement={'bottom'}
      modifiers={[
        {
          name: 'arrow',
          enabled: false,
          options: {
            element: arrowRef,
          },
        },
      ]}
    >
      <Paper>
        <ClickAwayListener onClickAway={handleClickaway}>
          <div>
            <span className={classes.arrow} ref={arrowRef} />

            <InnerPopover>
              {isAdminAtCurrentEvent ? (
                <>
                  {isKnownUser ? (
                    <Button className={classes.button} onClick={handleMoveUser}>
                      Move to {isAdmin ? 'Subscribers' : 'Admins'}
                    </Button>
                  ) : (
                    <Button
                      className={classes.button}
                      onClick={handleCopyInvitationLink}
                    >
                      Copy invitation link
                    </Button>
                  )}
                  <GrayDivider />
                  <SmallIconButton onClick={handleDeleteItem}>
                    <DeleteIcon color={'error'} />
                  </SmallIconButton>
                </>
              ) : (
                <SmallIconButton onClick={handleDeleteItem}>
                  <DeleteIcon color={'error'} />
                </SmallIconButton>
              )}
            </InnerPopover>
          </div>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
});

export const DashboardRunsheetPopover = observer(
  function DashboardUserPopover() {
    const { anchorEl, runsheet } =
      DashboardStore.store.dashboardRunsheetPopover;

    const me = MainStore.store.me;
    const meOwnsEvent =
      DashboardStore.store.activeEvent &&
      DashboardStore.store.activeEvent.owner.id === me.id;

    const otherEventsOwnedByMe =
      DashboardStore.store.activeEvent &&
      DashboardStore.store.events
        .filter(
          (event) =>
            event.ownerId === me.id &&
            event.id !== DashboardStore.store.activeEvent.id,
        )
        .sort((a, b) => {
          return a.createdAt > b.createdAt ? -1 : 1;
        });

    const handleClose = () => {
      setMoveRunsheetOpen(false);
      moveRunsheetAnchorEl.current = null;
      DashboardStore.store.resetPopovers();
    };
    useEscapeKey(handleClose);

    const handleEditRunsheet = async () => {
      DashboardStore.store.showRunsheetModal(
        AddModalType.EditRunsheet,
        runsheet,
      );
      handleClose();
    };

    const handleDuplicateRunsheet = async () => {
      DashboardStore.store.duplicateRunsheet(runsheet.id).then((created) => {
        handleClose();
        setTimeout(() => {
          // Open the Edit runsheet modal after duplicating
          DashboardStore.store.showRunsheetModal(
            AddModalType.EditRunsheet,
            created,
          );
        }, 500);
      });
    };

    const handleMoveRunsheet = async (eventId: number) => {
      DashboardStore.store.moveRunsheet(runsheet.id, eventId).then((result) => {
        handleClose();
        // do something after
      });
    };

    const handleClickaway = (e: MouseEvent | TouchEvent) => {
      if (!(e.target as HTMLElement).closest('button.popover')) {
        handleClose();
      }
    };

    const open = !!anchorEl;
    const id = open ? 'runsheet-popper' : undefined;

    const moveRunsheetAnchorEl = useRef<HTMLElement>(null);
    const [moveRunsheetOpen, setMoveRunsheetOpen] = useState(false);
    const moveRunsheetId = moveRunsheetOpen
      ? 'move-runsheet-popper'
      : undefined;

    const arrowRef = useRef<HTMLSpanElement>(null);

    const { classes } = usePlainPopperBottomStyle();

    return (
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        // transition
        className={classes.popper}
        placement={'bottom'}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
          {
            name: 'arrow',
            enabled: true,
          },
        ]}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClickaway}>
                <div>
                  <span className={classes.arrow} ref={arrowRef} />
                  <InnerPopoverVertical noPadding>
                    <ListItemButton
                      className={classes.button}
                      onClick={handleEditRunsheet}
                    >
                      <ListItemAvatar>
                        <EditIcon />
                      </ListItemAvatar>
                      <ListItemText primary="Edit runsheet" />
                    </ListItemButton>
                    <ListItemButton
                      className={classes.button}
                      onClick={handleDuplicateRunsheet}
                    >
                      <ListItemAvatar>
                        <FileCopyIcon />
                      </ListItemAvatar>
                      <ListItemText primary="Duplicate runsheet" />
                    </ListItemButton>
                    {meOwnsEvent && otherEventsOwnedByMe.length > 0 && (
                      <>
                        <ListItem
                          secondaryAction={<ArrowRight />}
                          sx={{
                            ['.MuiListItemSecondaryAction-root']: {
                              height: '24px',
                            },
                          }}
                          disablePadding
                        >
                          <ListItemButton
                            className={classes.button}
                            onClick={(e) => {
                              console.log('e', e);

                              moveRunsheetAnchorEl.current =
                                e.target as HTMLButtonElement;
                              setMoveRunsheetOpen((curr) => !curr);
                            }}
                          >
                            <ListItemAvatar>
                              <SwapHoriz />
                            </ListItemAvatar>
                            <ListItemText primary="Move runsheet" />
                          </ListItemButton>
                        </ListItem>
                        <Popper
                          id={moveRunsheetId}
                          open={moveRunsheetOpen}
                          anchorEl={moveRunsheetAnchorEl.current}
                          className={classes.popper}
                          placement="right-start"
                          transition
                          modifiers={[
                            {
                              name: 'offset',
                              options: {
                                offset: [0, 10],
                              },
                            },
                          ]}
                        >
                          {({ TransitionProps }) => (
                            <Fade {...TransitionProps}>
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={() => {
                                    setMoveRunsheetOpen(false);
                                  }}
                                >
                                  <InnerPopoverVertical
                                    noPadding
                                    sx={{
                                      maxHeight: '300px',
                                      overflowY: 'auto',
                                    }}
                                  >
                                    {otherEventsOwnedByMe.map((event) => {
                                      return (
                                        <ListItemButton
                                          key={event.id}
                                          className={classes.button}
                                          onClick={() =>
                                            handleMoveRunsheet(event.id)
                                          }
                                        >
                                          <ListItemText primary={event.title} />
                                        </ListItemButton>
                                      );
                                    })}
                                  </InnerPopoverVertical>
                                </ClickAwayListener>
                              </Paper>
                            </Fade>
                          )}
                        </Popper>
                      </>
                    )}
                  </InnerPopoverVertical>
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    );
  },
);
