import React, { useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react';
import { ButtonBase, Typography } from '@mui/material';
import { Notifications } from '@mui/icons-material';
import { motion } from 'framer-motion';

import {
  NotificationQueryDataFragment,
  NotificationsDocument,
  NotificationsQuery,
  NotificationsQueryVariables,
  NotificationType,
  useAddHiddenMutation,
} from '@checkpoints/shared';

import { mediaTablet, styled } from '../../colors';
import { CheckpointsStore } from '../../stores/checkpointsStore';

import NotificationsFeed from './NotificationsFeed';

export const NOTIFS_OVERLAY_WIDTH = 400;
export const PILL_WIDTH = 50;

// info = beige
// alert = red
// misc = green

export const runsheetNotificationColors = {
  info: '#ebc274',
  alert: '#e85d5d',
  misc: '#176ba7',
};

export type NotificationTab = 'new' | 'all' | 'hide';

// __typename?: 'Notification',
// id?: number | null,
// senderId?: number | null,
// runsheetId?: number | null,
// type?: string | null,
// content?: string | null,
// notifiedAt?: string | null,
// createdAt?: string | null,
// updatedAt?: string | null,
// viewed?: { __typename?: 'User', first_name?: string | null } | null,
// hidden?: { __typename?: 'User', first_name?: string | null } | null

export const useEffectOnce = (effect: () => void | (() => void)) => {
  const destroyFunc = useRef<void | (() => void)>();
  const effectCalled = useRef(false);
  const renderAfterCalled = useRef(false);
  const [val, setVal] = useState<number>(0);

  if (effectCalled.current) {
    renderAfterCalled.current = true;
  }

  useEffect(() => {
    // only execute the effect first time around
    if (!effectCalled.current) {
      destroyFunc.current = effect();
      effectCalled.current = true;
    }

    // this forces one render after the effect is run
    setVal((val) => val + 1);

    return () => {
      // if the comp didn't render since the useEffect was called,
      // we know it's the data React cycle
      if (!renderAfterCalled.current) {
        return;
      }
      if (destroyFunc.current) {
        destroyFunc.current();
      }
    };
  }, []);
};

const sortNotifications = (
  a: NotificationQueryDataFragment,
  b: NotificationQueryDataFragment,
) => {
  // NB: notifications should be sorted so that newest = first, etc.
  return Number(b.createdAt) - Number(a.createdAt);
};

export const NotificationsOverlay = observer(function NotificationsOverlay() {
  const [currentTab, setTab] = useState<'new' | 'all'>('new');
  const [data, setData] = useState<NotificationQueryDataFragment[]>([]);

  const editMode = CheckpointsStore.store.editMode;

  /**
   * **resetNotifications**
   * @description in the event you need to simply reset the local state
   * @param items
   */
  const resetNotifications = (items: NotificationQueryDataFragment[]) => {
    console.log('resetNotifications', items);

    setData((curr) => [...items.sort(sortNotifications)]);
  };

  /**
   * **addNotifications**
   * @description Adds notifications to the array and sorts
   * @param items
   */
  const addNotifications = (items: NotificationQueryDataFragment[]) => {
    console.log('appendNotifications', items);

    setData((curr) => [...curr, ...items].sort(sortNotifications));
  };

  /**
   * **hasSeenNotification**
   * @description Has seen notification with id.
   * @param idHasSeen
   */
  const hasSeenNotification = async (idHasSeen: number) => {
    console.log('hasSeenNotification', idHasSeen);
  };

  const [hidden] = useAddHiddenMutation();

  /**
   * **hideNotification**
   * @description Hide notification with id.
   * @param idToRemove
   */
  const hideNotification = React.useCallback((idToHide: number) => {
    console.log('hideNotification', idToHide);

    hidden({
      variables: {
        id: idToHide,
      },
      update: (_cache) => {
        console.log('hidden', _cache);
      },
    });
  }, []);

  const handleToggle = (tab: 'new' | 'all') => {
    console.log('in handleToggle', tab);

    setTab(tab);
  };

  const Interval = useRef<NodeJS.Timer>();

  useEffectOnce(() => {
    console.log('NotificationsList onEffect');

    return () => clearInterval(Interval.current);
  });

  if (editMode) {
    return null;
  }

  return (
    <OuterContainer>
      <NotificationsFeed
        data={CheckpointsStore.store.notifications}
        tab={currentTab}
        hasSeenFn={hasSeenNotification}
        hideFn={hideNotification}
      />
      <TabSwitchWrapper>
        <TabSwitch position="left">
          <TabSwitchTab style={{ cursor: 'default' }}>
            <Notifications fontSize="large" />
          </TabSwitchTab>
          <TabSwitchTab
            active={currentTab === 'new'}
            onClick={() => handleToggle('new')}
          >
            <ButtonBase title="NEW">
              <Typography
                fontWeight={600}
                style={{ position: 'relative', left: '3px' }}
              >
                NEW
              </Typography>
            </ButtonBase>
          </TabSwitchTab>
          <TabSwitchTab
            active={currentTab === 'all'}
            onClick={() => handleToggle('all')}
          >
            <ButtonBase title="ALL">
              <Typography
                fontWeight={600}
                style={{ position: 'relative', left: '-3px' }}
              >
                ALL
              </Typography>
            </ButtonBase>
          </TabSwitchTab>
          <TabSwitchTabMask tab={currentTab} />
        </TabSwitch>
      </TabSwitchWrapper>
    </OuterContainer>
  );
});

const OuterContainer = styled('div')(({ theme }) => ({
  width: `${NOTIFS_OVERLAY_WIDTH}px`,
  // height: '90%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  position: 'fixed',
  right: `0px`,
  bottom: '60px',
  zIndex: 20,
  mediaTablet: {
    top: '30px',
  },
}));

const NotifOverlayElement = styled(motion.div)`
  display: flex;
  position: fixed;
  color: #ddd;
  pointer-events: none;
  width: ${NOTIFS_OVERLAY_WIDTH}px;
  right: 2px;
  bottom: ${60 + PILL_WIDTH + 1}px;
  max-height: 80%;
  z-index: 22;

  ${mediaTablet} {
    bottom: ${30 + PILL_WIDTH + 1}px;
  }
`;

const NotificationsInner = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  bottom: '65px',
  left: 0,
  pointerEvents: 'auto',

  display: 'flex',
  flexDirection: 'column',

  border: `1px solid ${theme.palette.primary.main}`,
  transition: 'background 0.6s',
  borderRadius: '4px',
  flex: '1 1 auto',
  padding: '0px',
  background: [
    theme.palette.background.default,
    theme.palette.background.linearGradient,
  ],
  // ...linearGradient,
}));

const TabSwitchWrapper = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  // bottom: 0,
  // right: '24px',
  height: '52px',
  width: '200px',
  border: `1px solid ${theme.palette.colors.dimText}`,
  borderRadius: '2px',
  backgroundColor: theme.palette.background.default,
  padding: '8px',
  marginTop: '12px',
  marginRight: '24px',
  boxShadow: '0px 0px 6px 0px grey',
}));

const TabSwitch = styled(motion.div)<{ position: 'left' | 'right' }>(
  ({ theme, position }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
  }),
);

const TabSwitchTab = styled(motion.div)<{ active?: boolean }>(
  ({ theme, active = false }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flex: 1,
    padding: '0',
    zIndex: 35,
    position: 'relative',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: 'normal',
    // backgroundColor: active && theme.palette.colors.dimText,
    ['.MuiButtonBase-root']: {
      color: active ? theme.palette.colors.dark : theme.palette.colors.dimText,
      transition: 'color 200ms 200ms',
    },
  }),
);

const TabSwitchTabMask = styled(motion.div)<{ tab: 'new' | 'all' }>(
  ({ theme, tab }) => ({
    width: '33%',
    height: '100%',
    position: 'absolute',
    backgroundColor: theme.palette.colors.dimText,
    zIndex: 30,
    top: 0,
    left: tab === 'new' ? '35%' : '66%',
    transition: 'left cubic-bezier(.88, -.35, .565, 1.35) .4s',
    boxShadow: '0px 0px 6px 0px grey',
  }),
);
