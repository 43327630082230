import { useEffect } from 'react';

import {
  UpdateTrackedCheckpointDocument,
  UpdateTrackedCheckpointMutationFn,
  UpdateTrackedCheckpointMutationVariables,
  Constants,
} from '@checkpoints/shared';

import { CheckpointsStore } from '../checkpointsStore';
import MainStore from '../Store';
const { UP_KEYS, DOWN_KEYS } = Constants;

export function useTrackNextRow(skip = false) {
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (skip) return;
      if (e.repeat) {
        return;
      }
      let movement = 0;
      //Space
      if (e.keyCode === 32) {
        movement = 1;
        if (e.shiftKey) movement = -1;
      }
      if (UP_KEYS.includes(e.keyCode)) movement = -1;
      if (DOWN_KEYS.includes(e.keyCode)) movement = 1;

      if (movement !== 0) {
        // const userIsEditing = sortedCheckpoints.some(
        //   c => c.editingColumn !== undefined && c.editingColumn !== -1,
        // );

        if (!document.activeElement) {
          return true;
        }

        const userIsNotEditing = ['BODY', 'DIV'].includes(
          document.activeElement.tagName,
        );

        if (!userIsNotEditing) {
          return true;
        }
        if (CheckpointsStore.store.editMode) {
          return true;
        }
        if (CheckpointsStore.store.userIsDirector) {
          const sortedCheckpoints = CheckpointsStore.store.sortedCheckpoints;
          const nextIdx = CheckpointsStore.store.trackedRowIndex + movement;
          const nextCheckpoint = sortedCheckpoints[nextIdx];
          if (!nextCheckpoint) return;
          const nextId = nextCheckpoint.id;

          MainStore.store.client.mutate<
            UpdateTrackedCheckpointMutationFn,
            UpdateTrackedCheckpointMutationVariables
          >({
            mutation: UpdateTrackedCheckpointDocument,
            variables: {
              runsheetId: CheckpointsStore.store.selectedRunsheetId,
              checkpointId: nextId,
            },
          });
        }
      }
    };
    document.addEventListener('keydown', listener);

    return () => {
      document.addEventListener('keydown', listener);
    };
  }, [skip]);

  return null;
}
