import React from 'react';
import { useEffect } from 'react';

import { observer } from 'mobx-react';
import { useSearchParams } from 'react-router-dom';

import { Header } from '../Dashboard/DashboardHeader';
import { useSplitDivStyles } from '../../components/split-container';
import { CheckpointsStore } from '../../stores/checkpointsStore';
import MainStore from '../../stores/Store';
import { serverUrl } from '../../config';

import { Receipts } from './Receipts';
import { Profile } from './Profile';
import { Billing } from './Billing';

export const Account = observer(function Account() {
  const { classes, cx } = useSplitDivStyles();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const checkout_session_id = searchParams.get('checkout_session_id');

    console.log('checkout_session_id', checkout_session_id);

    if (checkout_session_id) {
      const user_id = MainStore.store.me.id;

      fetch(serverUrl + '/stripe_session', {
        // Enter your IP address here

        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          session_id: checkout_session_id,
          user_id: user_id,
        }),
      }).then(async (response) => {
        const result = await response.json();

        if (result.planLevel > 0) {
          window.location.replace('/dashboard/account?hasPlan=true');
        }
      });
    } else {
      const user_id = MainStore.store.me.id;

      fetch(serverUrl + '/stripe_update_user', {
        // Enter your IP address here

        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: user_id }),
      }).then(async (response) => {});
    }
  }, []);

  return (
    <div className="fadeInFromAbove">
      <Header>{`Account`}</Header>
      <div className={cx(classes.root)}>
        <Profile />
        <div className={cx(classes.root)}>
          <Receipts />
          <Billing />
        </div>
      </div>
    </div>
  );
});
