import React, {
  memo,
  useEffect,
  useState,
  ReactNode,
  useCallback,
} from 'react';

import { RunsheetInput, RunsheetUpdate } from '@checkpoints/shared';
import {
  END_TIME_ACCESSOR,
  START_TIME_ACCESSOR,
} from '@checkpoints/shared/src/constants';

import { mediaTablet, styled } from '../../colors';
import { Checkbox } from '../checkbox';
import {
  DateField,
  TextFieldWithCharacterCount,
  TimeField,
} from '../textfield';
import { CheckpointsStore } from '../../stores/checkpointsStore';
import { DashboardStore } from '../../pages/Dashboard/DashboardStore';
import { useUpdateUserHeader } from '../../stores/helpers';
import { ImportExportActions } from '../../pages/checkpoints/ImportStore';

export const InnerRunsheetEditor = memo(function InnerColumnPopover({
  runsheet,
  updateRunsheet,
  updateHeader,
  autoFocus,
  children,
}: {
  runsheet: RunsheetInput;
  updateRunsheet: (update: Omit<RunsheetUpdate, 'id'>) => Promise<void>;
  updateHeader: (header: any, update: any) => void;
  autoFocus?: boolean;
  children?: ReactNode;
}) {
  const [localRunsheet, setLocalRunsheet] = useState(runsheet);
  const { title, startDate, startTime, showSeconds } = localRunsheet;
  const [showProFeatures, setShowProFeatures] = useState(false);

  const handleUpdateRemote = useCallback(
    (name: string, value: string) => {
      console.log('handleUpdateRemote', name, value);

      updateRunsheet({ [name]: value }).catch(() => {
        console.warn('Error when updating runsheet, resetting local state.');

        setLocalRunsheet(runsheet);
      });
    },
    [runsheet, updateRunsheet],
  );

  const handleChange = useCallback(
    (name: string, value: string) => {
      console.log('handleChange', name, value);

      setLocalRunsheet((local) => ({
        ...local,
        [name]: value,
      }));

      handleUpdateRemote(name, value);
    },
    [handleUpdateRemote],
  );

  const updateShowSeconds = useCallback(
    async (value: boolean) => {
      const columns = CheckpointsStore.store.activeHeaders;

      const header_start = columns.find(
        (h) => h.accessor === START_TIME_ACCESSOR,
      );
      const header_end = columns.find((h) => h.accessor === END_TIME_ACCESSOR);
      const header_duration = columns.find((h) => h.accessor === 'duration');

      if (!showSeconds) {
        if (header_start && header_start.width < 95) {
          updateHeader(header_start, { width: 95 });
        }
        if (header_end && header_end.width < 95) {
          updateHeader(header_end, { width: 95 });
        }
        if (header_duration && header_duration.width < 95) {
          updateHeader(header_duration, { width: 95 });
        }
      } else {
        if (header_start && header_start.width > 70) {
          updateHeader(header_start, { width: 70 });
        }
        if (header_end && header_end.width > 70) {
          updateHeader(header_end, { width: 70 });
        }
        if (header_duration && header_duration.width > 70) {
          updateHeader(header_duration, { width: 70 });
        }
      }

      await updateRunsheet({ showSeconds: value });
    },
    [updateRunsheet],
  );

  const handleKeyDown = useCallback((e: any) => {
    if (e.keyCode === 13) {
      (e.target as HTMLInputElement).blur();
    }
  }, []);

  useEffect(() => {
    setLocalRunsheet(runsheet);

    const selectedEvent = DashboardStore.store.activeEvent;

    if (selectedEvent?.plan === 'PRO') {
      setShowProFeatures(true);
    } else {
      const anyRunsheet = runsheet as any;

      if (anyRunsheet?.event?.plan === 'PRO') {
        setShowProFeatures(true);
      }
    }
  }, [runsheet]);

  return (
    <InnerPopover>
      <LargeInputDiv>
        <TextFieldWithCharacterCount
          characterCountValue={30}
          value={title}
          placeholder={'Title'}
          label="Title"
          onChange={(e) => {
            handleChange('title', e.target.value);
          }}
          autoFocus={autoFocus}
        />
      </LargeInputDiv>
      <SmallInputDiv>
        <DateField
          label="Date"
          value={startDate}
          onChange={(date) => {
            // console.log('DatePicker onChange', date);

            handleChange('startDate', date);
          }}
        />
      </SmallInputDiv>
      <SmallInputDiv>
        <TimeField
          value={startTime}
          label="Time"
          showSeconds={showSeconds}
          onBlur={(e) => {
            let value = e.target.value;

            if (value.length === 5) {
              value = value + ':00';
            }
            handleChange('startTime', value);
          }}
          onChange={(date) => {
            // handleChange('startTime', date);
          }}
        />
      </SmallInputDiv>
      <>
        {showProFeatures && (
          <div>
            <Checkbox
              label={'Show seconds'}
              value={showSeconds}
              onChangeValue={updateShowSeconds}
            />
          </div>
        )}
        {runsheet.eventId && <ImportExportActions />}
      </>

      {children}
    </InnerPopover>
  );
});

const InnerPopover = styled('div', { name: 'InnerPopover' })`
  display: flex;
  flex-wrap: wrap;
  padding: 18px 16px;

  ${mediaTablet} {
    flex-direction: column;
    label {
      width: auto;
      display: block;
    }
  }
  color: white;

  & > div {
    padding: 10px;
  }

  .MuiFormLabel-root.Mui-focused,
  .MuiInputLabel-root.Mui-focused {
    color: white;
  }
  .MuiInput-root::after {
    border-bottom: 2px solid white;
  }
`;

const LargeInputDiv = styled('div', { name: 'LargeInputDiv' })`
  input {
    width: 260px;
  }
`;

const SmallInputDiv = styled('div', { name: 'SmallInputDiv' })`
  input {
    width: 120px;
  }
`;
