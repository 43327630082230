import { FetchResult } from '@apollo/client';
import { runInAction } from 'mobx';

import {
  ActiveUserListDocument,
  ActiveUserListQuery,
  MutationType,
  OnActiveUserListChangedDocument,
  OnActiveUserListChangedSubscription,
} from '@checkpoints/shared';

import { CheckpointsStore } from '../../stores/checkpointsStore';
import MainStore from '../../stores/Store';

/**
 * ActiveUserListener listens for online users for the selected runsheet
 * This updates the green circles on the user avatars that become online
 * 
 *  This keeps track of the runsheet-users-list
  Adding or deleting users are recoreded
 */
export class ActiveUserListener {
  private static _store: ActiveUserListener = null;
  static get store() {
    if (!this._store) {
      this._store = new ActiveUserListener();
    }

    return this._store;
  }

  listener: ZenObservable.Subscription;

  fetchActiveUserList = (runsheetId: number) => {
    MainStore.store.client
      .query<ActiveUserListQuery>({
        query: ActiveUserListDocument,
        variables: {
          runsheetId,
        },
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        if (data && data.activeUserList) {
          CheckpointsStore.store.setActiveUsers(data.activeUserList);
        }
      });
  };
  startListening = (runsheetId: number) => {
    if (!runsheetId) {
      return;
    }

    this.fetchActiveUserList(runsheetId);

    const client = MainStore.store.client;

    this.listener = client
      .subscribe({
        query: OnActiveUserListChangedDocument,
        variables: {
          runsheetId: runsheetId,
        },
      })
      .subscribe(this.onActiveUsersChanged);
  };
  onActiveUsersChanged = (
    result: FetchResult<OnActiveUserListChangedSubscription>,
  ) => {
    const data = result.data.activeUserListChanged;

    runInAction(() => {
      if (data.mutation === MutationType.Created) {
        CheckpointsStore.store.onlineUsersForRunsheet.add(data.userId);
      } else if (data.mutation === MutationType.Deleted) {
        CheckpointsStore.store.onlineUsersForRunsheet.delete(data.userId);
      }
    });
  };
  stopListening = () => {
    if (this.listener) {
      this.listener.unsubscribe();
      this.listener = null;
    }
  };
}
