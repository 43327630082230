import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { observer } from 'mobx-react';
import {
  ClickAwayListener,
  Fade,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
} from '@mui/material';

import { CheckpointsStore } from '../../stores/checkpointsStore';
import { handleReorder } from '../../stores/helpers';

import { ArrowedButton } from './PopoverUtils';

type MoveMode = 'before' | 'after' | null;

export const MovePopover = observer(() => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);
  const [moveMode, setMoveMode] = useState<MoveMode>(null);
  const subMenuRef = useRef<HTMLDivElement>(null);

  let checkpoints = CheckpointsStore.store.sortedCheckpoints;
  const multiSelectMode = CheckpointsStore.store.multiSelectMode ?? false;
  const multiSelected = CheckpointsStore.store.multiSelected;

  const fromCheckpointId = CheckpointsStore.store.rowSettingsActive.row.id;
  const fromIdx = checkpoints.findIndex((c) => c.id === fromCheckpointId);

  const handleToggleOpen = useCallback(() => {
    console.log('handleToggleMenu');

    setOpen((curr) => !curr);
  }, []);

  const onMove: React.MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    //index of clicked checkpt = index of item to set before/after
    const buttonIdx = parseInt(
      (e.currentTarget as HTMLDivElement).dataset.index,
    );

    // index to place the checkpt at
    const toIdx = buttonIdx + (moveMode === 'before' ? -1 : 1);

    if (!multiSelectMode) {
      handleReorder(fromIdx, toIdx);
    } else {
      //      console.log('toIdx', toIdx);

      const itemToMoveTo = checkpoints[buttonIdx];
      //    console.log('moving to', itemToMoveTo.title);
      const multiSelectedItems = [];

      for (const i of multiSelected) {
        multiSelectedItems.push(checkpoints.find((c) => c.id === i));
      }

      if (moveMode == 'after') {
        multiSelectedItems.sort((a, b) => b.order - a.order);
      } else {
        multiSelectedItems.sort((a, b) => a.order - b.order);
      }

      for (const item of multiSelectedItems) {
        checkpoints = CheckpointsStore.store.sortedCheckpoints;

        /*
        console.log('Start moving ' + item.title);
        let i = 0;
        for (let checkpoint of checkpoints) {
          console.log(i++ + " " + checkpoint.title);
        }
        */

        //let itemMoveFrom = (checkpoints.find((c) => c.id === item.id));
        //console.log('itemMoveFrom', itemMoveFrom.title);

        const moveFromIndex = checkpoints.findIndex((c) => c.id === item.id);
        let moveToIndex = checkpoints.findIndex(
          (c) => c.id === itemToMoveTo.id,
        );

        if (moveMode == 'before') {
          moveToIndex = moveToIndex - 1;
        }

        if (moveFromIndex > moveToIndex) {
          moveToIndex = moveToIndex + 1;
        }

        //console.log('moveFromIndex', moveFromIndex);
        //console.log('moveToIndex', moveToIndex);

        handleReorder(moveFromIndex, moveToIndex);

        /*
        checkpoints = CheckpointsStore.store.sortedCheckpoints;
        console.log('Done moving...' + item.title);
        i = 0;
        for (let checkpoint of checkpoints) {
          console.log(i++ + " " + checkpoint.title);
        }
        */
      }
    }

    CheckpointsStore.store.resetPopovers();
  }, []);

  const setSubMenu = useCallback(
    (e: MouseEvent<HTMLDivElement>, moveMode: MoveMode) => {
      subMenuRef.current = null;
      setMoveMode(null);

      // to prevent flickering from the anchorEl switching while it's open
      setTimeout(() => {
        subMenuRef.current = e.target as HTMLDivElement;
        setMoveMode(moveMode);
      }, 5);
    },
    [],
  );

  useEffect(() => {
    if (!open) {
      setMoveMode(null);
      subMenuRef.current = null;
    }
  }, [open]);

  const id = buttonRef?.current && 'moveCheckpointPopover';
  const subMenuOpen = !!subMenuRef?.current && !!moveMode;
  const submenuId =
    subMenuRef?.current && moveMode === 'before'
      ? 'beforeCheckpointPopover'
      : 'afterCheckpointPopover';

  return (
    <>
      <ArrowedButton
        ref={buttonRef}
        onClick={() => {
          handleToggleOpen();
        }}
        disabled={multiSelectMode && !multiSelected?.length}
        arrowDirection="down"
      >
        <ListItemText primary="Move" />
      </ArrowedButton>
      {open && (
        <Popper id={id} open={open} anchorEl={buttonRef.current} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Paper elevation={4}>
                <ClickAwayListener
                  onClickAway={() => {
                    setOpen(false);
                  }}
                >
                  <List>
                    <ArrowedButton
                      onClick={(e) => {
                        setSubMenu(e, 'before');
                      }}
                      arrowDirection="right"
                    >
                      <ListItemText primary="Before" />
                    </ArrowedButton>
                    <ArrowedButton
                      onClick={(e) => {
                        setSubMenu(e, 'after');
                      }}
                      arrowDirection="right"
                    >
                      <ListItemText primary="After" />
                    </ArrowedButton>
                    {subMenuOpen && (
                      <Popper
                        id={submenuId}
                        open={subMenuOpen}
                        anchorEl={subMenuRef.current}
                        placement="right-start"
                        transition
                      >
                        {({ TransitionProps }) => (
                          <Fade {...TransitionProps}>
                            <Paper elevation={4}>
                              <List
                                sx={{ maxHeight: '300px', overflowY: 'auto' }}
                              >
                                {checkpoints.map((c, idx) => (
                                  <ListItemButton
                                    key={c.id}
                                    disabled={
                                      !multiSelectMode
                                        ? idx === fromIdx
                                        : multiSelected.includes(c.id)
                                    }
                                    data-index={idx}
                                    onClick={onMove}
                                  >
                                    <ListItemText
                                      primary={`${idx + 1}. ${
                                        c.title || 'Untitled Checkpoint'
                                      }`}
                                    />
                                  </ListItemButton>
                                ))}
                              </List>
                            </Paper>
                          </Fade>
                        )}
                      </Popper>
                    )}
                  </List>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </>
  );
});
