import React, { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'query-string';
import { Box } from '@mui/material';

import {
  ErrorCodes,
  useLoginMutation,
  useResendEmailMutation,
  useSendPasswordResetLinkMutation,
} from '@checkpoints/shared';

import { mediaMobile, styled } from '../../colors';
import { ContainedButton } from '../../components/button';
import { PersonAddOutlined, VpnKeyIcon } from '../../components/common';
import { InnerContainer, OuterContainer } from '../../components/layouts';
import { Logo, useAnimateLogoV2 } from '../../components/server-image';
import { TooltipWrapper } from '../../components/tooltip';
import { ModalStore } from '../../stores/ModalStore';
import MainStore from '../../stores/Store';
import { useFormStyles } from '../../components/form';

const default_user = {
  email: '',
  password: '',
};

export const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { classes, cx } = useFormStyles();

  const { email } = parse(location.search);
  const [state, setState] = useState<typeof default_user>(default_user);

  const [login] = useLoginMutation();
  const [resend] = useResendEmailMutation();

  const resendEmail = async () => {
    await resend({
      variables: {
        email: state.email,
      },
    });
    MainStore.store.displayNotification({
      message: 'Email sent!',
      options: {
        variant: 'basic',
      },
    });
  };

  useEffect(() => {
    const user = MainStore.store.me;

    // if we are logged in as a different user than the email in the url?
    if (user && user.email !== email) {
      MainStore.store.logout(false);

      return;
    }
    MainStore.store.getMe().then((loggedIn) => {
      if (loggedIn) {
        navigate('/');
      }
    });
  }, [email, navigate]);

  const onChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setState((s) => ({ ...s, [name]: value }));
  };

  const updateForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    MainStore.store.removeNotification();
    login({
      variables: {
        username: state.email,
        password: state.password,
      },
    })
      .then(() => MainStore.store.getMe('network-only'))
      .then(() => {
        navigate('/dashboard');
      })
      .catch((e: ApolloError) => {
        let errorMessage = e.message;
        let code = '';
        if (e.graphQLErrors.length) {
          const graphqlError = e.graphQLErrors[0];
          errorMessage = graphqlError.message;
          code =
            graphqlError.extensions && (graphqlError.extensions.code as string);
          if (code === ErrorCodes.EmailNotVerifiedError) {
            MainStore.store.displayNotification({
              message: errorMessage,
              options: {
                variant: 'basic',
                buttonTitle: 'Resend email',
                handlePrimaryAction: resendEmail,
              },
            });

            return;
          }
        }
        MainStore.store.displayError(errorMessage);
      });

    return false;
  };
  const { forwards, backwards } = useAnimateLogoV2();
  const [sendReset] = useSendPasswordResetLinkMutation();

  const handleForgotPassword = async () => {
    const email = await ModalStore.store.prompt({
      header: 'Forgot your password?',
      message: 'Enter your email adress to reset it.',
    });

    if (!email) {
      return null;
    }
    await sendReset({
      variables: {
        email,
      },
    });

    return MainStore.store.displayNotification({
      message: 'Password reset link sent! Please check your inbox.',
    });
  };

  return (
    <OuterContainer>
      <InnerContainer>
        <VerticallyCenteredDiv>
          <form
            className={cx(classes.borderedForm)}
            onSubmit={updateForm}
            onMouseEnter={() => {
              forwards(true);
            }}
            onMouseLeave={() => {
              backwards();
            }}
          >
            <div className={cx(classes.logoImageDiv)}>
              <Logo variant="white" />
            </div>
            <TextField
              type="email"
              label={'Email'}
              name="email"
              autoComplete="username"
              value={state.email}
              onChange={onChange}
            />
            <TextField
              name="password"
              type="password"
              label={'Password'}
              autoComplete="current-password"
              value={state.password}
              onChange={onChange}
            />

            <div className="spacer" />
            <FlexBetween>
              <Box
                sx={{ display: 'flex', [mediaMobile]: { display: 'block' } }}
              >
                <TooltipWrapper title="Need an account? Sign up here!">
                  <IconButton
                    size="large"
                    onClick={() => {
                      navigate('/signup');
                    }}
                  >
                    <PersonAddOutlined />
                  </IconButton>
                </TooltipWrapper>

                <TooltipWrapper title="Forgot your password?">
                  <IconButton onClick={handleForgotPassword} size="large">
                    <VpnKeyIcon />
                  </IconButton>
                </TooltipWrapper>
              </Box>
              <ContainedButton>Login</ContainedButton>
            </FlexBetween>
          </form>
          {/* <Signup /> */}
        </VerticallyCenteredDiv>
      </InnerContainer>
    </OuterContainer>
  );
};

const VerticallyCenteredDiv = styled('div')`
  height: calc(100vh - 240px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FlexBetween = styled('div')`
  display: flex;
  justify-content: space-between;

  ${mediaMobile} {
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
  }

  button {
    svg {
      color: white;
    }
  }
`;
