import React from 'react';

import { observer } from 'mobx-react';

import { Constants } from '@checkpoints/shared';

import colors, { styled } from '../../colors';
import { ModalStore } from '../../stores/ModalStore';

const StyledAlertText = styled('span')`
  color: white;
`;

const StyledAlert = styled('div')`
  position: fixed;
  bottom: 18px;
  right: 18px;
  width: 400px;
  max-width: calc(100% - 40px);
  background: ${colors.dark};
  padding: 18px;
  border-radius: 2px;
  z-index: 10000;
  border: 1px solid white;
`;

const CloseButton = styled('button')`
  background-color: transparent;
  border: 0;
  padding: 8px;
  color: white;
  cursor: pointer;

  float: right;

  display: flex;
  align-items: center;
  font-size: 16px;
`;

export const NotPaidRunsheetAlert = observer(function NotPaidRunsheetAlert() {
  const visible = ModalStore.store.runsheetNotPaidAlert;

  const onClose = () => {
    ModalStore.store.setRunsheetNotPaidAlert(false);
  };

  if (!visible) {
    return null;
  }

  return (
    <StyledAlert>
      <StyledAlertText>
        {Constants.ERRORS.OPEN_READ_ONLY_RUNSHEET}
      </StyledAlertText>
      <CloseButton onClick={onClose}>
        <span>OK!</span>
        {/* <CloseIcon color="inherit" fontSize={'inherit'} /> */}
      </CloseButton>
    </StyledAlert>
  );
});
