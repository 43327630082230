import React, { useCallback, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useUpdateUserMutation } from '@checkpoints/shared';

import exitIcon from '../../assets/exit-icon.png';
import colors, { styled } from '../../colors';
import { WhiteContainedButton } from '../../components/button';
import { AddIcon, RemoveIcon } from '../../components/common';
import { SuccessIcon } from '../../components/success/SuccessIcon';
import { useMainStore } from '../../stores';
import MainStore from '../../stores/Store';

import { PaymentUser, useBillingStore } from './BillingStore';
import { PaymentUpdatedListener } from './PaymentUpdatedListener';

const StyledSuccessDiv = styled('div')`
  > div {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h2 {
    color: #666;
    font-weight: 100;
    margin-top: 0;
  }

  .blue {
    color: white;
    background: ${colors.primary};
  }

  .success-container {
    padding: 80px 40px;
    font-size: 20px;
    font-weight: 100;
    text-align: center;
  }
`;

const SuccessScreen = () => {
  const navigate = useNavigate();
  const billingStore = useBillingStore();

  return (
    <>
      <StyledSuccessDiv>
        <div className="success-container">
          <h2 className="modal-title">Success!</h2>
          <SuccessIcon />
        </div>
      </StyledSuccessDiv>
      <StyledSuccessDiv>
        <div className="success-container blue">
          <p>
            Thank your for choosing to be a part of the Checkpoints Admin crew!
          </p>
          {/* <p>The receipt has been sent to the email address provided.</p> */}
          <p>
            Now go!
            <br />
            And make great events!
          </p>
          <Button
            onClick={() => {
              billingStore.handleClose();
              navigate('/dashboard');
            }}
          >
            <img src={exitIcon} width="30" />
          </Button>
        </div>
      </StyledSuccessDiv>
    </>
  );
};

export const PaymentModal = () => {
  const stripe = useStripe();

  if (!stripe) {
    return null;
  }

  return <CheckoutForm />;
};

const CheckoutForm = observer(function CardSection() {
  const billingStore = useBillingStore();

  const loading = billingStore.loading;
  const error = billingStore.error;
  const success = billingStore.success;

  const store = MainStore.store;
  const me = store.me;

  const elements = useElements();
  const { classes, cx } = usePaymentModalStyles();

  const [cardError, setCardError] = useState<string>(null);
  const [state, setState] = useState<PaymentUser>({
    company: me.company || '',
    name: store.meFullName,
    email: me.email,
    address: me.address || '',
    city: me.city || '',
    zip: me.zip || '',
  });

  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback((e) => {
    const { name, value } = e.target;

    setState((s) => ({
      ...s,
      [name]: value,
    }));
  }, []);

  const [updateUser] = useUpdateUserMutation();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { zip, city, address, company } = state;

    //Save the billing details to user;
    await updateUser({
      variables: {
        body: {
          zip,
          city,
          address,
          company,
        },
      },
    });
    const card = elements.getElement(CardElement);
    await billingStore.createPayment({ user: state, card });

    return false;
  };

  if (success) {
    return (
      <div className={cx(classes.root)}>
        <SuccessScreen />
      </div>
    );
  }

  return (
    <div className={cx(classes.root)}>
      <PaymentModalLeft />

      <div>
        <form className={cx(classes.styledForm)} onSubmit={handleSubmit}>
          <TextField
            label={`Company`}
            name="company"
            value={state.company}
            onChange={handleChange}
          />
          <TextField
            label={`Name`}
            name="name"
            required
            value={state.name}
            onChange={handleChange}
          />
          <TextField
            label={`Address`}
            name="address"
            required
            value={state.address}
            onChange={handleChange}
          />
          <TextField
            label={`Postal code`}
            name="zip"
            required
            value={state.zip}
            onChange={handleChange}
          />
          <TextField
            label={`City`}
            name="city"
            required
            value={state.city}
            onChange={handleChange}
          />
          <CardElement
            id="card_element"
            onChange={({ error }) => {
              if (error) {
                setCardError(error.message);

                return;
              }
              setCardError(null);
            }}
            options={{ style: cardStyle }}
          />
          <WhiteContainedButton type="submit" disabled={!!cardError || loading}>
            {loading ? 'Laddar...' : 'Confirm payment'}
          </WhiteContainedButton>
          <div style={{ marginTop: 10 }}>{error && `Error: ${error}`}</div>
          <PaymentUpdatedListener />
        </form>
      </div>
    </div>
  );
});

const Terms = () => (
  <div className="text-center">
    <small>
      {`By completing this order, I accept `}
      <a
        href="https://checkpoints.se/terms-and-conditions/"
        target="_blank"
        rel="noreferrer"
      >
        The terms and Conditions
      </a>
    </small>
  </div>
);

const usePaymentModalStyles = makeStyles({ name: 'StyledPaymentModal' })(
  () => ({
    root: {
      display: 'flex',
      '> div': {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
      },
      '@media (max-width: 600px)': {
        flexDirection: 'column',
        '> div': {
          width: '100%',
        },
      },
    },
    styledForm: {
      color: 'white',
      padding: '50px 40px',
      background: colors.primary,
      maxWidth: '500px',
      minHeight: '500px',
      margin: '0 auto',
      '> div': {
        marginBottom: '10px',
      },
      '#card_element': {
        marginTop: '10px',
        marginBottom: '20px',
        paddingBottom: '7px',
        borderBottom: '1px solid #a2a2a2',
        transition: 'all 0.1s ease-in-out',
        transitionProperty: 'border-color, border-width',
        '&:hover,\n    &:focus': { borderBottom: '1px solid white' },
        '&.StripeElement--focus': { borderColor: 'white' },
      },
    },
  }),
);

const usePaymentModalLeftStyles = makeStyles({
  name: 'StyledPaymentModalLeft',
})(() => ({
  root: {
    padding: '50px 40px',
    table: {
      td: { padding: '6px 4px' },
      'td:last-of-type': { textAlign: 'right' },
    },
    hr: {
      margin: '10px 0',
      background: 'gray',
      height: '1px',
      width: '100%',
    },
    '.quantity-component': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '1 1 auto',
      margin: '30px 0',
      button: {
        cursor: 'pointer',
        alignSelf: 'stretch',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '60px',
        height: 'auto',
        zIndex: 1,
        backgroundColor: '#ccc',
        border: '0',
      },
      '.large-number': {
        flex: '2 1 auto',
        textAlign: 'center',
        fontSize: '30px',
        fontWeight: 300,
        backgroundColor: '#eee',
        color: 'rgb(102, 102, 102)',
      },
    },
  },
}));

const cardStyle = {
  base: {
    color: 'white',
    fontFamily: 'inherit',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
    iconColor: '#ffffff',
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

const PaymentModalLeft = observer(function PaymentModalLeft() {
  const store = useMainStore();
  const billingStore = useBillingStore();

  const { price, vat, total } = billingStore.currentPlanDescription;
  const { classes, cx } = usePaymentModalLeftStyles();

  return (
    <div className={cx(classes.root)}>
      <div className="text-center">
        <h2 className="modal-title">{`Add Admin days to your account`}</h2>
        <div className="quantity-component">
          <button onClick={billingStore.decrementQuanity}>
            <RemoveIcon />
          </button>
          <div className="large-number">{billingStore.daysAdded} days</div>
          <button onClick={billingStore.incrementQuanity}>
            <AddIcon />
          </button>
        </div>
        <small>
          With this order, your powers will last until{' '}
          {billingStore.newPlanExpires}
        </small>
      </div>
      <hr />
      <table>
        <tbody>
          <tr>
            <td>{billingStore.daysAdded} days admin</td>
            <td>{price}</td>
          </tr>
          <tr>
            <td>VAT (25%)</td>
            <td>{vat}</td>
          </tr>
          <tr>
            <td>
              <strong>Subtotal</strong>
            </td>
            <td>
              <strong>{total}</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="spacer" />
      <Terms />
    </div>
  );
});
