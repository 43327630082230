import React, { useRef, ReactNode, ReactElement } from 'react';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Popper, { PopperPlacementType, PopperProps } from '@mui/material/Popper';
import { Box, ClickAwayListener, Grow } from '@mui/material';

const DEFAULT_POPPER_ARROW_SIZE = 2;

interface Props {
  content: ReactElement;
  children: ReactElement;
  open: boolean;
  onClose?: () => void;
  arrow?: boolean;
  color?: string;
  size?: number;
  placement?: PopperPlacementType;
  modifiers?: PopperProps['modifiers'];
}

const useStyles = makeStyles<{ color: string; size: number }>({
  name: 'SimplePopover',
})((theme, { color, size }) => {
  const popperArrowWidth = `${size}em`;
  // = width / sqrt(2) = (length of the hypotenuse)
  const popperArrowHeight = `${(size / Math.sqrt(2)).toFixed(2)}em`;

  return {
    popoverRoot: {
      backgroundColor: color,
      maxWidth: 500,
      filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.48))',
      borderRadius: '4px',
      boxShadow: '0px 0px 20px #00000026',
    },
    content: {
      padding: '6px 10px',
    },
    // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js and https://github.com/mui-org/material-ui/blob/4f2a07e140c954b478a6670c009c23a59ec3e2d4/docs/src/pages/components/popper/ScrollPlayground.js
    popper: {
      zIndex: 2000,
      '&[data-popper-placement*="bottom"] .arrow': {
        top: 0,
        left: 0,
        marginTop: `-${popperArrowHeight}`,
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '0 100%',
        },
      },
      '&[data-popper-placement*="top"] .arrow': {
        bottom: 0,
        left: 0,
        marginBottom: -popperArrowHeight,
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '100% 0',
        },
      },
      '&[data-popper-placement*="right"] .arrow': {
        left: 0,
        marginLeft: -popperArrowHeight,
        height: popperArrowWidth,
        width: popperArrowHeight,
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '100% 100%',
        },
      },
      '&[data-popper-placement*="left"] .arrow': {
        right: 0,
        marginRight: -popperArrowHeight,
        height: popperArrowWidth,
        width: popperArrowHeight,
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '0 0',
        },
      },
    },
    // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
    arrow: {
      position: 'absolute',
      width: popperArrowWidth,
      height: popperArrowHeight,
      boxSizing: 'border-box',
      color,
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundColor: 'currentColor',
        transform: 'rotate(45deg)',
      },
    },
  };
});

const SimplePopover = ({
  placement = 'top',
  arrow = true,
  open,
  onClose = () => {},
  content,
  children,
  color = 'white',
  size = DEFAULT_POPPER_ARROW_SIZE,
  modifiers,
}: Props) => {
  const { classes, cx } = useStyles({ color, size });
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [childNode, setChildNode] = React.useState<HTMLElement | null>(null);

  return (
    <div>
      {React.cloneElement(children, { ...children.props, ref: setChildNode })}
      <Popper
        open={open}
        anchorEl={childNode}
        placement={placement}
        transition
        className={cx(classes.popper)}
        disablePortal={true}
        modifiers={[
          {
            name: 'flip',
            enabled: false,
            options: {
              altBoundary: true,
              rootBoundary: 'viewport',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: false,
              rootBoundary: 'viewport',
              padding: 8,
            },
          },
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
          ...modifiers,
        ]}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                boxShadow: 'none',
              }}
            >
              <ClickAwayListener onClickAway={onClose}>
                <Paper className={classes.popoverRoot} role="presentation">
                  {arrow ? (
                    <span
                      className={cx(classes.arrow, 'arrow')}
                      ref={setArrowRef}
                    />
                  ) : null}
                  <Box className={classes.content}>{content}</Box>
                </Paper>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default SimplePopover;
