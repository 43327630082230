import {
  RefreshCheckpointOrderIdsDocument,
  RefreshCheckpointOrderIdsMutation,
  RefreshCheckpointOrderIdsMutationVariables,
} from '@checkpoints/shared';

import MainStore from '../Store';

export function refreshCheckpointOrderIds(runsheetId: number) {
  const client = MainStore.store.client;

  return client.mutate<
    RefreshCheckpointOrderIdsMutation,
    RefreshCheckpointOrderIdsMutationVariables
  >({
    mutation: RefreshCheckpointOrderIdsDocument,
    variables: {
      runsheetId,
    },
  });
}
