import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, ButtonProps } from '@mui/material';

import { runsheetColors, styled } from '../../colors';
import SimplePopover from '../popovers/SimplePopover';

export function ColorPickerButton({
  selection = 'gray',
  onSelect,
  disabled = false,
}: {
  selection?: RunsheetColor;
  onSelect: (selection: string) => Promise<void>;
  disabled?: boolean;
}) {
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleSelect = useCallback(
    (color: RunsheetColor) => {
      onSelect(color);
      setOpen(false);
    },
    [onSelect],
  );

  const handleClick = () => {
    setOpen((previousOpen) => !previousOpen);
  };

  useEffect(() => {
    if (!selection) {
      handleSelect('gray');
    }
  });

  const contents = useMemo(() => {
    return (
      <ColorBox>
        {Object.keys(runsheetColors).map((colorName) => {
          return (
            <ColorButton
              key={colorName}
              displayColor={colorName as RunsheetColor}
              onClick={() => handleSelect(colorName as RunsheetColor)}
            />
          );
        })}
      </ColorBox>
    );
  }, [handleSelect]);

  return (
    <PopperContainer>
      <SimplePopover
        open={isOpen}
        onClose={() => handleClick}
        placement="bottom"
        content={contents}
        arrow
        modifiers={[
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: [0, 22],
            },
          },
        ]}
      >
        <ColorButton
          displayColor={selection}
          onClick={handleClick}
          disabled={disabled}
        ></ColorButton>
      </SimplePopover>
    </PopperContainer>
  );
}

export const PopperContainer = styled('div')`
  background: white;
  padding: 4px 8px;
  border-radius: 4px;
  z-index: 9;
`;
export const ColorBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  padding: 5px;
  background: white;
`;

export const ColorButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'displayColor',
  name: 'ColorButton',
})<{ displayColor: RunsheetColor }>(({ displayColor }) => ({
  backgroundColor: runsheetColors[displayColor],
  width: '20px',
  height: '20px',
  minWidth: 'auto',
  border: '1px solid #0000001a',
  borderRadius: '5px',
  padding: '12px',
  boxShadow: 'none',

  '&:hover': {
    backgroundColor: runsheetColors[displayColor],
  },
}));
ColorButton.defaultProps = {
  variant: 'contained',
};
