import React, { useMemo } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
loadStripe.setLoadParameters({ advancedFraudSignals: false });

const STRIPE_PUBLISHABLE_KEY = ''; // import.meta.env.VITE_STRIPE_PUBLIC_KEY as string;

export const StripeProvider = ({ children }) => {
  const stripePromise = useMemo(() => loadStripe(STRIPE_PUBLISHABLE_KEY), []);

  return <Elements stripe={stripePromise}>{children}</Elements>;
};
