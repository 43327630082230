import React, { useEffect, useRef, useState } from 'react';

import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react';
import { useDebounce } from 'use-debounce';

import { User } from '@checkpoints/shared';

import { styled } from '../../colors';

import { DashboardStore } from './DashboardStore';
import { UserSearchRow } from './DashboardRows';

export const UserSearch = observer(function UserSearch({
  isAdmins,
}: {
  isAdmins: boolean;
}) {
  const displayedUsers = DashboardStore.store.displayedSearchUsers;
  let existingUsers: User[], otherUsers: User[];
  if (isAdmins) {
    existingUsers = DashboardStore.store.admins;
    otherUsers = DashboardStore.store.subscribers;
  } else {
    otherUsers = DashboardStore.store.admins;
    existingUsers = DashboardStore.store.subscribers;
  }

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const textFieldRef = useRef<HTMLInputElement>();
  const [debounced] = useDebounce(search, 300);

  useEffect(() => {
    const promise = DashboardStore.store.findUsers(debounced, existingUsers);

    // promise.then(() => {
    //   console.log('Fetched users');
    // });
    return () => promise.cancel && promise.cancel();
  }, [debounced, existingUsers]);

  const onAdded = async (user) => {
    setLoading(true);
    DashboardStore.store
      .addUserToEvent(user)
      .then(() => {
        setSearch('');
        textFieldRef.current.focus();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <UserSearchDiv>
      <TextField
        autoFocus
        value={search}
        inputRef={textFieldRef}
        onChange={(e) => setSearch(e.target.value)}
        label="Search for users"
        placeholder="example@domain.com"
      />
      <DisplayedUsers>
        {displayedUsers.map((user) => {
          let infoText: string;
          const isUserInOtherList = otherUsers.find(
            (otherUser) => otherUser.id === user.id,
          );

          if (isUserInOtherList) {
            infoText = `This user is already added to the ${
              isAdmins ? `Subscribers` : `Admins`
            } list. By clicking the add button, you will transfer the user to the ${
              isAdmins ? `Admin` : `Subscriber`
            } list`;
          }

          return (
            <UserSearchRow
              key={user.email}
              infoText={infoText}
              user={user}
              disabled={loading}
              onSettingsClicked={onAdded}
            />
          );
        })}
      </DisplayedUsers>
    </UserSearchDiv>
  );
});
const DisplayedUsers = styled('div')`
  margin-top: 10px;
  overflow: hidden;
  /* overflow: scroll;
  -webkit-overflow-scrolling: touch; */
`;

const UserSearchDiv = styled('div')`
  display: flex;
  flex-direction: column;
`;
