import { ApolloQueryResult } from '@apollo/client';
import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  ObservableSet,
  runInAction,
  set,
} from 'mobx';
import { computedFn } from 'mobx-utils';

import {
  addTimeComponentsToDate,
  AppNotificationFragment,
  AppUserFragment,
  BasicEventInfoFragment,
  Checkpoint,
  CheckpointInput,
  CheckpointMeta,
  Constants,
  createExtendedCheckpoint,
  createInitialHeaders,
  CustomHeaders,
  ExtendedCheckpoint,
  formatDate,
  getCheckpointRowValue,
  getTimeComponents,
  HeaderSetting,
  idCompareEqual,
  InputType,
  RunsheetDocument,
  RunsheetQuery,
  RunsheetQueryDataFragment,
  TableColumn,
  UpdateTrackedCheckpointDocument,
  UserHeaderOverrides,
  UserNotes,
} from '@checkpoints/shared';
import {
  END_TIME_ACCESSOR,
  START_TIME_ACCESSOR,
} from '@checkpoints/shared/src/constants';

import { downloadCSV } from '../utils/download';
import { wait } from '../utils/promise';

import MainStore from './Store';
const {
  ORDER_INCREMENT,
  ORDER_THRESHOLD,
  TIME_FORMAT,
  TIME_FORMAT_SECONDS,
  USER_NOTES_ACCESSOR,
} = Constants;

export interface RowSettingsActive {
  row: ExtendedCheckpoint;
  anchorEl: any;
}

export interface ColumnSettingsActive {
  column: TableColumn;
  anchorEl: any;
}

export class CheckpointsStore {
  private static _store: CheckpointsStore;
  static get store() {
    if (!this._store) {
      this._store = new CheckpointsStore();
      window['checkpointStore'] = this._store;
    }

    return this._store;
  }

  constructor() {
    makeObservable(
      this,
      {
        tracking: observable,
        editMode: observable,
        multiSelectMode: observable,
        multiSelected: observable,
        toggleEditMode: action,
        toggleMultiSelectMode: action,
        toggleMultiSelection: action,
        toggleTracking: action,
        director: observable,
        setDirector: action,
        userIsDirector: computed,
        showingRunsheetEditModal: observable,
        toggleRunsheetEditModal: action,
        rowSettingsActive: observable,
        activateRowSettings: action,
        clearRowSettings: action,
        columnSettingsActive: observable,
        activateColumnSettings: action,
        resetPopovers: action,
        showSeconds: observable,
        setShowSeconds: action,
        selectedRunsheet: observable,
        fetchSelectedRunsheet: action,
        setSelectedEvent: action,
        trackedRowId: observable,
        setTrackedRowId: action,
        trackedRowIndex: computed,
        activeHeaderWidth: computed,
        setActiveUsers: action,
        displayedAdmins: computed,
        isAdmin: observable,
        selectedRunsheetId: observable,
        selectedEvent: observable,
        runsheetTitle: observable,
        selectedEventId: observable,
        runsheetStartTime: observable,
        userNotes: observable,
        setSelectedRunsheetId: action,
        setAdmin: action,
        setCustomHeaders: action,
        _recalculateHeaders: action,
        _performUserHeaderOverride: action,
        updateUserHeader: action,
        activeHeaders: computed,
        sortedHeaders: computed,
        convertedServerUserHeaders: computed,
        convertedServerHeaders: computed,
        reorderHeaders: action,
        activeCheckpoints: computed,
        setRunsheetStartTime: action,
        setCheckpoints: action,
        setUserNotes: action,
        updateUserNote: action,
        createCheckpoint: action,
        deleteCheckpoint: action,
        updateCheckpointFromSocket: action,
        reorderCheckpoints: action,
        sortedCheckpoints: computed,
        updateTrackedCheckpoint: action,
        setNotifications: action,
        notifications: observable,
        tableWrapperEl: observable,
        setTableWrapperEl: action,
      },
      { autoBind: true },
    );
  }

  //---------- UI STATE ----------
  // @observable loading = false;
  // @action setLoading(loading) {
  //   if (this.loading !== loading) {
  //     this.loading = loading;
  //   }
  // }

  exportCSV() {
    let csv =
      this.activeHeaders
        .slice()
        .map((c) => c.title)
        .join('\t') + '\n';

    csv += this.sortedCheckpoints.slice().reduce((acc, curr, idx) => {
      const checkpointMeta = this.checkpointMeta(idx);
      const row = this.activeHeaders
        .map((header) => {
          let value =
            checkpointMeta[header.accessor] ||
            getCheckpointRowValue(curr, header) ||
            '';
          value = String(value);
          value = value.replace(/\t/gi, ' '); //replace tabs
          value = value.replace(/"/g, '""'); //replace quotes
          // we need to add quotes around the strings else new lines will break the csv
          value = '"' + value + '"';

          return value;
        })
        .join('\t');

      acc += row + '\n';

      return acc;
    }, '');

    downloadCSV(
      csv,
      `${this.runsheetTitle} - ${this.selectedEvent.title}-export`,
    );
  }

  tracking = true;
  editMode = false;
  multiSelectMode = false;
  multiSelected: number[] = [];

  toggleEditMode() {
    if (!this.isAdmin) {
      if (this.editMode) {
        this.editMode = false;
      }

      return;
    }

    // if we're disabling edit mode, also disable multiselect
    if (this.editMode) {
      this.multiSelectMode = false;
      this.multiSelected = [];
    }
    this.editMode = !this.editMode;
    this._recalculateHeaders();
  }

  toggleMultiSelectMode(id?: number) {
    if (!this.isAdmin || !this.editMode) {
      this.multiSelectMode = false;
      this.multiSelected = [];

      return;
    }

    this.multiSelected = id ? [id] : [];
    this.multiSelectMode = !this.multiSelectMode;
    this._recalculateHeaders();
  }

  toggleMultiSelection(id: number) {
    // console.log('toggleMultiSelection', id);
    const idx = this.multiSelected.findIndex((item) => item === id);

    if (idx === -1) {
      this.multiSelected.push(id);
    } else {
      this.multiSelected.splice(idx, 1);
    }

    this.multiSelected.sort((a, b) => {
      const itemA = this.sortedCheckpoints.findIndex((item) => item.id === a);
      const itemB = this.sortedCheckpoints.findIndex((item) => item.id === b);

      return itemA - itemB;
    });
  }

  toggleTracking() {
    this.tracking = !this.tracking;
  }

  director?: AppUserFragment = null;

  setDirector(director: AppUserFragment) {
    if (!idCompareEqual(this.director, director)) {
      this.director = director;
    }

    if (this.userIsDirector) {
      this.tracking = true;
    }
  }

  get userIsDirector() {
    const me = MainStore.store.me;

    if (!me || !this.director) {
      return false;
    }

    return this.director.id === me.id;
  }

  showingRunsheetEditModal = false;

  toggleRunsheetEditModal(value: boolean = undefined) {
    if (value !== undefined) {
      this.showingRunsheetEditModal = value;
    } else {
      this.showingRunsheetEditModal = !this.showingRunsheetEditModal;
    }
  }

  rowSettingsActive: RowSettingsActive = {
    row: null,
    anchorEl: null,
  };

  async activateRowSettings(activeProps: RowSettingsActive) {
    // this.resetPopovers();
    if (Boolean(this.rowSettingsActive.anchorEl)) {
      await this.clearRowSettings();
    }
    this.rowSettingsActive = activeProps;
  }

  async clearRowSettings() {
    this.multiSelectMode = false;
    this.rowSettingsActive.anchorEl = null;
    const row = this.rowSettingsActive.row;
    // prevents visual glitch where "old" popover seems to jump to new anchorEl
    await wait(5);

    if (row === this.rowSettingsActive.row) {
      runInAction(() => {
        this.rowSettingsActive.row = null;
      });
    }

    // this.rowSettingsActive = {
    //   row: null,
    //   anchorEl: null,
    // };
  }

  columnSettingsActive: ColumnSettingsActive = {
    column: null,
    anchorEl: null,
  };

  activateColumnSettings(activeProps: ColumnSettingsActive) {
    // this.resetPopovers();
    this.columnSettingsActive = activeProps;
  }

  //Hide all popovers
  async resetPopovers() {
    this.clearRowSettings();
    this.columnSettingsActive.anchorEl = null;
    const column = this.columnSettingsActive.column;
    await new Promise((res) => setTimeout(res, 300));

    if (column === this.columnSettingsActive.column) {
      runInAction(() => {
        this.columnSettingsActive.column = null;
      });
    }
    // this.columnSettingsActive = {
    //   column: null,
    //   anchorEl: null,
    // };
  }

  showSeconds = false;

  setShowSeconds(showSeconds: boolean) {
    if (this.showSeconds !== showSeconds) {
      const multiplier = this.showSeconds && !showSeconds ? -1 : 1;

      this.headers
        .filter(
          (h) =>
            h.accessor === START_TIME_ACCESSOR ||
            h.accessor === END_TIME_ACCESSOR,
        )
        .forEach((header) => {
          if (multiplier === -1) {
            if (header.width >= 100) {
              header.width += 28 * multiplier;
            }
          } else {
            if (header.width < 100) {
              header.width += 28 * multiplier;
            }
          }
        });
    }

    this.showSeconds = showSeconds;
  }

  selectedRunsheet: RunsheetQueryDataFragment;

  async fetchSelectedRunsheet() {
    const client = MainStore.store.client;

    await client
      .query<RunsheetQuery>({
        query: RunsheetDocument,
        variables: {
          id: this.selectedRunsheetId,
        },
      })
      .then((result) => {
        this.selectedRunsheet = result.data.runsheet;
      });
  }

  fetchExtendedRunsheetData = flow(function* fetchData(this: CheckpointsStore) {
    const client = MainStore.store.client;

    const result: ApolloQueryResult<RunsheetQuery> = yield client.query({
      query: RunsheetDocument,
      variables: {
        id: this.selectedRunsheetId,
      },
    });

    this.selectedRunsheet = result.data.runsheet;

    console.log(
      'tracked runsheet id',
      this.selectedRunsheet.trackedCheckpointId,
    );
  });

  setSelectedEvent(event: BasicEventInfoFragment) {
    this.selectedEvent = event;
    this.selectedEventId = event.id;
  }

  trackedRowId = -1;

  setTrackedRowId(trackedRowId: number) {
    console.log('Set tracked row id', trackedRowId);
    if (this.trackedRowId !== trackedRowId) {
      this.trackedRowId = trackedRowId;
    }
  }

  get trackedRowIndex() {
    return this.sortedCheckpoints.findIndex((c) => c.id === this.trackedRowId);
  }

  //Not used
  // isRowBeforeTracked = computedFn(function (id: number) {
  //   if (this.trackedRowId < 0) {
  //     return false;
  //   }

  //   const myIndex = this.sortedCheckpoints.findIndex((c) => c.id === id);
  //   const trackedIndex = this.sortedCheckpoints.findIndex(
  //     (c) => c.id === this.trackedRowId,
  //   );

  //   return myIndex < trackedIndex;
  // });

  get activeHeaderWidth() {
    return this.activeHeaders.reduce(
      (acc, curr) => acc + (curr.width || 200),
      0,
    );
  }

  onlineUsersForRunsheet = observable.set<number>([]);

  setActiveUsers(users: ObservableSet<number>) {
    if (this.onlineUsersForRunsheet !== users) {
      this.onlineUsersForRunsheet.replace(users);
    }
  }

  get displayedAdmins(): AppUserFragment[] {
    const director = this.director;

    if (!this.selectedEvent || !director) {
      return [];
    }

    return this.selectedEvent.admins.filter((a) => a.id !== director.id);
  }

  isAdmin: boolean;
  selectedRunsheetId: number;
  selectedEvent: BasicEventInfoFragment;
  runsheetTitle: string;
  selectedEventId: number;
  runsheetStartTime: string;

  readonly headers = observable.array<TableColumn>([]);

  //Caches these in memory for equality checks;
  customHeaders: CustomHeaders = {};
  userHeaderOverrides: UserHeaderOverrides = {};

  userNotes: UserNotes = {};

  setSelectedRunsheetId(selectedRunsheetId: number) {
    if (this.selectedRunsheetId !== selectedRunsheetId) {
      //We should clear the stored checkpoints to avoid a flash of old content
      this.checkpoints.replace([]);
      //Tracking on by default
      this.tracking = true;
      this.editMode = false;
      this.multiSelectMode = false;
    }

    // We still want to reload the data even if the same runsheet was selected
    this.selectedRunsheetId = selectedRunsheetId;
    // Remove any open popovers
    this.rowSettingsActive.anchorEl = null;

    this.fetchSelectedRunsheet();
  }

  setAdmin(isAdmin: boolean) {
    if (this.isAdmin !== isAdmin) {
      this.isAdmin = isAdmin;
    }
  }

  setCustomHeaders({
    customHeaders,
    userHeaderOverrides,
  }: {
    customHeaders?: CustomHeaders;
    userHeaderOverrides?: string;
  }) {
    if (userHeaderOverrides) {
      if (typeof userHeaderOverrides === 'object') {
        this.userHeaderOverrides = userHeaderOverrides;
      } else {
        this.userHeaderOverrides = JSON.parse(userHeaderOverrides);
      }
    }

    if (customHeaders) {
      if (typeof customHeaders === 'object') {
        this.customHeaders = customHeaders;
      } else {
        this.customHeaders = JSON.parse(customHeaders as unknown as string);
      }
    }

    console.log('this.customHeaders', this.customHeaders);
    console.log('this.userHeaderOverrides', this.userHeaderOverrides);

    this._recalculateHeaders();
  }

  _recalculateHeaders() {
    const headers = createInitialHeaders(this.showSeconds);

    if (this.customHeaders) {
      Object.keys(this.customHeaders).forEach((id) => {
        const customHeaderSettings = this.customHeaders[id];
        const existingHeader = headers.find((h) => h.accessor === id);

        if (existingHeader) {
          Object.keys(customHeaderSettings).forEach((customKey) => {
            existingHeader[customKey] = customHeaderSettings[customKey];
          });

          // existingHeader.overrideWithUserSettings(customHeader);
          return;
        }

        const headerObj = {
          title: customHeaderSettings.title,
          isCustomHeader: true,
          accessor: id,
          order: customHeaderSettings.order,
          inputType: InputType.textarea,
          active: true,
        };

        headers.push(headerObj);
      });
    }

    this._performUserHeaderOverride(headers);
    this.headers.replace(headers);
    //Give each header an order
    this.reorderHeaders();
  }

  _performUserHeaderOverride(headers: TableColumn[]) {
    if (this.userHeaderOverrides) {
      headers.forEach((header) => {
        const overrides = this.userHeaderOverrides[header.accessor];

        if (overrides) {
          //In edit mode, we only override the header width
          if (this.editMode) {
            if (overrides.width !== undefined) {
              header.width = overrides.width;
            }
          } else {
            if (overrides.title !== undefined) {
              header.title = overrides.title;
            }
            if (overrides.width !== undefined) {
              header.width = overrides.width;
            }
            if (overrides.active !== undefined) {
              header.active = overrides.active;
            }
            if (overrides.order !== undefined) {
              header.order = overrides.order;
            }
          }
        }
      });
    }
  }

  updateUserHeader(column: TableColumn, updates: HeaderSetting) {
    const header = this.headers.find((h) => h.accessor === column.accessor);

    Object.keys(updates).forEach((customKey) => {
      header[customKey] = updates[customKey];
    });
  }

  get activeHeaders() {
    const editMode = this.editMode;
    // console.log('get activeHeaders, headers', this.headers);

    return this.headers
      .filter((h) => {
        if (editMode && h.accessor === USER_NOTES_ACCESSOR) {
          return true;
        }

        return h.active === true;
      })
      .slice()
      .sort((a, b) => a.order - b.order);
  }

  get sortedHeaders() {
    console.log('sortedHeaders called');

    return this.headers.slice().sort((a, b) => {
      return a.order - b.order;
    });
  }

  //Used to save header customizations
  get convertedServerUserHeaders(): UserHeaderOverrides {
    return this.headers.reduce((acc, curr) => {
      acc[curr.accessor] = {
        width: curr.width,
        active: curr.active,
        order: curr.order,
      };

      return acc;
    }, {});
  }

  get convertedServerHeaders(): CustomHeaders {
    return this.headers.reduce((acc, curr) => {
      acc[curr.accessor] = {
        title: curr.title, //title is needed here when admin makes changes to title
        width: curr.width,
        active: curr.active,
        order: curr.order,
      };

      return acc;
    }, {});
  }

  reorderHeaders(from?: number, to?: number) {
    //TODO: check that we cant breach length
    const columns = this.sortedHeaders;

    if (from !== undefined && to !== undefined) {
      const [removed] = columns.splice(from, 1);
      columns.splice(to, 0, removed);
    }

    for (let i = 0; i < columns.length; i++) {
      const col = columns[i];
      col.order = i;
    }
  }

  readonly checkpoints = observable.array<ExtendedCheckpoint>([]);

  get activeCheckpoints() {
    // return this.checkpoints;

    if (this.editMode) {
      return this.checkpoints;
    }

    return this.checkpoints.filter((c) => c.active === true);
  }

  setRunsheetStartTime(runsheetStartTime: string) {
    if (this.runsheetStartTime !== runsheetStartTime) {
      this.runsheetStartTime = runsheetStartTime;
    }
  }

  setCheckpoints(checkpoints: Checkpoint[]) {
    if (this.checkpoints !== checkpoints) {
      //  as IObservableArray<ExtendedCheckpoint>;
      this.checkpoints.replace(checkpoints.map(createExtendedCheckpoint));
    }
  }

  setUserNotes(notes: UserNotes) {
    this.userNotes = notes;
  }

  updateUserNote(checkpointId: number, newValue: string) {
    this.userNotes[checkpointId] = newValue;
    // const checkpoint = this.checkpoints.find(c => c.id === checkpointId);
    // if (checkpoint) {
    //   checkpoint.userNote = newValue;
    // }
  }

  createCheckpoint(checkpoint: Checkpoint) {
    const c = this.checkpoints.find((c) => c.id === checkpoint.id);

    if (!c) {
      this.checkpoints.push(createExtendedCheckpoint(checkpoint));
    }
  }

  deleteCheckpoint(checkpoint: Checkpoint) {
    const c = this.checkpoints.find((c) => c.id === checkpoint.id);

    if (c) {
      this.checkpoints.remove(c);
    }
  }
  updateCheckpointFromSocket(checkpoint: Checkpoint) {
    const exitingCheckpoint = this.checkpoints.find(
      (c) => c.id === checkpoint.id,
    );

    if (!exitingCheckpoint) {
      return;
    }

    if (JSON.stringify(exitingCheckpoint) === JSON.stringify(checkpoint)) {
      return;
    }

    //If the order has changed, we delete the row and add it after a delay;
    if (exitingCheckpoint.order !== checkpoint.order) {
      // c.changedOrder = true;
      this.checkpoints.remove(exitingCheckpoint);

      setTimeout(() => {
        runInAction(() => {
          this.checkpoints.push(createExtendedCheckpoint(checkpoint));
        });
      }, 400);

      return;
    }

    set(exitingCheckpoint, checkpoint);
    exitingCheckpoint.modifiedRow = true;
  }

  // removingList = []
  // removeAfterDuration(checkpoint) {
  //   const existingIndex = this.removingList.indexOf(checkpoint);
  //   if (existingIndex !== -1) {
  //     this.removingList.slice(existingIndex, 1);
  //   }
  //   this.removingList.push({checkpoint, timer});
  // }
  // timeoutRef: NodeJS.Timeout

  // @action recalculateStartAndEndTimes() {
  //   this.checkpoints = this.updateTimesOnCheckpoints() as any;
  // }

  /**
   * createDuplicateCheckpoint
   * @param checkpoint
   *
   * Creates a duplicate checkpoint from a previous checkpoint.
   * Handles finding a order number that is betweeen 2 order numbers.
   */
  createDuplicateCheckpoint(
    checkpoint: ExtendedCheckpoint,
    below = true,
    id?: number,
    index?: number,
  ): { checkpoint: CheckpointInput; shouldReload: boolean } {

    let shouldReload = false;
    const checkpoints = this.sortedCheckpoints;
    let newOrderNumber = 0;
    const indexToSearchFor = id ?? checkpoint.id;

    let currentIndex = checkpoints.findIndex(
      (c) => c.id === indexToSearchFor,
    );

      if (index != undefined) {
        currentIndex = index;
      }

    let currentOrder = checkpoints[currentIndex].order;

    if (below) {
      //create row below:
      const nextIndex = currentIndex + 1;

      if (nextIndex < checkpoints.length) {
        const afterOrder = checkpoints[nextIndex].order;
        const between = Math.floor((afterOrder + currentOrder) * 0.5);

        if (Math.abs(between - currentOrder) < ORDER_THRESHOLD) {
          shouldReload = true;
        }

        newOrderNumber = between;
      } else {
        newOrderNumber = currentOrder + ORDER_INCREMENT;
      }

    } else {
      //create row above:
      const prevIndex = currentIndex - 1;
      let prevOrder = 0;

      if (prevIndex >= 0) {
        prevOrder = checkpoints[prevIndex].order;
      }

      const between = Math.floor((prevOrder + currentOrder) * 0.5);

      if (Math.abs(between - currentOrder) < ORDER_THRESHOLD) {
        shouldReload = true;
      }

      newOrderNumber = between;
    }

    const duplicate = {
      title: checkpoint ? checkpoint.title : '',
      duration: checkpoint ? checkpoint.duration : '00:30:00',
      headers: checkpoint ? checkpoint.headers : {},
      active: checkpoint ? checkpoint.active : true,
      color: checkpoint ? checkpoint.color : 'gray',
      runsheetId: this.selectedRunsheetId,
      order: newOrderNumber,
    };

    return { checkpoint: duplicate, shouldReload };
  }

  reorderCheckpoints(from: number, to: number) {
    if (from === to) {
      return;
    }

    const checkpoints = this.sortedCheckpoints;

    //Limits;
    if (to < 0) {
      to = 0;
    }
    if (to > checkpoints.length - 1) {
      to = checkpoints.length - 1;
    }

    console.log(`Moving from ${from} to ${to}`);

    let shouldReload = false;

    const movedUp = to < from;
    //The existing index.
    const beforeIndex = movedUp ? to - 1 : to;
    const beforeOrder = beforeIndex >= 0 ? checkpoints[beforeIndex].order : 0;
    const nextIndex = movedUp ? to : to + 1;
    let afterOrder: number;

    let newOrderNumber = 0;

    if (nextIndex < checkpoints.length) {
      const nextCheckpoint = checkpoints[nextIndex];
      afterOrder = nextCheckpoint.order;

      const between = Math.floor((afterOrder + beforeOrder) * 0.5);

      if (between - beforeOrder < ORDER_THRESHOLD) {
        shouldReload = true;
      }

      newOrderNumber = between;
    } else {
      //if last, add ORDER_INCREMENT to the previous order number;
      newOrderNumber =
        checkpoints[checkpoints.length - 1].order + ORDER_INCREMENT;
    }

    const affectedCheckpoint = checkpoints[from];
    affectedCheckpoint.order = newOrderNumber;

    return { checkpoint: affectedCheckpoint, shouldReload };
  }

  get sortedCheckpoints() {
    return this.activeCheckpoints.slice().sort((a, b) => a.order - b.order);
  }

  checkpointMeta: (idx: number) => CheckpointMeta = computedFn(
    function checkpointMeta(this: CheckpointsStore, idx: number) {
      // const idx = this.sortedCheckpoints.findIndex(c => c.id === id);
      const checkpoint = this.sortedCheckpoints[idx];

      if (!checkpoint) {
        return null;
      }

      const meta: CheckpointMeta = { duration: checkpoint.duration };

      if (idx !== -1) {
        //duration
        const timeFormat = TIME_FORMAT_SECONDS;

        // if (!this.showSeconds) {
        //   //remove seconds;
        //   meta.duration = checkpoint.duration.slice(
        //     0,
        //     checkpoint.duration.length - 3,
        //   );
        // }
        //Start
        const previousIdx = idx - 1;
        // const previous = this.sortedCheckpoints[previousIdx];

        if (previousIdx >= 0) {
          const previousMeta = this.checkpointMeta(previousIdx);
          meta.start = previousMeta.end;
          meta.startDate = previousMeta.endDate;
          // meta.start = previous.end;
        } else {
          //TODO: Parse date in datefns to guarantee support in all browsers.
          const startDate = new Date(`01/01/2020 ${this.runsheetStartTime}`);
          meta.start = formatDate(startDate, timeFormat);
          meta.startDate = startDate;
        }

        //End
        if (checkpoint) {
          const durationComponents = getTimeComponents(meta.duration);

          const endDate = addTimeComponentsToDate(
            meta.startDate,
            durationComponents,
          );
          meta.endDate = endDate;
          meta.end = formatDate(endDate, timeFormat);

          //Notes
          if (this.userNotes) {
            if (typeof this.userNotes === 'string') {
              this.userNotes = JSON.parse(this.userNotes);
            }
            meta.userNote = this.userNotes[checkpoint.id];
          }
        }
      }

      return meta;
    },
  );

  /** Only allows directors to track checkpoints */
  updateTrackedCheckpoint({ checkpointId }: { checkpointId: number }) {
    if (!this.userIsDirector) {
      return;
    }

    const client = MainStore.store.client;

    client.mutate({
      mutation: UpdateTrackedCheckpointDocument,
      variables: {
        checkpointId,
        runsheetId: this.selectedRunsheetId,
      },
    });
  }

  notifications = [] as AppNotificationFragment[];
  setNotifications(notifications: AppNotificationFragment[]) {
    this.notifications = notifications;
  }

  tableWrapperEl?: HTMLDivElement;

  setTableWrapperEl(el: HTMLDivElement) {
    this.tableWrapperEl = el;
  }
}
