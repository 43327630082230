import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import {
  ExtendedCheckpoint,
  InputType,
  TableColumn,
} from '@checkpoints/shared';

import { CheckpointsStore } from '../../stores/checkpointsStore';
import { mutateCheckpoint } from '../../stores/helpers';
import MainStore from '../../stores/Store';
import { AutoresizingInputField, TimeField } from '../textfield';

import { SimpleTableTextInput, StyledCell } from './StyledTableCells';

export const ActiveCell = observer(function ActiveCell({
  header,
  cellValue,
  handleUnSelect,
  row,
  className,
}: {
  header: TableColumn;
  cellValue: string;
  handleUnSelect: () => void;
  row: ExtendedCheckpoint;
  className: string;
}) {
  const [current, setValue] = useState(cellValue);
  const showSeconds = CheckpointsStore.store.showSeconds;
  const store = MainStore.store;

  const handleBlur: React.FocusEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      console.log('blur', e.target.value);

      const newValue = e.target.value;

      if (cellValue === newValue) {
        handleUnSelect();

        return;
      }

      console.log(
        `new value: ${newValue} id: ${row.id}, header: ${header.accessor} `,
      );
      mutateCheckpoint({
        checkpointId: row.id,
        client: store.client,
        column: header,
        newValue,
      }).finally(() => {
        handleUnSelect();
      });
    },
    [cellValue, handleUnSelect, header, row.id, store.client],
  );

  const handleBlurTime: React.FocusEventHandler<HTMLTextAreaElement> =
    useCallback(
      (e) => {
        const newValue = e.target.value;
        const split = newValue.split(':');

        const hours = parseInt(split[0]);
        const minutes = parseInt(split[1]);

        if (isNaN(hours) || isNaN(minutes)) {
          handleUnSelect();

          return;
        }

        handleBlur(e);
      },
      [handleBlur, handleUnSelect],
    );

  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> =
    useCallback((e) => {
      console.log('handleKeydown', e.key);

      if (e.key === 'Escape' || (!e.shiftKey && e.key === 'Enter')) {
        (e.target as HTMLInputElement).blur();
      }
    }, []);

  useEffect(() => {
    setValue(cellValue);
  }, [cellValue]);

  if (header.inputType === InputType.time) {
    return (
      <StyledCell
        className={className}
        active
        key={header.accessor}
        style={{
          width: header.width || 200,
          flex: `0 0 auto`,
        }}
      >
        <div className="flex" style={{ padding: '12px 12px 11px 12px' }}>
          <TimeField
            autoFocus
            value={current}
            showSeconds={showSeconds}
            onChange={(time) => {
              setValue(time);
            }}
            onBlur={handleBlurTime}
            props={{
              slotProps: {
                textField: {
                  className: 'cell__input',
                  onKeyUp: (e) => {
                    // console.log('keyup', e.key);
                    if (e.key === 'Enter') {
                      handleBlurTime(
                        e as unknown as React.FocusEvent<
                          HTMLTextAreaElement,
                          Element
                        >,
                      );
                    }
                  },
                },
              },
            }}
          />
        </div>
      </StyledCell>
    );
  }

  return (
    <StyledCell
      className={className}
      active
      style={{
        width: header.width || 200,
        flex: `0 0 auto`,
      }}
    >
      <div className="flex" style={{ padding: '12px 12px 11px 12px' }}>
        <AutoresizingInputField
          {...{ handleKeyDown, handleBlur, value: cellValue }}
        />
      </div>
    </StyledCell>
  );
});
