import React, { useCallback, useState } from 'react';

import { observer } from 'mobx-react';
import { Box, IconButton } from '@mui/material';
import { Notifications } from '@mui/icons-material';
import {
  animate,
  AnimatePresence,
  LayoutGroup,
  motion,
  Transition,
  useAnimationControls,
  useMotionTemplate,
  useMotionValue,
} from 'framer-motion';

import MainStore from '../../stores/Store';
import colors, { mediaTablet, styled } from '../../colors';
import { CheckpointsStore } from '../../stores/checkpointsStore';
import { useReorderUserHeader } from '../../stores/helpers';

import { ColumnList } from './ColumnList';
import { NotificationsOverlay } from './NotificationsOverlay';

export const OVERLAY_WIDTH = 320;
export const PILL_WIDTH = 50;

const columnAnimationStates = {
  hidden: { right: 2 },
  show: {
    right: -(OVERLAY_WIDTH - PILL_WIDTH) + 1,
  },
  transition: {
    duration: 0.3,
    ease: [0.445, 0.05, 0.55, 0.95],
  } as Transition,
};

export const UserOverlay = observer(function UserOverlay() {
  const [showColumn, setShowColumn] = useState<boolean>(false);

  const rightPos = useMotionValue(columnAnimationStates.hidden.right);
  const transform = useMotionTemplate`translate3d(${rightPos}px, 0px, 0px)`;
  const isPro = CheckpointsStore.store.selectedRunsheet?.event?.plan === 'PRO';

  const handleToggleColumn = useCallback(() => {
    console.log('in handleToggleColumn');

    setShowColumn((current) => {
      if (!current) {
        console.log('setting to');
        animate(rightPos, columnAnimationStates.show.right);

        return true;
      } else {
        console.log('hiding');
        animate(rightPos, columnAnimationStates.hidden.right);

        return false;
      }
    });
  }, [rightPos]);

  const editMode = CheckpointsStore.store.editMode;

  if (editMode) {
    return null;
  }

  const initials = MainStore.store.meInitials;
  const fullName = MainStore.store.meFullName;

  return (
    <LayoutGroup>
      <ColumnsOverlay
        style={{ transform }}
        transition={columnAnimationStates.transition}
      >
        <ButtonBox>
          <UserPill onClick={() => handleToggleColumn()}>{initials}</UserPill>
        </ButtonBox>
        <ColumnsInner>
          <UserNameDisplayer>{fullName}</UserNameDisplayer>
          <ColumnListHeader>{'Display these columns:'}</ColumnListHeader>
          <ColumnList />
        </ColumnsInner>
      </ColumnsOverlay>
      {isPro && <NotificationsOverlay />}
    </LayoutGroup>
  );
});

export const ButtonBox = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Overlay = styled(motion.div)`
  display: flex;
  position: fixed;
  color: #ddd;
  pointer-events: none;
`;

const ColumnsOverlay = styled(Overlay)`
  width: ${OVERLAY_WIDTH}px;
  right: -${OVERLAY_WIDTH - PILL_WIDTH + 1}px;
  top: 60px;
  height: 80%;
  z-index: 21;

  ${mediaTablet} {
    top: 30px;
  }
`;

export const UserPill = styled('button')`
  pointer-events: auto;
  cursor: pointer;

  border: 1px solid ${colors.primary};
  border-right-width: 0;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  position: relative;
  margin-right: -1px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  height: ${PILL_WIDTH}px;
  width: ${PILL_WIDTH}px;
  flex-shrink: 0;

  font-weight: 700;
  font-size: 1.5rem;

  /* override button css */
  color: inherit;
  background-color: ${colors.backgroundFirst};
  font-family: inherit;

  &:focus {
    outline: 0;
    /* border-color: white; */
    color: white;
  }
`;

export const InnerOverlay = styled(motion.div)`
  pointer-events: auto;

  display: flex;
  flex-direction: column;

  padding: 12px 20px;

  border: 1px solid ${colors.primary};
  transition: background 0.6s;
  border-radius: 4px;
  height: 100%;
  flex: 1 1 auto;
`;

const ColumnsInner = styled(InnerOverlay)`
  background: ${colors.backgroundFirst};
  background: linear-gradient(
    90deg,
    ${colors.backgroundFirst},
    ${colors.backgroundSecond}
  );
`;

const UserNameDisplayer = styled('div')`
  /* padding: 20px 0; */
  margin-bottom: 32px;
  margin-top: 6px;
  font-size: 1rem;
`;

const ColumnListHeader = styled('div')`
  text-decoration: underline;
  text-decoration-color: ${colors.primary};
  font-size: 0.8rem;
  font-weight: 700;
  margin-bottom: 10px;
`;
