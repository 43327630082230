import React from 'react';

import {
  FormControl,
  Input,
  InputLabel,
  InputProps,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface ExtendedTextFieldProps extends InputProps {
  label: string;
  characterCountValue: number;
  preventEnter?: boolean;
}

export const TextFieldWithCharacterCount = ({
  characterCountValue,
  preventEnter = false,
  ...props
}: ExtendedTextFieldProps) => {
  const { classes, cx } = useStyles({
    length: (props.value as string)?.length,
    maxLength: characterCountValue,
  });

  return (
    <FormControl>
      <InputLabel
        htmlFor="textfield-charactercounter"
        className={cx(classes.label)}
        shrink
      >
        <Typography component="span" className={cx(classes.title)}>
          {props.label}
        </Typography>
        <Typography component="span" className={cx(classes.counter)}>
          {(props.value ? `${(props.value as string)?.length}` : '0') +
            ' / ' +
            characterCountValue}
        </Typography>
      </InputLabel>
      <Input
        onKeyDown={(e) => {
          e.key === 'Enter' && preventEnter === true && e.preventDefault();
        }}
        id="textfield-charactercounter"
        {...props}
        sx={{
          minWidth: '250px',
          ['& legend']: { width: '100%' },
          ['&::before']: {
            borderBottomColor: 'rgba(255, 255, 255, 0.5)',
            ['&:hover']: {
              borderBottomColor: '#ffffff',
            },
          },
          ...props.sx,
        }}
        inputProps={{ maxLength: characterCountValue, ...props.inputProps }}
      />
    </FormControl>
  );
};

const useStyles = makeStyles<
  { length?: number; maxLength: number },
  'label' | 'title' | 'counter'
>({
  name: 'TextFieldWithCharacterCount',
})((theme, { length, maxLength }, classes) => ({
  label: {
    display: 'flex',
    width: 'calc(133%)',
    justifyContent: 'space-between',
    [`&.Mui-focused .${classes.title}`]: {
      opacity: 1,
    },
    [`&.Mui-focused .${classes.counter}`]: {
      opacity: 1,
    },
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '16px',
    opacity: 0.8,
    // fontWeight: 600,
    // color: theme.palette.colors.dimText,
  },
  counter: {
    fontSize: '16px',
    opacity: 0.8,
    // color:
    //   length === maxLength
    //     ? theme.palette.warning.main
    //     : theme.palette.text.primary,
    userSelect: 'none',
    paddingLeft: '2px',
    // transform: 'translate(0, -1.5px) scale(0.75)',
    // lineHeight: '1.4375em',
  },
}));
