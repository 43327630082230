import React, { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'query-string';

import { useVerifyUserMutation } from '@checkpoints/shared';

import { InnerContainer, OuterContainer } from '../../components/layouts';

export function Verify() {
  const location = useLocation();
  const navigate = useNavigate();

  const { v } = parse(location.search);
  const [verifyUser] = useVerifyUserMutation();

  useEffect(() => {
    if (v) {
      verifyUser({
        variables: {
          token: v as string,
        },
      })
        .then(() => {
          navigate('/welcome');
        })
        .catch(() => {
          navigate('/login');
        });
    }
  }, [navigate, v, verifyUser]);

  return (
    <OuterContainer>
      <InnerContainer></InnerContainer>
    </OuterContainer>
  );
}
