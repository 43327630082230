import React, { useEffect, useRef, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';

import { TableColumn } from '@checkpoints/shared';

import { CheckpointsStore } from '../../stores/checkpointsStore';
import { useReorderUserHeader, useUpdateHeader } from '../../stores/helpers';
import { ModalStore } from '../../stores/ModalStore';
import { useEscapeKey } from '../../utils/useWindowListener';
import { ChevronLeft, ChevronRight, DeleteIcon } from '../common';

import { useStyles } from './popoverStyles';
import { InnerPopover, PaddedDiv, GrayDivider } from './PopoverUtils';
import { SmallIconButton } from './SmallIconButton';

export const ColumnSettingsPopover = observer(function ColumnSettingsPopover() {
  const { anchorEl, column } = CheckpointsStore.store.columnSettingsActive;
  const open = Boolean(anchorEl);
  const id = open ? 'column-popper' : undefined;
  const arrowRef = useRef(null);
  const { classes } = useStyles();

  // const previousAnchorEl = usePrevious(anchorEl);
  const handleClose = () => {
    CheckpointsStore.store.resetPopovers();
  };
  useEscapeKey(handleClose);
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);

  if (!column) {
    return null;
  }

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      // transition
      className={classes.popper}
      placement={'bottom-start'}
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: false,
        },
        {
          name: 'flip',
          enabled: false,
        },
        {
          name: 'hide',
          enabled: false,
        },
        {
          name: 'arrow',
          enabled: false,
          options: {
            element: arrowRef,
          },
        },
      ]}
    >
      <Paper>
        <span className={classes.arrow} ref={arrowRef} />

        <InnerColumnPopover column={column} handleClose={handleClose} />
      </Paper>
    </Popper>
  );
});
const InnerColumnPopover = observer(function InnerColumnPopover({
  column,
  handleClose,
}: {
  column: TableColumn;
  handleClose: () => void;
}) {
  if (!column) {
    column = { title: '' } as any;
  }
  const [title, setTitle] = useState(column.title);
  // const [confirming, setConfirming] = useState(false);
  const { updateHeader, deleteHeader, setHeaders } = useUpdateHeader(column);
  const reorderUserHeader = useReorderUserHeader();

  useEffect(() => {
    setTitle(column.title);
  }, [column]);

  const handleBlur = () => {
    // do nothing
  };

  const onDelete = async () => {
    const confirmed = await ModalStore.store.confirm(
      `Are you sure you want to delete this column?`,
    );
    if (!confirmed) return;
    await deleteHeader();
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      updateHeader(title).then(() => {
        handleClose();
      });
    }
    if (e.keyCode === 27) {
      (e.target as HTMLInputElement).blur();
    }
  };

  const reattach = (accessor: string) => {
    handleClose();
    setTimeout(() => {
      const anchorEl = document.querySelector(`#header_edit_${accessor}`);

      runInAction(() => {
        const c = CheckpointsStore.store.headers.find(
          (h) => h.accessor === accessor,
        );

        CheckpointsStore.store.activateColumnSettings({
          anchorEl,
          column: c,
        });
      });
    }, 100);
  };

  const moveLeft = async () => {
    //First reorder user header, then set the actual runhseet header;
    await reorderUserHeader(column.order, column.order - 1);
    const serverHeaders = CheckpointsStore.store.convertedServerHeaders;
    await setHeaders(serverHeaders);
    reattach(column.accessor);
  };

  const moveRight = async () => {
    await reorderUserHeader(column.order, column.order + 1);
    const serverHeaders = CheckpointsStore.store.convertedServerHeaders;
    await setHeaders(serverHeaders);
    reattach(column.accessor);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <InnerPopover noPadding>
        <SmallIconButton reallySmall onClick={moveLeft}>
          <ChevronLeft />
        </SmallIconButton>
        <PaddedDiv>
          <GrayDivider />
        </PaddedDiv>
        <PaddedDiv>
          <TextField
            value={title}
            key={column.accessor}
            className="dark"
            label={'Column title'}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            autoFocus
          />
        </PaddedDiv>
        <GrayDivider />
        <SmallIconButton onClick={onDelete}>
          <DeleteIcon color={'error'} />
        </SmallIconButton>
        <GrayDivider />
        <SmallIconButton reallySmall onClick={moveRight}>
          <ChevronRight />
        </SmallIconButton>
      </InnerPopover>
    </ClickAwayListener>
  );
});
