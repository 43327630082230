import * as React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react';

import { ModalStore } from '../../stores/ModalStore';

export const PromptDialog = observer(function PromptDialog() {
  return (
    <CustomPromptDialog
      message={ModalStore.store.promptMessage}
      open={!!ModalStore.store.promptMessage}
      onSubmit={(result: string) => ModalStore.store.submitPrompt(result)}
      onCancel={() => ModalStore.store.submitPrompt(null)}
    />
  );
});

export function CustomPromptDialog({
  onCancel,
  message,
  open,
  onSubmit,
}: {
  onCancel: () => void;
  message: { header: string; message: string };
  open: boolean;
  onSubmit: (value: string) => void;
}) {
  const previousMessage = React.useRef<any>('');

  React.useEffect(() => {
    if (message) {
      previousMessage.current = message;
    }
  }, [message]);
  const [result, setResult] = React.useState('');

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={onCancel}
      aria-labelledby="prompt"
    >
      <DialogTitle id="prompt">
        {(message && message.header) ||
          (previousMessage.current && previousMessage.current.header)}
      </DialogTitle>
      <div
        style={{
          padding: '0px 24px',
        }}
      >
        <div style={{ marginBottom: 10 }}>
          {(message && message.message) ||
            (previousMessage.current && previousMessage.current.message)}
        </div>
        <TextField
          className="dark"
          autoFocus
          placeholder="Email"
          value={result}
          onChange={(e) => {
            setResult(e.target.value);
          }}
        />
      </div>
      <DialogActions>
        <Button onClick={onCancel} color="secondary" type="button">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSubmit(result);
          }}
          color="primary"
          type="submit"
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
