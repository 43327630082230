import React, { useRef, useEffect } from 'react';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { styled } from '../../colors';
import { ErrorIcon, CloseIcon } from '../common';
import MainStore, { Notification } from '../../stores/Store';

const useStyles = makeStyles()((theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },

  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },

  error: {
    backgroundColor: theme.palette.error.dark,
  },

  success: {
    backgroundColor: theme.palette.primary.main,
  },

  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const autoHideDuration = 5 * 1000;

const defaults: Notification = {
  message: undefined,
  options: {
    variant: 'error',
    buttonTitle: '',
    handlePrimaryAction: null,
    timeout: autoHideDuration,
  },
};

export const Notifications = observer(function Notifications() {
  const { classes } = useStyles();

  const props = MainStore.store.notificationProps;
  const showingNotification = !!props;

  const updatedNotificationProps = useNotificationProps(props);
  const { variant, buttonTitle, handlePrimaryAction, timeout } =
    updatedNotificationProps.options;
  const message = updatedNotificationProps.message;

  const isError = variant === 'error';
  const isSucess = variant === 'success';

  function handleClose(
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) {
    console.log('reason', reason);
    if (reason === 'clickaway') {
      return;
    }
    MainStore.store.removeNotification();
  }

  return (
    <>
      {message ==
      "Cannot read properties of undefined (reading 'id')" ? null : (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          // style={{ background: 'red' }}
          className={isError ? classes.error : isSucess ? classes.success : ''}
          open={showingNotification}
          autoHideDuration={timeout === -1 ? null : timeout}
          onClose={handleClose}
          ContentProps={{
            className: isError
              ? classes.error
              : isSucess
              ? classes.success
              : '',
            'aria-describedby': 'message-id',
          }}
          message={
            <span id="message-id" className={classes.message}>
              {isError && <ErrorIcon className={classes.iconVariant} />}
              {message}
            </span>
          }
          action={[
            handlePrimaryAction && (
              <WhiteButton size="small" onClick={handlePrimaryAction}>
                {buttonTitle}
              </WhiteButton>
            ),
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      )}
    </>
  );
});

const WhiteButton = styled(Button)`
  color: white;
`;

function useNotificationProps(props: Notification): Notification {
  const notificationProps = props
    ? {
        message: props.message,
        options: {
          ...defaults.options,
          ...toJS(props.options),
        },
      }
    : defaults;
  const lastMessageRef = useRef<any>(notificationProps);

  useEffect(() => {
    if (notificationProps.message) {
      lastMessageRef.current = notificationProps;
    }
  }, [notificationProps]);

  const updatedNotificationProps = notificationProps.message
    ? notificationProps
    : lastMessageRef.current;

  return updatedNotificationProps;
}
