import React from 'react';

import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';

import { Constants } from '@checkpoints/shared';

import { styled } from '../../colors';
import { ModalStore } from '../../stores/ModalStore';
import { WhiteContainedButton, WhiteOutlinedButton } from '../button';
import { FlexBetween } from '../common';
import { DarkDialog } from '../modals';

const StyledAlertText = styled('div')`
  color: white;
  margin-bottom: 20px;
  white-space: pre-line;
`;

const StyledAlert = styled('div')`
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
`;

export const NotPaidEventAlert = observer(function NotPaidEventAlert() {
  const nav = useNavigate();
  const isOpen = ModalStore.store.createEventNoPlan;

  const onClose = () => {
    ModalStore.store.setCreateEventNoPlan(false);
  };

  return (
    <DarkDialog
      id={'NotPaidEventAlert'}
      open={isOpen}
      fullWidth={false}
      onClose={onClose}
    >
      <StyledAlert>
        <StyledAlertText>
          {Constants.ERRORS.CREATE_EVENT_NO_PAID_PLAN}
        </StyledAlertText>
        <FlexBetween>
          <WhiteOutlinedButton onClick={onClose}>Close</WhiteOutlinedButton>
          <WhiteContainedButton
            onClick={() => {
              nav('/dashboard/account');
            }}
          >
            My account
          </WhiteContainedButton>
        </FlexBetween>
      </StyledAlert>
    </DarkDialog>
  );
});
