import { useMediaQuery } from '@mui/material';
import { createTheme, ThemeOptions, css } from '@mui/material/styles';
import { createStyled } from '@mui/system';
import type {} from '@mui/x-date-pickers/themeAugmentation';

const colors = {
  nav: '#535353',
  primary: '#0372cd',
  dark: '#454545',
  gray: '#848484',
  online: '#03CA48',
  dimText: '#D8D8D8',
  error: '#C35959',

  backgroundFirst: '#454545',
  backgroundSecond: '#333333',
  // backgroundRGB: '51,51,51',
  // editModeBackgroundFirst: '#168FE5', //rgba(22, 143, 229)
  // editModeBackgroundFirst: '#168FE5', //rgba(22, 143, 229)
  editModeBackgroundFirst: '#018de8', //rgba(0, 121, 207)
  editModeBackgroundSecond: '#045bb5', //rgba(0, 121, 207)

  tableHeader: 'rgb(67, 67, 67)',
  editModeTableHeader: '#0189e4',

  tableHeaderFadeFirst: 'rgba(66,66,66)',
  tableHeaderFadeSecond: 'rgba(66,66,66, 0)',
  editModeTableHeaderFadeFirst: 'rgb(4, 136, 228)',
  editModeTableHeaderFadeSecond: 'rgba(4, 136, 228, 0)',
  minimapFadeFirst: 'rgba(54,54,54,0)',
  minimapFadeSecond: 'rgba(53,53,53,1)',
  editModeMinimapFadeFirst: 'rgb(3, 96, 186, 0)',
  editModeMinimapFadeSecond: 'rgb(3, 96, 186)',
};

export const runsheetColors = {
  gray: '#535353bf',
  blue: '#007fe8bf',
  orange: '#f26214bf',
  green: '#1ca350bf',
  yellow: '#ffd400bf',
  purple: '#ce0cb6b3',
} as const;

export const baseTheme: ThemeOptions = {
  palette: {
    colors,
    primary: {
      main: colors.primary,
      dark: colors.dark,
    },
    secondary: {
      main: '#bbbbbb',
    },
    grey: {
      '400': '#535353',
      '500': '#454545',
      '600': '#333333',
    },
    error: { main: '#C35959' },
    background: {
      default: colors.backgroundFirst,
      paper: '#ffffff',
      linearGradient: `
      linear-gradient(
        #454545,
        #333333
      );
    `,
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255,255,255,0.6)',
      disabled: 'rgba(255,255,255,0.38)',
    },
    divider: 'rgba(255,255,255,0.12)',
    contrastThreshold: 3,
  },
  typography: {
    // Use the system font.
    fontFamily: 'Open Sans, sans-serif',
  },
  mixins: {},
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          color: colors.dark,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: { fontFamily: 'Open Sans, sans-serif' },
      },
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          opacity: 0.8,
          '::before': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
          },
        },
        input: {
          color: '#ffffff',
          '&:focus': {
            outline: 1,
          },
          ':disabled': {
            WebkitTextFillColor: '#ffffff',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: { color: '#ffffff', opacity: 0.8 },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: { color: 'currentColor', padding: '9px' },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          background: 'transparent',
          color: colors.dark,
          textTransform: 'none',
        },
        contained: {
          background: '#ffffff',
          color: colors.primary,
          ':hover': {
            boxShadow:
              '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
            backgroundColor: '#d5d5d5',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          background: 'transparent',
          color: colors.dark,
          textTransform: 'none',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          background: 'transparent',
          color: colors.dark,
          textTransform: 'none',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: '#ffffff',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          pointerEvents: 'none',
        },
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          height: '24px',
          pointerEvents: 'none',
          ['[aria-disabled=true] + &']: {
            opacity: theme.palette.action.disabledOpacity,
          },
        }),
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          zIndex: 10000,
        },
      },
    },
  },
};

export const theme = createTheme(baseTheme);

export const styled = createStyled({ defaultTheme: theme });

export default colors;

export const linearGradient = css`
  background: linear-gradient(
    ${theme.palette.grey[500]},
    ${theme.palette.grey[600]}
  );
`;

// window.theme = theme;
const breakpoints = [600, 960, 1280, 1920];

export const mediaMaxJs = breakpoints.map((bp) => `@media (maxWidth: ${bp}px)`);

export const mediaMax = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const mediaMobile = mediaMax[0];

export const mediaTablet = mediaMax[1];

export const mediaLarge = mediaMax[2];

export function useMobile() {
  return useMediaQuery('(max-width:600px)');
}

type Colors = typeof colors;

declare module '@mui/material/styles' {
  interface TypeBackground {
    linearGradient?: string;
  }
  interface Palette {
    colors: Colors;

    background: TypeBackground;
  }
  interface PaletteOptions {
    colors?: Partial<Colors>;
    background?: Partial<TypeBackground>;
  }

  interface Theme {
    palette: Palette;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    palette?: PaletteOptions;
  }
}
