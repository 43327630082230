import { observer } from 'mobx-react';
import { runInAction } from 'mobx';

import {
  useOnNewNotificationSubscription,
  useOnRunsheetUpdatedSubscription,
  UserChannelVariant,
  useUserChannelSubscription,
  useUserRunsheetDetailsUpdatedSubscription,
} from '@checkpoints/shared';

import { CheckpointsStore } from '../../stores/checkpointsStore';
import MainStore from '../../stores/Store';
import { DashboardStore } from '../Dashboard/DashboardStore';

function useRunsheetUpdate(selectedRunsheetId: number) {
  useOnRunsheetUpdatedSubscription({
    variables: {
      id: selectedRunsheetId,
    },
    skip: !selectedRunsheetId,
    onSubscriptionData: (result) => {
      const runsheet = result.subscriptionData.data.runsheetUpdated;

      runInAction(() => {
        CheckpointsStore.store.setRunsheetStartTime(runsheet.startTime);
        CheckpointsStore.store.setShowSeconds(runsheet.showSeconds);
        CheckpointsStore.store.setDirector(runsheet.director);
      });
      if (
        JSON.stringify(CheckpointsStore.store.customHeaders) !==
        JSON.stringify(runsheet.headers)
      ) {
        CheckpointsStore.store.setCustomHeaders({
          customHeaders: runsheet.headers,
        });
      }
    },
  });
}
function useUserRunsheetDetailsUpdated(selectedRunsheetId: number) {
  useUserRunsheetDetailsUpdatedSubscription({
    variables: {
      runsheetId: selectedRunsheetId,
    },
    skip: !selectedRunsheetId,
    onSubscriptionData: (result) => {
      const data = result.subscriptionData.data.userRunsheetDetailsUpdated;

      // TODO: add this if we want to start tracking custom app headers
      // if (data.appHeaders) {
      //   if (
      //     JSON.stringify(CheckpointsStore.store.userHeaderOverrides) !==
      //     JSON.stringify(data.appHeaders)
      //   ) {
      //     CheckpointsStore.store.setCustomHeaders({
      //       userHeaderOverrides: data.appHeaders,
      //     });
      //   }
      // }
      if (data.notes) {
        CheckpointsStore.store.setUserNotes(data.notes);
      }
    },
  });
}

export const RunsheetUpdateListener = observer(
  function RunsheetUpdateListener(): any {
    const runsheetId = CheckpointsStore.store.selectedRunsheetId;
    useRunsheetUpdate(runsheetId);
    useUserRunsheetDetailsUpdated(runsheetId);

    return null;
  },
);

export function MeListener(): any {
  useUserChannelSubscription({
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data) {
        const result = subscriptionData.data.userChannel;

        switch (result.variant) {
          case UserChannelVariant.EventAdded:
            DashboardStore.store.addEvent(result.event);
            break;
          case UserChannelVariant.EventRemoved:
            DashboardStore.store.removeEvent(result.event);
            break;
          case UserChannelVariant.ProfileUpdated:
            MainStore.store.setMe(result.me);
            break;
          default:
            break;
        }
      }
    },
  });

  return null;
}
