import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

import {
  AppRunsheetFragment,
  AppUserFragment,
  encodeInteger,
  formatDate,
  Constants,
  RunsheetWithCheckpointsQuery,
  RunsheetWithCheckpointsQueryVariables,
  RunsheetWithCheckpointsDocument,
} from '@checkpoints/shared';

import colors, { styled } from '../../colors';
// import CheckCircle from '@mui/icons-material/CheckCircle';
// import WatchLater from '@mui/icons-material/WatchLater';
import { DasboardAvatar, DashboardUserRowRing } from '../../components/avatar';
import {
  InfoIcon,
  PersonIcon,
  SettingsIcon,
  StarIcon,
} from '../../components/common';
import { ToolTipText, TooltipWrapper } from '../../components/tooltip';
import { CheckpointsStore } from '../../stores/checkpointsStore';
import MainStore from '../../stores/Store';

import { RunsheetDateCircle } from './RunsheetDateCircle';

interface RunsheetRowsProps {
  runsheet: AppRunsheetFragment;
  onSettingsClicked: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    runsheet: AppRunsheetFragment,
  ) => void;
}

export const RunsheetRow = observer(
  ({ runsheet, onSettingsClicked }: RunsheetRowsProps) => {
    function fixNumbers(nr: number) {
      if (nr < 10) {
        return '0' + nr;
      }

      return nr;
    }

    const date = runsheet.startDate && new Date(runsheet.startDate);
    const startTime = runsheet.startTime;
    const startMinutes: number = startTime && +startTime.slice(3, 5);
    const startHours: number = startTime && +startTime.slice(0, 2);

    const [endTime, setendTime] = React.useState(startTime);

    if (endTime == startTime) {
      MainStore.store.client
        .query<
          RunsheetWithCheckpointsQuery,
          RunsheetWithCheckpointsQueryVariables
        >({
          query: RunsheetWithCheckpointsDocument,
          variables: { id: runsheet.id },
        })
        .then((res) => {
          let CalculatingDuration = 0;

          res.data.runsheet.checkpoints.forEach((checkpoint) => {
            if (checkpoint.duration) {
              const hours = checkpoint.duration.slice(0, 2);
              const minutes = checkpoint.duration.slice(3, 5);
              const seconds = checkpoint.duration.slice(6, 8);

              const checkpointDuration =
                parseInt(hours) * 3600 +
                parseInt(minutes) * 60 +
                parseInt(seconds);
              CalculatingDuration += checkpointDuration;

              const endMinutes =
                startHours * 60 +
                startMinutes +
                Math.floor(CalculatingDuration / 60);

              let endHours = Math.floor(endMinutes / 60);
              const endMinutesMod = endMinutes % 60;

              if (endHours > 23) {
                endHours = endHours - 24;
              }

              setendTime(
                fixNumbers(endHours) + ':' + fixNumbers(endMinutesMod),
              );
            }
          });
        });
    }

    // const error = false;
    return (
      <StyledRunsheetRow className="fadeIn">
        <Link
          to={`/dashboard/runsheets/${encodeInteger(runsheet.id)}`}
          onClick={() => {
            CheckpointsStore.store.setSelectedRunsheetId(runsheet.id);
          }}
        >
          <TooltipWrapper title={formatDate(date, 'dd MMM yyyy')}>
            <RunsheetDateCircle>{date && date.getDate()}</RunsheetDateCircle>
          </TooltipWrapper>
          <TooltipWrapper title={runsheet.title} className="nameTooltip">
            <RunsheetTitle>
              <span>{runsheet.title}</span>
              <div className="time">
                <AccessTimeFilledIcon
                  sx={{
                    fontSize: 14,
                    marginRight: '2px',
                    position: 'relative',
                    top: '3px',
                  }}
                />
                <span>
                  {fixNumbers(startHours)}:{fixNumbers(startMinutes)}
                  {' - '}
                  {endTime}
                </span>
              </div>
            </RunsheetTitle>
          </TooltipWrapper>
        </Link>
        {onSettingsClicked && (
          <IconButton
            className="popover"
            onClick={(e) => {
              onSettingsClicked(e, runsheet);
            }}
            size="large"
          >
            {/* {error && <ErrorIcon />} */}
            <SettingsIcon />
          </IconButton>
        )}
      </StyledRunsheetRow>
    );
  },
);

const StyledRunsheetRow = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  a {
    color: white;
    text-decoration: none;

    display: flex;
    align-items: center;
    overflow: hidden;
    font-size: 16px;
    flex-grow: 1;
    mask-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) calc(100% - 15px),
      transparent 100%
    );
  }
  button {
    color: white;
  }
`;

const RunsheetTitle = styled('div')`
  flex: 1 1 auto;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  line-height: 19px;
  white-space: nowrap;

  position: relative;

  .time {
    font-size: 12px;
    font-weight: 600;
  }
`;

interface UserRowProps {
  user: AppUserFragment;
  isOwner?: boolean;
  showEmail?: boolean;
  disabled?: boolean;
  onSettingsClicked?: (user?: AppUserFragment, event?: any) => void;
  isViewedByAdmin?: boolean;
}

export const UserRow = observer(function ({
  user,
  isOwner = false,
  onSettingsClicked,
  showEmail = false,
  disabled = false,
  isViewedByAdmin = false,
}: UserRowProps) {
  const isKnownUser = !!user.first_name;
  const isSettingsIcon = !isOwner;

  let title = 'Owner';

  if (!isOwner) {
    title = '';
  }

  return (
    <>
      <StyledUserRow className="fadeIn">
        {isKnownUser ? (
          <DasboardAvatar user={user} />
        ) : (
          <DashboardUserRowRing>{'?'}</DashboardUserRowRing>
        )}
        <div className="name_fields">
          {isKnownUser ? (
            <NameAndSubtitle {...{ user, showEmail, isViewedByAdmin }} />
          ) : (
            <NameField>{user.email}</NameField>
          )}
        </div>
        {onSettingsClicked && (
          <TooltipWrapper title={title} aria-label={title}>
            <IconButton
              onClick={(e) => {
                if (!isOwner) {
                  onSettingsClicked(user, e);
                }
              }}
              className={isSettingsIcon ? 'popover' : ''}
              disabled={disabled}
              size="large"
            >
              {isOwner && <StarIcon />}
              {isSettingsIcon && <SettingsIcon />}
            </IconButton>
          </TooltipWrapper>
        )}
      </StyledUserRow>
    </>
  );
});

interface UserRowProps {
  user: AppUserFragment;
  isOwner?: boolean;
  infoText?: string;
  disabled?: boolean;
  onSettingsClicked?: (user?: AppUserFragment, event?: any) => void;
}

export const UserSearchRow = observer(function ({
  user,
  isOwner = false,
  onSettingsClicked,
  infoText,
  disabled = false,
}: UserRowProps) {
  const isKnownUser = !!user.first_name;
  const nonExistingUser = !!user.id;

  const settingsIcon = (
    <IconButton
      onClick={(e) => {
        if (!isOwner) {
          onSettingsClicked(user, e);
        }
      }}
      disabled={disabled}
      size="large"
    >
      {<PersonIcon />}
    </IconButton>
  );

  return (
    <>
      <StyledUserRow className="fadeIn">
        {isKnownUser ? (
          <DasboardAvatar user={user} />
        ) : (
          <DashboardUserRowRing>{'?'}</DashboardUserRowRing>
        )}
        <div className="name_fields">
          {isKnownUser ? (
            <NameAndSubtitle {...{ user, showEmail: true }} />
          ) : (
            <NameField>{user.email}</NameField>
          )}
        </div>
        {infoText && (
          <TooltipWrapper
            title={<ToolTipText>{infoText}</ToolTipText>}
            aria-label="change-user-status"
          >
            <InfoIcon />
          </TooltipWrapper>
        )}
        {isOwner ? (
          <TooltipWrapper title="Owner" aria-label="owner">
            {settingsIcon}
          </TooltipWrapper>
        ) : (
          onSettingsClicked && settingsIcon
        )}
      </StyledUserRow>
      {!nonExistingUser && (
        <p className="gray_text">{Constants.NON_EXISTING_USER_MESSAGE}</p>
      )}
    </>
  );
});

const StyledUserRow = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  div.name_fields {
    flex: 1 1 auto;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    mask-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) calc(100% - 15px),
      transparent 100%
    );
  }

  button {
    flex-shrink: 0;
    &.MuiIconButton-root.Mui-disabled {
      color: white;
    }
    color: white;
  }
`;

export const NameField = styled('span')`
  font-size: 16px;
  color: white;
`;

export const SubtitleField = styled('span')`
  font-size: 10px;
  color: ${colors.dimText};
`;

const NameAndSubtitle = observer(function NameAndSubtitle({
  user,
  showEmail = false,
  isViewedByAdmin = false,
}: {
  user: AppUserFragment;
  showEmail?: boolean;
  isViewedByAdmin?: boolean;
}) {
  //React to changes to me;
  const me = MainStore.store.me;

  if (me && user.id === me.id) {
    user = me;
  }

  return isViewedByAdmin ? (
    <>
      <TooltipWrapper title={user.email} aria-label="email">
        <NameField>{user.first_name + ' ' + user.last_name}</NameField>
      </TooltipWrapper>
      {showEmail && <SubtitleField>{user.email}</SubtitleField>}
    </>
  ) : (
    <>
      <NameField>{user.first_name + ' ' + user.last_name}</NameField>
      {showEmail && <SubtitleField>{user.email}</SubtitleField>}
    </>
  );
});
// export const UserSearchRow = ({
//   user,
//   onAdded,
// }: {
//   user: AppUserFragment;
//   onAdded: (user: any) => void;
// }) => {
//   const isKnownUser = !!user.first_name;
//   return (
//     <>
//       <StyledUserRow>
//         {isKnownUser ? (
//           <Avatar user={user} />
//         ) : (
//           <UserRowRing>{'?'}</UserRowRing>
//         )}
//         <div className="name_fields">
//           {isKnownUser ? (
//             <>
//               <NameField>{user.first_name + ' ' + user.last_name}</NameField>
//               <SubtitleField>{user.email}</SubtitleField>
//             </>
//           ) : (
//             <NameField>{user.email}</NameField>
//           )}
//         </div>
//         <IconButton
//           onClick={() => {
//             onAdded(user);
//           }}
//         >
//           <PersonIcon />
//         </IconButton>
//       </StyledUserRow>
//       {!isKnownUser && <p className="gray_text">{NON_EXISTING_USER_MESSAGE}</p>}
//     </>
//   );
// };
