import { FormHTMLAttributes } from 'react';

import { makeStyles } from 'tss-react/mui';

import colors, { mediaMobile } from '../../colors';

/** Gives every direct child a margin bottom, has a max width of 400px  */
// export const FlexFormCSS = css`
//   max-width: 400px;
//   margin: 0 auto;
//   display: flex;
//   flex-direction: column;
//   > * {
//     margin: 10px 0;
//   }
//   button {
//     align-self: flex-end;
//   }

//   color: white;
// `;

const formStyles = {
  maxWidth: '400px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  '> *': {
    margin: '10px 0',
  },
  button: {
    alignSelf: 'flex-end',
  },
  color: 'white',
} as const;

export const useFormStyles = makeStyles()(() => ({
  flexForm: formStyles,
  flexFormDiv: formStyles,
  logoImageDiv: {
    display: 'flex',
    marginBottom: '40px',
    'img, svg': {
      margin: '0 auto',
      width: '300px',
      height: '64px',
    },
    [mediaMobile]: {
      'img, svg': {
        width: '180px',
      },
    },
  },
  borderedForm: {
    ...formStyles,
    padding: '20px',
    [mediaMobile]: {
      margin: '0',
      flex: '1 1 auto',
      width: '100%',
      height: '100%',
      maxWidth: '560px',
      boxSizing: 'content-box',
      marginTop: '40px',
    },
    boxShadow: 'rgba(0, 0, 0, 0.15) 5px 5px 60px',
    borderRadius: '10px',
    transition: 'box-shadow 0.5s cubic-bezier(0.39, 0.575, 0.565, 1)',
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.3) 5px 5px 60px',
    },
  },
}));

// export const FlexForm = styled('form')`
//   ${FlexFormCSS}
// `;

// export const LogoImageDiv = styled('div')`
//   display: flex;
//   margin-bottom: 40px;
//   img,
//   svg {
//     margin: 0 auto;
//     width: 300px;
//     height: 64px;
//   }
//   ${mediaMobile} {
//     img,
//     svg {
//       width: 180px;
//     }
//   }
// `;

// export const BorderdForm = styled(FlexForm)`
//   /* background: ${colors.backgroundFirst}}; */
//   padding: 20px;
//   ${mediaMobile} {
//     margin: 0;
//     flex: 1 1 auto;
//     width: 100%;
//     height: 100%;
//     max-width: 560px;
//     box-sizing: content-box;
//     margin-top: 40px;
//   }
//   box-shadow: rgba(0, 0, 0, 0.15) 5px 5px 60px;
//   border-radius: 10px;
//   transition: box-shadow 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
//   &:hover {
//     box-shadow: rgba(0, 0, 0, 0.3) 5px 5px 60px;
//   }
// ` as React.ComponentType<FormHTMLAttributes<any>>;
