import React from 'react';

import { IconButton } from '@mui/material';
import { observer } from 'mobx-react';

import {
  ImportCheckpointsFromCsvDocument,
  ImportCheckpointsFromCsvMutation,
  ImportCheckpointsFromCsvMutationVariables,
  ParseCsvImportDocument,
  ParseCsvImportMutation,
  ParseCsvImportMutationVariables,
} from '@checkpoints/shared';

import { ExportIcon, ImportIcon } from '../../components/common';
import { CheckpointsStore } from '../../stores/checkpointsStore';
import MainStore from '../../stores/Store';

export class ImportStore {
  static _store: ImportStore;
  static get store() {
    if (!this._store) {
      this._store = new ImportStore();
    }

    return this._store;
  }

  input: HTMLInputElement;
  reader: FileReader;
  onFileLoaded = async (event: ProgressEvent<FileReader>) => {
    try {
      const data = event.target.result;
      const client = MainStore.store.client;
      const result = await client.mutate<
        ParseCsvImportMutation,
        ParseCsvImportMutationVariables
      >({
        mutation: ParseCsvImportDocument,
        variables: {
          data: data as string,
        },
      });

      if (result.data.parseCSVImport) {
        // If it worked, we try and run the import
        // TODO: Show the user a modal where they can select which columns they want to include
        await client.mutate<
          ImportCheckpointsFromCsvMutation,
          ImportCheckpointsFromCsvMutationVariables
        >({
          mutation: ImportCheckpointsFromCsvDocument,
          variables: {
            runsheetId: CheckpointsStore.store.selectedRunsheetId,
            data: data as string,
          },
        });
        MainStore.store.displayNotification({
          message: 'Imported completed!',
          options: { variant: 'success' },
        });
      }
    } catch (e) {}
  };
  onFileChanged = (e: Event) => {
    const files = (e.target as HTMLInputElement).files;
    console.log('files', files);
    if (!this.reader) {
      this.reader = new FileReader();
      this.reader.addEventListener('load', this.onFileLoaded);
    }
    this.reader.readAsText(files[0]);
  };
  importFile(_e: any) {
    if (!this.input) {
      this.input = document.createElement('input');
      this.input.type = 'file';
      this.input.id = 'importButton';
      this.input.accept = '.csv';
      this.input.style.display = 'none';
      this.input.addEventListener('change', this.onFileChanged);
    }
    this.input.value = null;
    this.input.click();
  }
}

const IS_DANGEROUS_IMPORT_ENABLED = false;

export const ImportExportActions = observer(() => {
  const handleExport = () => {
    CheckpointsStore.store.exportCSV();
  };

  const handleImport: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    ImportStore.store.importFile(e);
  };
  const isAdmin = CheckpointsStore.store.isAdmin;

  return (
    <div className="noMobile download_button">
      <span className="download_label">Export CSV</span>

      <br />
      <IconButton title="Export to CSV" onClick={handleExport} size="large">
        <ExportIcon />
      </IconButton>
      {IS_DANGEROUS_IMPORT_ENABLED && isAdmin && (
        <IconButton title="Import from CSV" onClick={handleImport} size="large">
          <ImportIcon />
        </IconButton>
      )}
    </div>
  );
});
