import React, { ReactNode, useEffect, useState } from 'react';

import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

import { styled } from '../../colors';

interface TooltipWrapperProps extends TooltipProps {
  children: React.ReactElement;
  title: React.ReactNode;
  show?: boolean;
}

export const TooltipWrapper = ({
  children,
  title,
  show = true,
  ...rest
}: TooltipWrapperProps) => {
  if (show === false) return <>{children}</>;

  return (
    <Tooltip title={title} enterTouchDelay={50} leaveTouchDelay={50} {...rest}>
      <div style={{ display: 'flex' }}>{children}</div>
    </Tooltip>
  );
};

export const ToolTipText = styled('div')`
  max-width: 200px;
  word-wrap: break-word;
`;
