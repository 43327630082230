import React, { useEffect, useRef, useState } from 'react';

import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PercentCrop,
  PixelCrop,
  ReactCropProps,
} from 'react-image-crop';
import './Cropper.scss';

const defaultCrop: PixelCrop = {
  unit: 'px',
  width: 900,
  height: 900,
  x: 0,
  y: 0,
};

export function Cropper({
  file,
  handleChange,
  cropProps,
}: {
  file: File;
  handleError?: (err: any) => void;
  handleChange: (crop: Crop) => void;
  cropProps?: Partial<ReactCropProps>;
}) {
  const imageRef = useRef<React.SyntheticEvent<HTMLImageElement, Event>>();
  const [src, setSrc] = useState<string>();
  const [crop, setCrop] = useState<Crop>(defaultCrop);

  useEffect(() => {
    const reader = new FileReader();
    const handleLoad = () => setSrc(reader.result as string);
    reader.addEventListener('load', handleLoad);
    // reader.addEventListener('error', handleError);

    if (file) {
      reader.readAsDataURL(file);
    }

    return () => {
      reader.removeEventListener('load', handleLoad);
    };
  }, [file]);

  const onCropChange = (crop: PixelCrop, percentageCrop: PercentCrop) => {
    // console.log('percentCrop', percentCrop);
    setCrop(percentageCrop);
  };

  const onImageLoaded: React.ReactEventHandler<HTMLImageElement> = (image) => {
    imageRef.current = image;

    const { naturalWidth: width, naturalHeight: height } = image.currentTarget;
    const aspectRatio = cropProps?.circularCrop ? 1 : 3;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspectRatio,
        width,
        height,
      ),
      width,
      height,
    );

    setCrop(crop);
    handleChange(crop);
  };

  const onCropComplete = (crop: PixelCrop, percentageCrop: PercentCrop) => {
    handleChange(percentageCrop);
  };

  if (!src) {
    return null;
  }

  const length = src.toString().length;

  if (length > 4100000) {
    return (
      <p>
        <b>Error:</b> You tried to upload an image that was too large...
      </p>
    );
  }

  return (
    <ReactCrop
      {...cropProps}
      crop={crop}
      //TODO: calculate minWidth based on image size and current div size
      minWidth={150}
      aspect={cropProps?.circularCrop ? 1 : 3}
      keepSelection={true}
      onComplete={onCropComplete}
      onChange={onCropChange}
    >
      <img src={src} onLoad={onImageLoaded} />
    </ReactCrop>
  );
}
