import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import {
  Location,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { DashboardLayout } from '../components/layouts';
import { DashboardUserPopover } from '../components/popovers';
import MainStore from '../stores/Store';

import { Account } from './Account/Account';
import { AddModal } from './Dashboard/AddModal';
import { EditEvent } from './Dashboard/EditEvent';
import { DashboardStore } from './Dashboard/DashboardStore';
import { Dashboard } from './Dashboard/Dashboard';
import { Runsheet } from './checkpoints/Runsheet';
import { MeListener } from './checkpoints/Listeners';
import { SuperAdmin } from './admin/SuperAdmin';

export const AuthRoute = observer(function Main() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    MainStore.store.getMe().then((res) => {
      if (res === false) {
        navigate('/login');
      }
    });
  }, [navigate]);

  const me = MainStore.store.me;

  if (!me) {
    return null;
  }

  return (
    <>
      <MainComponent location={location} />
      <MeListener />
    </>
  );
});

const MainComponent = ({ location }: { location: Location }) => {
  const isAtRunsheet = location.pathname.includes('runsheets/');

  useEffect(() => {
    if (!isAtRunsheet) {
      DashboardStore.store.fetchEvents();
    }
  }, [isAtRunsheet]);

  return (
    <>
      <DashboardLayout disable={isAtRunsheet}>
        <>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route
              path="/runsheets/:selectedRunsheetId"
              element={<Runsheet />}
            />
            <Route path="/create" element={<EditEvent />} />
            <Route path="/edit/:eventId" element={<EditEvent />} />
            <Route path="/account" element={<Account />} />
            <Route path="/topsecret" element={<SuperAdmin />} />
            {/* TODO: Replace redirect logic? */}
            {/* <Redirect default from="/dashboard/runsheets" to="/" noThrow /> */}
          </Routes>
          <AddModal />
          <DashboardUserPopover />
        </>
      </DashboardLayout>
    </>
  );
};
