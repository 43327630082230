import { ApolloClient } from '@apollo/client';

import {
  ExtendedCheckpoint,
  HeaderSetting,
  TableColumn,
  useCreateCheckpointMutation,
  useDeleteRunsheetHeaderMutation,
  useSetRunsheetHeadersMutation,
  useUpdateRunsheetHeaderMutation,
  useUpdateUserRunsheetHeadersMutation,
  UpdateUserRunsheetHeadersDocument,
} from '@checkpoints/shared';

import { CheckpointsStore } from '../checkpointsStore';

import { refreshCheckpointOrderIds } from './reloadCheckpoints';

export function useUpdateUserHeader() {
  const [update] = useUpdateUserRunsheetHeadersMutation({
    fetchPolicy: 'no-cache',
  });

  const handleUpdateActive = (header: TableColumn, updates: HeaderSetting) => {
    CheckpointsStore.store.updateUserHeader(header, updates);

    const userHeaderOverrides =
      CheckpointsStore.store.convertedServerUserHeaders;
    CheckpointsStore.store.userHeaderOverrides = userHeaderOverrides;
    const selectedRunsheetId = CheckpointsStore.store.selectedRunsheetId;

    //Save to server
    update({
      variables: {
        runsheetId: selectedRunsheetId,
        headers: userHeaderOverrides,
      },
    });
  };

  return handleUpdateActive;
}

export async function saveCurrentUserHeaders(client: ApolloClient<any>) {
  const userHeaderOverrides = CheckpointsStore.store.convertedServerUserHeaders;
  CheckpointsStore.store.userHeaderOverrides = userHeaderOverrides;
  const selectedRunsheetId = CheckpointsStore.store.selectedRunsheetId;

  await client.mutate({
    mutation: UpdateUserRunsheetHeadersDocument,
    variables: {
      runsheetId: selectedRunsheetId,
      headers: userHeaderOverrides,
    },
  });
}

export function useReorderUserHeader() {
  const [update] = useUpdateUserRunsheetHeadersMutation({
    fetchPolicy: 'no-cache',
  });

  const reorder = async (from: number, to: number) => {
    //reorder
    CheckpointsStore.store.reorderHeaders(from, to);

    const userHeaderOverrides =
      CheckpointsStore.store.convertedServerUserHeaders;
    //Save userHeaderOverrides to cache
    CheckpointsStore.store.userHeaderOverrides = userHeaderOverrides;
    await update({
      variables: {
        runsheetId: CheckpointsStore.store.selectedRunsheetId,
        headers: userHeaderOverrides,
      },
    });
  };

  return reorder;
}

export function useCreateNewRow({
  checkpoint: row,
  below,
}: {
  checkpoint?: ExtendedCheckpoint;
  below?: boolean;
}) {
  const [createCheckpoint] = useCreateCheckpointMutation({
    fetchPolicy: 'no-cache',
  });

  const handleCreateRow = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let newCheckpoint = row;
    if (!newCheckpoint) {
      //duplicate last row.
      const checkpoints = CheckpointsStore.store.sortedCheckpoints;
      newCheckpoint = checkpoints[checkpoints.length - 1];
    }

    const { checkpoint, shouldReload } =
      CheckpointsStore.store.createDuplicateCheckpoint(newCheckpoint, below);
    const { order, duration, runsheetId } = checkpoint;

    return createCheckpoint({
      variables: {
        body: {
          title: '',
          order,
          duration,
          runsheetId,
          active: true,
        },
      },
    }).finally(() => {
      if (shouldReload) {
        refreshCheckpointOrderIds(checkpoint.runsheetId);
      }
    });
  };

  return handleCreateRow;
}

export function useUpdateHeader(column: TableColumn) {
  const [update] = useUpdateRunsheetHeaderMutation({ fetchPolicy: 'no-cache' });

  const updateHeader = async (title: string) => {
    await update({
      variables: {
        headerId: column.accessor,
        runsheetId: CheckpointsStore.store.selectedRunsheetId,
        title,
      },
    });
  };
  const [del] = useDeleteRunsheetHeaderMutation({ fetchPolicy: 'no-cache' });

  const deleteHeader = async () => {
    await del({
      variables: {
        headerId: column.accessor,
        runsheetId: CheckpointsStore.store.selectedRunsheetId,
      },
    });
    CheckpointsStore.store.resetPopovers();
  };
  const [set] = useSetRunsheetHeadersMutation({ fetchPolicy: 'no-cache' });

  const setHeaders = async (headers: any) => {
    await set({
      variables: {
        headers,
        runsheetId: CheckpointsStore.store.selectedRunsheetId,
      },
    });
    // CheckpointsStore.store.setCustomHeaders({
    //   customHeaders: result.data.deleteRunsheetHeader.headers,
    // });
  };

  return { updateHeader, deleteHeader, setHeaders };
}
