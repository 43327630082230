import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Crop, ReactCropProps } from 'react-image-crop';

import { Cropper } from '../image-cropper';

export interface ResizeDialogRawProps {
  open: boolean;
  file: File;
  onClose: (value?: Crop) => void;

  classes?: Record<'paper', string>;
  keepMounted?: boolean;

  cropProps?: Partial<ReactCropProps>;
}

export function ResizeDialogRaw({
  open,
  file,
  onClose,
  cropProps,
  ...other
}: ResizeDialogRawProps) {
  const [crop, setCrop] = useState<Crop>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [open]);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    setLoading(true);
    onClose(crop);
  };

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="sm"
      // onEntering={handleEntering}
      aria-labelledby="resize-dialog-title"
      open={open}
      id="resize-dialog"
      {...other}
    >
      <DialogTitle id="resize-dialog-title"></DialogTitle>
      <DialogContent dividers={false}>
        <Cropper
          cropProps={cropProps}
          file={file}
          handleChange={(crop) => {
            setCrop(crop);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary" disabled={loading}>
          {loading ? 'Loading...' : 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
