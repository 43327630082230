import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import {
  AppUserFragment,
  encodeInteger,
  formatDate,
} from '@checkpoints/shared';

import { mediaTablet, styled } from '../../colors';
import {
  DeleteButton,
  WhiteContainedButton,
  WhiteOutlinedButton,
} from '../../components/button';
import { DashedContainer } from '../../components/dashed-container';
import { DarkDialog } from '../../components/modals';
import { InnerRunsheetEditor } from '../../components/popovers/InnerRunsheetEditor';
import { CheckpointsStore } from '../../stores/checkpointsStore';
import { ModalStore } from '../../stores/ModalStore';

import {
  AddModalType,
  DashboardStore,
  PartialRunsheet,
} from './DashboardStore';
import { UserRow } from './DashboardRows';
import { UserSearch } from './UserSearch';

function createDefaultRunsheet(): PartialRunsheet {
  const initialDate = formatDate(new Date(), 'yyyy-MM-dd');
  const initialRunsheet = {
    title: '',
    startTime: '00:00:00',
    startDate: initialDate,
    showSeconds: false,
  };

  return initialRunsheet;
}

const createRunsheet = async (
  runsheet: PartialRunsheet,
  nav: NavigateFunction,
  enter = false,
) => {
  const selectedEvent = DashboardStore.store.activeEvent;
  const result = await DashboardStore.store.createRunsheet({
    ...runsheet,
    eventId: selectedEvent.id,
  });
  DashboardStore.store.hideModal();

  if (enter) {
    CheckpointsStore.store.setSelectedRunsheetId(result.id);

    nav(`/dashboard/runsheets/${encodeInteger(result.id)}`);
  }
  // CheckpointsStore.store.runsheetStartTime =
  //   result.data.updateRunsheet.startTime;
};

const updateRunsheet = async (
  runsheet: PartialRunsheet,
  nav: NavigateFunction,
  enter = false,
) => {
  const { id, title, startDate, startTime, showSeconds } = runsheet;

  const result = await DashboardStore.store.updateRunsheet({
    id,
    title,
    startDate,
    startTime,
    showSeconds,
  });

  DashboardStore.store.hideModal();

  if (enter) {
    nav(`/dashboard/runsheets/${encodeInteger(result.id)}`);
  }
};

export const AddModal = observer(function AddModal() {
  const modalActive = DashboardStore.store.showingAddModal;
  const modalType = DashboardStore.store.showingAddModalType;

  const isEditRunsheet = modalType === AddModalType.EditRunsheet;
  const isRunsheetModal =
    modalType === AddModalType.AddRunsheet || isEditRunsheet;

  const modalId = 'add_event_details_dialog';

  return (
    <DarkDialog
      id={modalId}
      open={modalActive}
      fullWidth={!isRunsheetModal}
      onClose={() => DashboardStore.store.hideModal()}
    >
      <>{isRunsheetModal ? <RunsheetModal /> : <AddUsersModal />}</>
    </DarkDialog>
  );
});

const AddUsersModal = observer(() => {
  const selectedEvent = DashboardStore.store.activeEvent;
  // const modalActive = DashboardStore.store.showingAddModal;
  const modalType = DashboardStore.store.showingAddModalType;
  const isAdmins = modalType === AddModalType.Admin;

  const title =
    modalType === AddModalType.Subscriber ? 'Add subscriber' : 'Add admin';

  const currentUsers = selectedEvent
    ? modalType === AddModalType.Subscriber
      ? selectedEvent.subscribers
      : selectedEvent.admins
    : [];

  const handleUserClicked = (user: AppUserFragment, event: any) => {
    DashboardStore.store.activateUserPopoverSettings({
      anchorEl: event.target,
      user: user,
      isAdmin: isAdmins,
    });
  };

  return (
    <SplitModalView>
      <div>
        <h3>{title}</h3>
        <UserSearch isAdmins={isAdmins} />
      </div>
      <DashedContainer>
        {currentUsers.map((a) => (
          <UserRow
            showEmail
            onSettingsClicked={handleUserClicked}
            key={`${a.id}`}
            user={a}
            isOwner={a.id === selectedEvent.owner.id}
          />
        ))}
      </DashedContainer>
    </SplitModalView>
  );
});

const RunsheetModal = observer(() => {
  const navigate = useNavigate();

  const modalActive = DashboardStore.store.showingAddModal;
  const modalType = DashboardStore.store.showingAddModalType;
  const isEditRunsheet = modalType === AddModalType.EditRunsheet;

  const [runsheet, setRunsheet] = useState(createDefaultRunsheet());

  useEffect(() => {
    if (modalActive) {
      if (isEditRunsheet) {
        setRunsheet(DashboardStore.store.addModalMeta);
      } else {
        setRunsheet(createDefaultRunsheet());
      }
    }
  }, [modalActive, isEditRunsheet]);

  const updateRunsheetDetails = async (updates: any) => {
    setRunsheet((r) => ({ ...r, ...updates }));
  };

  const save = () => {
    if (isEditRunsheet) {
      updateRunsheet(runsheet, navigate);

      return;
    }

    createRunsheet(runsheet, navigate);
  };

  const deleteRunsheet = async () => {
    const result = await ModalStore.store.confirm(
      'Are you sure you want to delete this runsheet with all of its Checkpoints?',
    );

    if (result) {
      await DashboardStore.store.deleteRunsheet(runsheet as any);
      DashboardStore.store.hideModal();
    }
  };

  const saveAndEnterRunsheet = () => {
    if (isEditRunsheet) {
      updateRunsheet(runsheet, navigate, true);

      return;
    }
    createRunsheet(runsheet, navigate, true);
  };

  const title =
    modalType === AddModalType.AddRunsheet ? 'Add runsheet' : 'Edit runsheet';

  return (
    <RunsheetModalView>
      <h3>{title}</h3>
      <InnerRunsheetEditor
        runsheet={runsheet as any}
        autoFocus
        updateRunsheet={updateRunsheetDetails}
      />
      <div className="buttons">
        {isEditRunsheet && (
          <>
            <DeleteButton onClick={deleteRunsheet}>
              Delete runsheet
            </DeleteButton>
            <div className="spacer"></div>
          </>
        )}
        <WhiteOutlinedButton className="plain" type="submit" onClick={save}>
          Save
        </WhiteOutlinedButton>
        <WhiteContainedButton
          onClick={saveAndEnterRunsheet}
          style={{ marginLeft: 10 + 'px' }}
        >
          Save and enter runsheet
        </WhiteContainedButton>
      </div>
    </RunsheetModalView>
  );
});

const RunsheetModalView = styled('div')`
  padding-top: 4px;
  padding-bottom: 16px;

  h3 {
    padding: 0 26px;
    color: white;
    font-weight: 700;
    font-size: 14px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    padding: 0 26px;

    ${mediaTablet} {
      flex-direction: column;

      .spacer {
        height: 20px;
      }

      button {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
`;

const SplitModalView = styled('div')`
  display: flex;
  padding-top: 4px;
  padding-bottom: 16px;
  color: white;
  > div {
    width: 50%;

    &:first-of-type {
      margin-right: 10px;
    }

    &:last-of-type {
      margin-left: 10px;
    }
  }
  ${mediaTablet} {
    flex-direction: column;

    > div {
      width: 100%;
      &:first-of-type,
      &:last-of-type {
        margin: 0;
      }
      height: 350px;
    }
  }

  h3 {
    color: white;
    font-weight: 700;
    font-size: 14px;
  }

  .dashed_container {
    height: 350px;
  }
`;
