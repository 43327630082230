import React from 'react';

import { observer } from 'mobx-react';

import { styled } from '../../colors';
import packagejson from '../../../package.json';

import { Navbar } from './Navbar';
import { InnerContainer, OuterContainer } from './OuterContainer';

export const DashboardLayout = observer(function DashboardLayout({
  disable,
  children,
}: {
  disable: boolean;
  children: JSX.Element;
}) {
  if (disable) {
    return children;
  }

  return (
    <OuterContainer>
      <InnerContainer>
        <Navbar />
        <FlexGrowChildren>{children}</FlexGrowChildren>
        <Footer />
      </InnerContainer>
    </OuterContainer>
  );
});

const FlexGrowChildren = styled('div')`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  & > div {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    & > div {
      flex: 1 1 auto;
    }
  }
`;

function Footer() {
  return <StyledFooter>{`Version: ${packagejson.version}`}</StyledFooter>;
}

const StyledFooter = styled('footer')`
  width: 100%;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;
