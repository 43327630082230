export function scrollToTracked() {
  setTimeout(() => {
    const tracked: HTMLDivElement = document.querySelector('.tracked');
    if (!tracked) return;
    const container = document.getElementById('table-container');

    container.scrollTo({
      top: tracked.offsetTop - 108,
      behavior: 'smooth',
    });
    // tracked.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'center',
    //   // inline: 'start',
    // });
  }, 100);
}
