import React from 'react';

import IconButton from '@mui/material/IconButton';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';

import { AddCircleIcon } from '../common';

export function AddButton({
  onClick,
  className,
}: {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
}) {
  return (
    <IconButton
      className={className}
      onClick={onClick}
      color="inherit"
      size="large"
    >
      <AddCircleIcon />
    </IconButton>
  );
}

export function AddButtonTwoToned({
  onClick,
  className,
}: {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
}) {
  return (
    <IconButton
      className={className}
      onClick={onClick}
      color="inherit"
      size="large"
    >
      <AddCircleTwoToneIcon />
    </IconButton>
  );
}
