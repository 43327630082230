import { useEffect, useRef, useState } from 'react';

export function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  return windowWidth;
}

export function useEscapeKey(callback: (event?: any) => void) {
  const savedHandler = useRef<(event?: any) => void>();

  useEffect(() => {
    savedHandler.current = callback;
  }, [callback]);

  useEffect(() => {
    // Create event listener that calls handler function stored in ref

    const listener = (event: KeyboardEvent) => {
      //Escape
      if (event.key === 'Escape' || event.keyCode === 27) {
        if (savedHandler.current) {
          savedHandler.current(event);
        }
      }
    };
    window.addEventListener('keydown', listener);

    return () => window.removeEventListener('keydown', listener);
  }, []);
}

export function useEventListener(
  eventName: string,
  handler: (ev: KeyboardEvent) => void,
  element = window,
) {
  // Create a ref that stores handler
  const savedHandler = useRef<(event: Event) => void>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    // Make sure element supports addEventListener
    // On
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    // Create event listener that calls handler function stored in ref
    const eventListener = (event) => savedHandler.current(event);

    // Add event listener
    element.addEventListener(eventName, eventListener);

    // Remove event listener on cleanup
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]); // Re-run if eventName or element changes
}
