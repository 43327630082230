import colors, { styled } from '../../colors';

export const RunsheetDateCircle = styled('span')`
  margin-right: 15px;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: ${colors.primary};
  font-size: 16px;
  border-radius: 50%;
`;
