import React, { useCallback } from 'react';

import { observer } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';

import {
  AppRunsheetFragment,
  AppUserFragment,
  User,
} from '@checkpoints/shared';

import { mediaTablet } from '../../colors';
import { AddButton } from '../../components/button';
import { ErrorLoading } from '../../components/common';
import { DashedContainer } from '../../components/dashed-container';
import { NotPaidEventAlert } from '../../components/notifications/NotPaidEventAlert';
import {
  splitDivStyles,
  useSplitDivStyles,
} from '../../components/split-container';
import MainStore from '../../stores/Store';
import { DashboardRunsheetPopover } from '../../components/popovers';

import { AddModalType, DashboardStore } from './DashboardStore';
import { Header, Header3 } from './DashboardHeader';
import { RunsheetRow, UserRow } from './DashboardRows';
import { Events } from './Events';
import { Tutorial } from './Tutorial';

export const Dashboard = observer(function Dashboard() {
  const events = DashboardStore.store.events;
  const { classes, cx } = useDashboardStyles();
  const { classes: splitDivClasses, cx: splitDivCx } = useSplitDivStyles();

  const error = DashboardStore.store.error;
  const completedFetch = DashboardStore.store.completedFetchEvents;

  if (error || !completedFetch) {
    return <ErrorLoading loading error={error} />;
  }

  return (
    <>
      {events.length !== 0 ? (
        <div className={cx(classes.StyledDashboard, 'fadeInFromAbove')}>
          <Header>{`Dashboard`}</Header>
          <div className={splitDivCx(splitDivClasses.root)}>
            <Events />
            <div className={cx(classes.InnerGrid)}>
              <Runsheets />
              <Users />
            </div>
          </div>
        </div>
      ) : (
        <div className="fadeInFromAbove">
          <Tutorial />
        </div>
      )}
      <NotPaidEventAlert />
    </>
  );
});

const Runsheets = observer(function Runsheets() {
  const sortedActiveRunsheets = DashboardStore.store.sortedActiveRunsheets;
  const isAdminAtCurrentEvent = DashboardStore.store.isAdminAtCurrentEvent;

  const onSettingsClicked = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      runsheet: AppRunsheetFragment,
    ) => {
      DashboardStore.store.activateRunsheetPopover({
        anchorEl: event.target,
        runsheet,
      });
    },
    [],
  );

  const addButton = isAdminAtCurrentEvent && (
    <AddButton
      className="add_button"
      onClick={() => {
        DashboardStore.store.showRunsheetModal(AddModalType.AddRunsheet);
      }}
    />
  );

  return (
    <div className="dashed_list">
      <Header3>{`Runsheets:`}</Header3>
      <DashedContainer directChildren={addButton}>
        {sortedActiveRunsheets.map((runsheet) => (
          <RunsheetRow
            key={runsheet.id}
            runsheet={runsheet}
            onSettingsClicked={isAdminAtCurrentEvent && onSettingsClicked}
          />
        ))}
      </DashedContainer>
      <DashboardRunsheetPopover />
    </div>
  );
});

const onAdminSettingsPressed = (user: User, event: any) => {
  DashboardStore.store.activateUserPopoverSettings({
    anchorEl: event.target,
    user: user,
    isAdmin: true,
  });
};

const onSubscriberSettingsPressed = (user: User, event: any) => {
  DashboardStore.store.activateUserPopoverSettings({
    anchorEl: event.target,
    user: user,
    isAdmin: false,
  });
};

const Users = observer(function Users() {
  const selectedEvent = DashboardStore.store.activeEvent;
  const isAdminAtCurrentEvent = DashboardStore.store.isAdminAtCurrentEvent;

  const admins = DashboardStore.store.admins;
  const subscribers = DashboardStore.store.subscribers;

  const addButtonAdmin = isAdminAtCurrentEvent && (
    <AddButton
      className="add_button"
      onClick={() => {
        DashboardStore.store.showRunsheetModal(AddModalType.Admin);
      }}
    />
  );

  const addButtonSubscriber = isAdminAtCurrentEvent && (
    <AddButton
      className="add_button"
      onClick={() => {
        DashboardStore.store.showRunsheetModal(AddModalType.Subscriber);
      }}
    />
  );

  return (
    <div className="dashed_list">
      <Header3>{`Admins:`}</Header3>
      <DashedContainer full={false} directChildren={addButtonAdmin}>
        {admins.map((admin) => (
          <UserRow
            onSettingsClicked={isAdminAtCurrentEvent && onAdminSettingsPressed}
            key={`${selectedEvent.id}${admin.email}`}
            user={admin as AppUserFragment}
            isOwner={admin.id === selectedEvent.owner.id}
            isViewedByAdmin={isAdminAtCurrentEvent}
          />
        ))}
      </DashedContainer>
      <Header3>{`Subscribers:`}</Header3>
      <DashedContainer full={false} directChildren={addButtonSubscriber}>
        {subscribers.map((subscriber) => (
          <UserRow
            onSettingsClicked={
              // show popover if self or if user is admin
              (subscriber.id === MainStore.store.me.id ||
                isAdminAtCurrentEvent) &&
              onSubscriberSettingsPressed
            }
            key={subscriber.email}
            user={subscriber as AppUserFragment}
            isViewedByAdmin={isAdminAtCurrentEvent}
          />
        ))}
      </DashedContainer>
    </div>
  );
});

const useDashboardStyles = makeStyles()(() => ({
  InnerGrid: {
    ...splitDivStyles,
    '.dashed_list': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    [mediaTablet]: {
      '.dashed_list': {
        height: '500px',
      },
    },
  },
  StyledDashboard: {
    height: 'calc(100vh - 200px)',
    minHeight: '400px',
    [mediaTablet]: {
      height: 'auto',
    },
    '@media (max-height: 600px)': {
      height: 'auto',
    },
    display: 'flex',
    flexDirection: 'column',
    '> div': {
      flex: '1 1 auto',
    },
    '.add_button': {
      position: 'absolute',
      top: '-24px',
      right: '-20px',
      padding: '9px',
    },
  },
}));
