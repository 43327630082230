import React, { useEffect, useRef } from 'react';

import { styled } from '../../colors';

function setListeners(
  div: HTMLElement,
  minimumColumnWidth: number,
  resize: (w: number) => void,
  columnClass: string,
  // resizeNext: (w: number) => void,
) {
  let pageX: number;
  let curCol: HTMLElement;

  // let nxtCol: HTMLElement;
  let curColWidth: number;
  // let nxtColWidth: number;

  function onMouseDown(e: MouseEvent) {
    curCol = (e.target as HTMLElement).parentElement;
    pageX = e.pageX;
    curColWidth = curCol.offsetWidth;
    // nxtCol = curCol.nextElementSibling as HTMLElement;
    // if (nxtCol) nxtColWidth = nxtCol.offsetWidth;
  }

  function onMouseMove(e: MouseEvent) {
    if (curCol) {
      const diffX = e.pageX - pageX;

      // if (nxtCol) {
      //   const updatedNextColumnWidth = nxtColWidth - diffX;
      //   nxtCol.style.width = `${updatedNextColumnWidth}px`;
      // }

      const updatedCurrentColumnWidth = Math.max(
        minimumColumnWidth,
        curColWidth + diffX,
      );
      curCol.style.width = `${updatedCurrentColumnWidth}px`;
      document
        .querySelectorAll(`.${columnClass}`)
        .forEach((e: HTMLDivElement) => {
          e.style.width = `${updatedCurrentColumnWidth}px`;
        });
    }
  }

  function onMouseUp() {
    if (curCol) {
      resize(parseInt(curCol.style.width));
    }
    // if (nxtCol) {
    //   resizeNext(parseInt(nxtCol.style.width));
    // }

    curCol = undefined;
    // nxtCol = undefined;

    pageX = undefined;
    // nxtColWidth = undefined;
    curColWidth = undefined;
  }

  div.addEventListener('mousedown', onMouseDown);
  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp);

  return () => {
    div.removeEventListener('mousedown', onMouseDown);
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };
}

export function Resizer({
  resize,
  minimumColumnWidth,
  columnClass,
}: {
  resize: (w: number) => void;
  minimumColumnWidth: number;
  columnClass: string;
}) {
  // let pageX: number;
  // let curCol: HTMLElement;

  // let nxtCol: HTMLElement;
  // let curColWidth: number;
  const ref = useRef(null);

  useEffect(() => {
    let remove: () => void;

    if (ref.current) {
      remove = setListeners(
        ref.current,
        minimumColumnWidth,
        resize,
        columnClass,
      );
    }

    return () => {
      if (remove) {
        remove();
      }
    };
  }, [resize, columnClass, minimumColumnWidth]);

  return <StyledResizer ref={ref} />;
  // return <StyledResizer onMousedown />;
}

const StyledResizer = styled('div')`
  cursor: col-resize;
  user-select: none;
  position: absolute;
  z-index: 12;
  top: 0;
  right: -10px;
  width: 20px;
  height: 100%;
  pointer-events: auto;
`;
