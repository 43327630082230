//thanks to https://stackoverflow.com/a/29304414 and https://stackoverflow.com/a/49950777
// The download function takes a CSV string, the filename and mimeType as parameters
// Scroll/look down at the bottom of this snippet to see how download is called
export const downloadCSV = function (content: string, fileName: string) {
  // for UTF-16
  let cCode: number;
  const bArr = [];
  bArr.push(255, 254);
  for (let i = 0; i < content.length; ++i) {
    cCode = content.charCodeAt(i);
    bArr.push(cCode & 0xff);
    bArr.push((cCode / 256) >>> 0);
  }

  // eslint-disable-next-line no-undef
  const blob = new Blob([new Uint8Array(bArr)], {
    type: 'text/csv;charset=UTF-16LE;',
  });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');

    if (link.download !== undefined) {
      const url = window.URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  }
};
