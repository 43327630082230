import { ApolloClient } from '@apollo/client';

import {
  UpdateUserRunsheetNoteDocument,
  UpdateCheckpointDocument,
  TableColumn,
  Constants,
} from '@checkpoints/shared';

import { CheckpointsStore } from '../checkpointsStore';
import MainStore from '../Store';

import { refreshCheckpointOrderIds } from './reloadCheckpoints';
const { USER_NOTES_ACCESSOR } = Constants;

export const handleReorder = async (from: number, to: number) => {
  const { checkpoint: affectedCheckpoint, shouldReload } =
    CheckpointsStore.store.reorderCheckpoints(from, to);

  return mutateCheckpoint({
    client: MainStore.store.client,
    checkpointId: affectedCheckpoint.id,
    column: { accessor: 'order' } as any,
    newValue: affectedCheckpoint.order,
  }).finally(() => {
    if (shouldReload) {
      refreshCheckpointOrderIds(affectedCheckpoint.runsheetId);
    }
  });
};

export async function mutateCheckpoint({
  client,
  checkpointId,
  column,
  newValue,
}: {
  client: ApolloClient<any>;
  checkpointId: number;
  column: TableColumn;
  newValue: any;
}) {
  //user note
  if (column.accessor === USER_NOTES_ACCESSOR) {
    await client.mutate({
      mutation: UpdateUserRunsheetNoteDocument,
      variables: {
        runsheetId: CheckpointsStore.store.selectedRunsheetId,
        checkpointId: checkpointId,
        note: newValue,
      },
      fetchPolicy: 'no-cache',
    });
    //TODO: use websockets to do this!
    CheckpointsStore.store.updateUserNote(checkpointId, newValue);

    return;
  }
  const body: any = { id: checkpointId };

  //custom headers
  if (column.isCustomHeader) {
    body.header = column.accessor;
    body.headerValue = newValue;
  } else {
    body[column.accessor] = newValue;
  }

  return await client.mutate({
    mutation: UpdateCheckpointDocument,
    variables: {
      body,
    },
    fetchPolicy: 'no-cache',
  });
}
