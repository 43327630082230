import * as React from 'react';

import Cloud from '@mui/icons-material/CloudUpload';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';

import { styled } from '../../colors';
import { SimpleDashedContainer } from '../dashed-container';
import { convertFileSizeToBytes } from '../../utils/dropUploader';

const UPLOAD_FILESIZE_LIMIT = '3mb';

export interface DropzoneProps {
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void;
  disabled?: boolean;
  className?: string;
}

export function EventImageDropzone({
  onDrop,
  disabled,
  className,
}: DropzoneProps) {
  // const [files, setFiles] = React.useState([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: convertFileSizeToBytes(UPLOAD_FILESIZE_LIMIT),
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
  });

  const { ref, ...rootProps } = getRootProps();
  const inputProps = getInputProps();

  return (
    <Container active={isDragActive} className={className}>
      <SimpleDashedContainer>
        <FlexCenter>
          <DropHolder
            className={disabled ? 'disabled' : ''}
            ref={ref}
            {...rootProps}
            active={isDragActive}
          >
            <input {...inputProps} />
            <Cloud />
            {isDragActive ? (
              <p>Drop your image here...</p>
            ) : (
              <p>Upload an image (Max {UPLOAD_FILESIZE_LIMIT})</p>
            )}
          </DropHolder>
        </FlexCenter>
      </SimpleDashedContainer>
    </Container>
  );
}

type DropHolderProps = {
  active: boolean;
};
const Container = styled('div')<DropHolderProps>`
  .dashed_container {
    padding: 0px;

    svg {
      ${(p) =>
        p.active ? `stroke-dasharray: 20; animation: dash 25s linear;` : ``}
    }
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 1000;
    }
  }
`;

const FlexCenter = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DropHolder = styled('div')<DropHolderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  cursor: pointer;
  /* padding: 20px; */
  width: 100%;
  height: 100%;
  margin: -1px 0 0 -2px;
  min-height: 200px;
  background: ${(props) => (props.active ? 'rgba(0,0,0, 0.4)' : 'initial')};

  &.disabled {
    pointer-events: none;
    background: rgba(0, 0, 0, 0.2);

    color: #ccc;
  }

  svg {
    width: 100px;
    height: auto;
    color: white;
  }
`;
