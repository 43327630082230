import React, { useState } from 'react';

import BurgerIcon from '@mui/icons-material/Menu';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { mediaMobile, styled } from '../../colors';
import { DashboardStore } from '../../pages/Dashboard/DashboardStore';
import MainStore from '../../stores/Store';
import { Logo } from '../server-image';

const StyledLink = styled(Link)(({}) => ({}));

export function Navbar() {
  const [menu, setMenu] = useState(false);

  const handleClick = () => {
    setMenu(false);
    DashboardStore.store.resetPopovers();
  };

  return (
    <NavContainer>
      <StyledLink
        to={'/dashboard'}
        onClick={() => {
          DashboardStore.store.resetPopovers();
        }}
        sx={{ width: '180px' }}
      >
        <Logo variant="white" />
      </StyledLink>

      <Links className={classNames({ open: menu })}>
        <Link to="/dashboard" onClick={handleClick}>
          Dashboard
        </Link>
        <Link to="/dashboard/account" onClick={handleClick}>
          Account
        </Link>
        <a
          href="https://checkpoints.se/docs"
          target="_blank"
          rel="noreferrer"
          onClick={handleClick}
        >
          Help
        </a>
        <Link
          to="/login"
          onClick={() => {
            handleClick();
            MainStore.store.logout();
          }}
        >
          Log out
        </Link>

        <div style={{ height: 20 }}></div>
        <Link
          to={'#'}
          className="lg-hidden"
          onClick={() => {
            setMenu(false);
          }}
          style={{ fontWeight: 700 }}
        >
          Close
        </Link>
      </Links>

      <MenuButton
        onClick={(e) => {
          setMenu(true);
          e.stopPropagation();
          e.preventDefault();

          return false;
        }}
      >
        <BurgerIcon />
      </MenuButton>
    </NavContainer>
  );
}

const MenuButton = styled('button')`
  background: 0;
  border: 0;
  padding: 12px;
  color: white;
  display: none;
  ${mediaMobile} {
    display: block;
  }
`;

const NavContainer = styled('header')`
  display: flex;
  justify-content: space-between;
  img {
    width: 180px;
    height: auto;
  }
  border-bottom: 2px solid white;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const Links = styled('div')`
  display: flex;
  padding: 12px;
  align-items: center;
  a {
    color: white;
    text-decoration: none;
    padding: 0 20px;
    position: relative;
    &:not(:nth-last-of-type(2))::after {
      content: '';
      border-right: 1px solid white;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .lg-hidden {
    display: none;
  }

  ${mediaMobile} {
    .lg-hidden {
      display: block;
    }

    &::before {
      content: 'Menu';
      padding: 10px;
      font-size: 30px;
      border-bottom: 2px solid white;
      margin-bottom: 10px;
    }
    a::after {
      border: 0 !important;
    }
    a {
      padding: 10px 20px;
    }
    z-index: 999;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    background: #454545;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transition-property: opacity, transform;
    transform: translate(0px, -100%);
    &.open {
      opacity: 1;
      transform: translate(0px, 0);
    }
  }
`;
