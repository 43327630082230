import { observable, action, makeObservable } from 'mobx';

export class ModalStore {
  private static _store: ModalStore;

  static get store() {
    if (!this._store) {
      this._store = new ModalStore();
      window['modalStore'] = this._store;
    }

    return this._store;
  }

  constructor() {
    makeObservable(this, {
      addItemModal: observable,
      addItem: action.bound,
      closeAddItemModal: action.bound,
      updateItemModal: observable,
      exisitingItem: observable.shallow,
      updateItem: action,
      closeUpdateItemModal: action.bound,
      confirmPrompt: observable,
      showingConfirmDialog: observable,
      confirm: action,
      submitConfirm: action,
      promptMessage: observable,
      prompt: action,
      submitPrompt: action,
      createEventNoPlan: observable,
      setCreateEventNoPlan: action,
      runsheetNotPaidAlert: observable,
      setRunsheetNotPaidAlert: action,
    });
  }

  addItemModal = false;

  addItem() {
    this.addItemModal = true;
  }

  closeAddItemModal() {
    this.addItemModal = false;
  }

  updateItemModal = false;

  exisitingItem?: any;

  updateItem(item: any) {
    this.updateItemModal = true;
    this.exisitingItem = item;
  }

  closeUpdateItemModal() {
    this.updateItemModal = false;
  }

  confirmResolve: (result: boolean) => void;

  confirmPrompt: string;

  showingConfirmDialog = false;

  confirm(prompt: string) {
    this.confirmPrompt = prompt;
    this.showingConfirmDialog = true;

    return new Promise<boolean>((resolve) => {
      this.confirmResolve = resolve;
    });
  }

  submitConfirm(result: boolean) {
    if (this.confirmResolve) {
      this.confirmResolve(result);
      this.confirmResolve = null;
    }
    this.showingConfirmDialog = false;
  }

  promptResolve: (result: string) => void;

  promptMessage: { header: string; message: string };

  prompt(promptMessage: { header: string; message: string }) {
    this.promptMessage = promptMessage;

    return new Promise<string>((resolve) => {
      this.promptResolve = resolve;
    });
  }

  submitPrompt(result: string) {
    if (this.promptResolve) {
      this.promptResolve(result);
      this.promptMessage = null;
    }
  }

  createEventNoPlan = false;

  setCreateEventNoPlan(show: boolean) {
    this.createEventNoPlan = show;
  }

  runsheetNotPaidAlert = false;

  setRunsheetNotPaidAlert(show: boolean) {
    this.runsheetNotPaidAlert = show;
  }
}
