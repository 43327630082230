import * as React from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';

interface CustomDialogProps extends DialogProps {}

export const CustomDialog = ({ children, ...props }: CustomDialogProps) => {
  return (
    <Dialog
      // maxWidth="xs"
      fullWidth
      aria-labelledby="modal-title"
      {...props}
    >
      {children}
    </Dialog>
  );
};
