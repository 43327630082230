import React, { createContext, useContext } from 'react';

import {
  ConfirmCardPaymentData,
  PaymentIntent,
  Stripe,
  StripeCardElement,
} from '@stripe/stripe-js';
import addDays from 'date-fns/addDays';
import { action, computed, makeObservable, observable } from 'mobx';

import {
  AppUserFragment,
  formatDate,
  InitializePaymentDocument,
  InitializePaymentMutation,
  InitializePaymentMutationVariables,
  Product,
} from '@checkpoints/shared';

import MainStore from '../../stores/Store';

export const currencyFormatter = new Intl.NumberFormat('sv-SE', {
  currencyDisplay: 'code',
  style: 'currency',
  currency: 'SEK',
});

export const Admin30DayPlan = {
  id: Product.Month,
  price: 199,
  vat: 49.75,
  total: 248.75,
  daysAdded: 30,
  title: 'Admin – 199 SEK/month',
};

export interface PaymentUser {
  company: string;
  name: string;
  email: string;
  address: string;
  city: string;
  zip: string;
}

export class BillingStore {
  mainStore: MainStore;

  constructor() {
    this.mainStore = MainStore.store;

    makeObservable(this, {
      setStripe: action,
      setError: action,
      showingPaymentModal: observable,
      setShowingPaymentModal: action,
      loading: observable,
      error: observable,
      success: observable,
      quantity: observable,
      incrementQuanity: action,
      decrementQuanity: action,
      currentPlanDescription: computed,
      daysAdded: computed,
      newPlanExpires: computed,
      paymentUpdated: action,
      handleClose: action,
      createPayment: action,
    });
  }

  stripe: Stripe;
  timeoutTimer: NodeJS.Timeout;
  paymentIntent: PaymentIntent;

  setStripe(stripe: Stripe) {
    this.stripe = stripe;
  }

  setError(error: string) {
    this.error = error;
    if (error) {
      this.loading = false;
    }
  }

  showingPaymentModal = false;

  setShowingPaymentModal(show: boolean) {
    if (show) {
      this.success = false;
      this.loading = false;
      this.error = null;
      this.quantity = 1;
    }

    this.showingPaymentModal = show;
  }

  loading: boolean;
  error: string;
  success: boolean;

  quantity = 1;

  incrementQuanity = () => {
    this.quantity += 1;
  };

  decrementQuanity = () => {
    if (this.quantity <= 1) return;
    this.quantity -= 1;
  };

  get currentPlanDescription() {
    const quantity = this.quantity;
    const totalPriceWithoutVat = Admin30DayPlan.price * quantity;
    const vat = totalPriceWithoutVat * 0.25;
    const totalPriceWithVat = totalPriceWithoutVat + vat;

    return {
      price: currencyFormatter.format(totalPriceWithoutVat),
      vat: currencyFormatter.format(vat),
      total: currencyFormatter.format(totalPriceWithVat),
    };
  }

  get daysAdded() {
    return this.quantity * Admin30DayPlan.daysAdded;
  }

  get newPlanExpires() {
    let planExpires = new Date();
    if (this.mainStore.hasActivePaidAccount) {
      planExpires = new Date(Number(this.mainStore.me.paymentExpires));
    }
    const newExpiry = addDays(planExpires, this.daysAdded);

    return formatDate(newExpiry, 'yyyy-MM-dd');
  }

  //Called via websocket
  paymentUpdated = (user: AppUserFragment) => {
    this.mainStore.setMe(user);
    this.success = true;
    clearTimeout(this.timeoutTimer);

    // Ensure orders is refetched
    this.mainStore.client.refetchQueries({
      include: ['Orders'],
    });
  };

  handleClose = () => {
    clearTimeout(this.timeoutTimer);
    this.success = false;
    this.loading = false;
    this.error = null;

    this.setShowingPaymentModal(false);
  };

  createPayment = async ({
    user,
    card,
  }: {
    user: PaymentUser;
    card: StripeCardElement;
  }) => {
    this.loading = true;
    this.error = null;

    const client = this.mainStore.client;

    const result = await client.mutate<
      InitializePaymentMutation,
      InitializePaymentMutationVariables
    >({
      mutation: InitializePaymentDocument,
      variables: {
        quantity: this.quantity,
        product: Admin30DayPlan.id,
      },
    });

    const paymentClientSecret = result.data.initializePayment;

    const paymentDetails: ConfirmCardPaymentData = {
      // setup_future_usage: 'off_session',
      // receipt_email: user.email,
      payment_method: {
        card: card,
        billing_details: {
          address: {
            line1: user.address,
            //   line2,
            city: user.city,
            postal_code: user.zip,
          },
          name: user.name,
          email: user.email,
        },
        metadata: {
          company: user.company,
        },
      },
    };

    const response = await this.stripe.confirmCardPayment(
      paymentClientSecret,
      paymentDetails,
    );

    const { paymentIntent, error } = response;

    if (error) {
      this.setError(error.message);

      return;
    }

    this.paymentIntent = paymentIntent;

    if (paymentIntent.status === 'canceled') {
      this.setError(`The payment was canceled.`);

      return;
    } else if (
      paymentIntent.status === 'processing' ||
      paymentIntent.status === 'succeeded' ||
      paymentIntent.status === 'requires_action'
    ) {
      this.timeoutTimer = setTimeout(() => {
        this.setError(
          `We did not receive a response from our payment provider in time. 
          If you have any questions, please contact support.`,
        );
      }, 30 * 1000);
    } else {
      this.setError(
        `Unknown error occured. If you have any questions, please contact support.`,
      );

      return;
    }
  };
}

const billingStore = new BillingStore();

// @ts-expect-error debug purposes
window.billingStore = billingStore;

const BillingContext = createContext<BillingStore>(billingStore);

export const BillingStoreProvider = ({ children }) => (
  <BillingContext.Provider value={billingStore}>
    {children}
  </BillingContext.Provider>
);

export const useBillingStore = () => {
  return useContext(BillingContext);
};
