import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet-async';
import { runInAction } from 'mobx';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WatchLater from '@mui/icons-material/WatchLater';

import { useRunsheetQuery } from '@checkpoints/shared';

import colors, { mediaTablet, styled } from '../../colors';
import { DirectorSwitch, OnlineUserList } from '../../components/avatar';
import {
  ExpandMoreIcon,
  GpsFixedIcon,
  PlainButton,
} from '../../components/common';
import { RunsheetPopover } from '../../components/popovers';
import { UserOverlay } from '../../components/user-overlay';
import { CheckpointsStore } from '../../stores/checkpointsStore';
import { ModalStore } from '../../stores/ModalStore';
import { GrayDivider } from '../../components/popovers/PopoverUtils';
import { Logo } from '../../components/server-image';
import { RunsheetNotificationPopover } from '../../components/popovers/RunsheetNotificationPopover';

import { EditModeSwitch } from './EditModeSwitch';

export function useRunsheetData(selectedRunsheetId: number) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, loading } = useRunsheetQuery({
    variables: { id: selectedRunsheetId },
    skip: !selectedRunsheetId,
  });

  useEffect(() => {
    //Below we set some properties on our mobx store on render;
    if (!data) {
      return;
    }
    const { runsheet = null } = data || {};
    const { director, event } = runsheet;

    runInAction(() => {
      // Note: these may be old stale values due to cahce
      CheckpointsStore.store.setDirector(director);
      CheckpointsStore.store.setSelectedEvent(event);
    });
  }, [data]);

  const { runsheet = null } = data || {};

  return { runsheet, loading };
}

export const RunsheetHeader = observer(function RunsheetHeader() {
  const selectedRunsheetId = CheckpointsStore.store.selectedRunsheetId;
  const editButtonRef = useRef(null);
  const { runsheet } = useRunsheetData(selectedRunsheetId);
  const isAdmin = CheckpointsStore.store.isAdmin;
  const isPro = runsheet?.event?.plan === 'PRO';

  useEffect(() => {
    if (!runsheet) {
      return;
    }

    if (runsheet.event.plan != 'CORE' && runsheet.event.plan != 'PRO') {
      ModalStore.store.setRunsheetNotPaidAlert(true);
    }

    return () => {
      // ensure we close this error modal every time the runsheet changes
      ModalStore.store.setRunsheetNotPaidAlert(false);
    };
  }, [runsheet]);

  useEffect(() => {
    return () => {
      CheckpointsStore.store.toggleRunsheetEditModal(false);
    };
  }, []);

  return (
    <>
      <Helmet title={runsheet ? runsheet.title : ''} />
      <StyledRunsheetHeader className="fadeIn">
        <HeaderSettingsDiv className="logoLink">
          <Link to="/dashboard">
            <Logo />
          </Link>
          <GrayDivider variant="subtle" padded />
          <TrackingDiv>
            <GpsFixedIcon />
            <span>Tracking</span>
            <DirectorSwitch />
          </TrackingDiv>
          <GrayDivider variant="subtle" padded />
          {isAdmin && isPro && (
            <RunsheetNotificationPopover runsheet={runsheet} />
          )}
        </HeaderSettingsDiv>
        <TitleDiv>
          <PlainButton
            ref={editButtonRef}
            onClick={() => {
              CheckpointsStore.store.toggleRunsheetEditModal();
            }}
          >
            {runsheet && (
              <TitleSpan>{`${runsheet.title}${
                runsheet.event.readOnly ? ' (Read-only)' : ''
              }`}</TitleSpan>
            )}
            <ExpandMoreIcon />
          </PlainButton>
        </TitleDiv>
        <HeaderSettingsDiv>
          <RunsheetTime />
          {runsheet && <OnlineUserList userList={runsheet.subscribers} />}
          <EditModeSwitch />
        </HeaderSettingsDiv>
      </StyledRunsheetHeader>
      <RunsheetPopover runsheet={runsheet} anchorEl={editButtonRef} />
      <UserOverlay />
    </>
  );
});

const RunsheetTime = () => {
  const [time, setTime] = useState<Date>(null);

  useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      // console.log('set time');

      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);

  return (
    <Box style={{ display: 'flex' }}>
      <WatchLater
        sx={(theme) => ({
          color: theme.palette.colors.dimText,
          height: '25px',
          width: '25px',
          marginRight: '4px',
        })}
        aria-label="Time"
      />
      <Typography
        component="span"
        sx={(theme) => ({
          color: theme.palette.colors.dimText,
          fontWeight: 600,
        })}
      >
        {time &&
          time.toLocaleTimeString('sv-se', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          })}
      </Typography>
    </Box>
  );
};

const StyledRunsheetHeader = styled('header')`
  padding: 0 10px;
  position: relative;
  background-color: ${colors.nav};
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mediaTablet} {
    flex-direction: column;
    height: auto;
    > div {
      height: 40px;
      position: relative;
      padding-right: 0;
      left: 0;
      transform: none;
    }
  }
  a {
    padding: 0 10px;
    img {
      display: block;
    }
  }
`;

const TitleSpan = styled('span')`
  margin-left: 5px;
`;

const TitleDiv = styled('div')`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;

  color: #d8d8d8;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    padding: 4px;
    color: inherit;
  }
`;

const HeaderSettingsDiv = styled('div')`
  overflow: hidden;

  ${mediaTablet} {
    label {
      margin-right: 0;
    }
  }

  color: ${colors.gray};
  padding-right: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  button {
    padding: 4px;
    color: inherit;
  }
`;

const TrackingDiv = styled('div')`
  display: flex;
  align-items: center;

  ${mediaTablet} {
    display: none;
    /* TODO: fix so ipads can change tracking */
  }

  color: ${colors.gray};
  font-size: 18px;
  > * {
    margin: 0 4px;
  }
`;
