import React from 'react';

import { AnimateSharedLayout, LayoutGroup, motion } from 'framer-motion';
import { observer } from 'mobx-react';

import { AppUserFragment, getDisplayedUsers } from '@checkpoints/shared';

import { styled } from '../../colors';
import { CheckpointsStore } from '../../stores/checkpointsStore';
import MainStore from '../../stores/Store';

import { OnlineAvatar, ShowMoreUsersAvatar } from './OnlineAvatar';

const spring = {
  type: 'spring',
  damping: 20,
  stiffness: 300,
};

export const OnlineUserList = observer(function OnlineUserList({
  userList,
}: {
  userList: AppUserFragment[];
}) {
  const me = MainStore.store.me;
  const activeUserList = [...CheckpointsStore.store.onlineUsersForRunsheet];
  if (!me) return;
  const { displayedUsers, hiddenUsers } = getDisplayedUsers({
    activeUserList,
    userList,
    me,
  });
  const moreUsersCount = hiddenUsers.length;

  // = React.useMemo(
  //   () => getDisplayedUsers(activeUserList, userList, me),
  //   [activeUserList, userList, me],
  // );
  return (
    <LayoutGroup>
      <StyledUserListContainer layout>
        {moreUsersCount > 0 && (
          <ShowMoreUsersAvatar
            title={`+${moreUsersCount}`}
            users={hiddenUsers}
          />
        )}
        {displayedUsers.reverse().map((user) => (
          <motion.div layout transition={spring} key={user.id}>
            <OnlineAvatar user={user} />
          </motion.div>
        ))}
      </StyledUserListContainer>
    </LayoutGroup>
  );
});

const StyledUserListContainer = styled(motion.div)`
  display: flex;
  align-items: center;

  /* reversed for correct z index */
  flex-direction: row-reverse;
  padding-left: 20px;
`;
