import React, { ReactNode } from 'react';

import { observer } from 'mobx-react';

import { AppUserFragment } from '@checkpoints/shared';

import { styled } from '../../colors';

import { UserContent } from './Avatar';
import { DashboardUserRowRing } from './DasboardAvatar';

//Large profile image on account page;
export const ProfileAvatar = observer(function ProfileAvatar({
  user,
  children,
}: {
  user: AppUserFragment;
  children?: ReactNode;
}) {
  if (!user) return null;

  return (
    <ProfileRing className="ring">
      <UserContent user={user} />
      {children}
    </ProfileRing>
  );
});

export const ProfileRing = styled(DashboardUserRowRing)`
  width: 120px;
  height: 120px;
  margin: 0 auto;
  font-size: 28px;
  overflow: hidden;
`;
