import React, { ReactNode } from 'react';

import { observer } from 'mobx-react';

import colors, { mediaTablet, styled } from '../../colors';
import { CheckpointsStore } from '../../stores/checkpointsStore';

export const RunsheetContainer = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
}));

export const BackgroundContainer = observer(
  ({ children }: { children: ReactNode }) => {
    const editMode = CheckpointsStore.store.editMode;

    return (
      <Background className={editMode ? 'editMode' : undefined}>
        {children}
      </Background>
    );
  },
);

const Background = styled('div')`
  flex: 1 1 auto;
  padding-top: 60px;

  ${mediaTablet} {
    padding-top: 0;
  }

  background-image: linear-gradient(
    ${colors.backgroundFirst},
    ${colors.backgroundSecond}
  );

  &.editMode {
    background-image: linear-gradient(
      ${colors.editModeBackgroundFirst},
      ${colors.editModeBackgroundSecond}
    );
  }
`;
