import React from 'react';

import useSt8 from 'use-st8';
import { ApolloError } from '@apollo/client';
import { useNavigate } from 'react-router';

import {
  getFullName,
  useAllUsersQuery,
  useLoginMutation,
  useForceUserLogoutMutation,
} from '@checkpoints/shared';

import { styled } from '../../colors';
import { DeleteButton, WhiteOutlinedButton } from '../../components/button';
import { SpacerAuto } from '../../components/common';
import { useMainStore } from '../../stores';
import { ModalStore } from '../../stores/ModalStore';
import MainStore from '../../stores/Store';

import { ChangeExpiryModal } from './ChangeExpiryModal';

export function SuperAdmin() {
  const { data } = useAllUsersQuery({ variables: {} });
  const modalUser = useSt8<any>(null);
  const store = useMainStore();
  const [login] = useLoginMutation();
  const nav = useNavigate();

  const handlePeek = async (user) => {
    login({
      variables: {
        username: user.email,
        password: '_peek_',
        peek: true,
      },
    })
      .then(() => MainStore.store.getMe('network-only'))
      .then(() => {
        nav('/dashboard');
      })
      .catch((e: ApolloError) => {
        let errorMessage = e.message;
        let code = '';
        if (e.graphQLErrors.length) {
          const graphqlError = e.graphQLErrors[0];
          errorMessage = graphqlError.message;
          code =
            graphqlError.extensions && (graphqlError.extensions.code as string);
        }
        MainStore.store.displayError(errorMessage);
      });
  };

  const [forceLogout] = useForceUserLogoutMutation();

  const handleForceLogout = async (user) => {
    const result = await ModalStore.store.confirm(
      'Are you sure you want to log this user out of all their sessions?',
    );

    if (result) {
      await forceLogout({
        variables: {
          userId: user.id,
        },
      });

      if (user.id === store.me.id) {
        await store.getMe();
      }
      store.displayNotification({
        message: 'User logged out of all sessions!',
      });
    }
  };

  if (!data) {
    return null;
  }

  return (
    <>
      {(data.allUsers || []).map((user) => (
        <StyledUser key={user.id}>
          <StyledUserTitle>
            <div style={{ fontWeight: 'bold' }}>{user.company}</div>
            <div style={{ fontStyle: 'italic' }}>
              {getFullName(user) || user.email}
            </div>
          </StyledUserTitle>

          <SpacerAuto />
          <div>{user.email}</div>
          <SpacerAuto />
          <div>
            {user.paymentExpires
              ? ' Expires at: ' +
                store.formatPaymentExpires(user.paymentExpires)
              : 'No plan'}
          </div>
          <SpacerAuto />

          <div>
            <WhiteOutlinedButton
              onClick={() => {
                modalUser(user);
              }}
            >
              Update payment expiry
            </WhiteOutlinedButton>
            <WhiteOutlinedButton
              onClick={() => {
                handlePeek(user);
              }}
            >
              Log in as user
            </WhiteOutlinedButton>

            <DeleteButton
              onClick={() => {
                handleForceLogout(user);
              }}
            >
              Force log out
            </DeleteButton>
          </div>
        </StyledUser>
      ))}
      <ChangeExpiryModal
        onClose={() => {
          modalUser(null);
        }}
        user={modalUser()}
      />
    </>
  );
}

const StyledUser = styled('div')`
  padding: 10px;
  display: flex;
`;

const StyledUserTitle = styled('span')`
  font-size: 18px;
  color: white;
  margin-right: 20px;
`;
