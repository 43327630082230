import * as React from 'react';

import { Route, Routes } from 'react-router-dom';

import { ForgotPassword } from './pages/Account/ForgotPassword';
import { Login } from './pages/Account/Login';
import { Signup } from './pages/Account/Signup';
import { AuthRoute } from './pages/Main';
import { Verify } from './pages/Account/Verify';
import { WelcomeScreen } from './pages/Account/WelcomeScreen';

const Page404 = () => (
  <div className="main-container">
    <h1>404</h1>
    <p>Sidan hittades inte.</p>
  </div>
);

export default function Router() {
  return (
    <Routes>
      <Route path="/dashboard/*" element={<AuthRoute />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/welcome" element={<WelcomeScreen />} />
      <Route path="/reset_password" element={<ForgotPassword />} />
      {/* TODO: Replace redirect logic? */}
      {/* <Redirect path="/" from="/" to="/dashboard" noThrow /> */}
      <Route path="*" element={<AuthRoute />} />
      {/* <Route path="*" element={<Page404 />} /> */}
    </Routes>
  );
}
