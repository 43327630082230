import { useRef, useEffect } from 'react';

export function useLatestValue<T>(val: T) {
  const latestPersonRef = useRef<T>();

  useEffect(() => {
    if (val) {
      latestPersonRef.current = val;
    }
  }, [val]);

  return val || latestPersonRef.current;
}
