import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { decodeInteger } from '@checkpoints/shared';

import { CheckpointsTable } from '../../components/checkpoints-table';
import { Minimap } from '../../components/minimap';
import { NotPaidRunsheetAlert } from '../../components/notifications/NotPaidRunsheetAlert';
import { ColumnSettingsPopover } from '../../components/popovers';
import { CheckpointsStore } from '../../stores/checkpointsStore';

import { ActiveUserListener } from './ActiveUserListener';
import { CheckpointsListener } from './CheckpointsListener';
import { RunsheetUpdateListener } from './Listeners';
import { RunsheetHeader } from './RunsheetHeader';
import { BackgroundContainer, RunsheetContainer } from './RunsheetLayout';
import { NotificationListener } from './NotificationListener';

export const Runsheet = observer(function Runsheet() {
  const { selectedRunsheetId } = useParams();

  const RunsheetId = decodeInteger(selectedRunsheetId);

  useEffect(() => {
    if (CheckpointsStore.store.selectedRunsheetId !== RunsheetId) {
      CheckpointsStore.store.setSelectedRunsheetId(RunsheetId);
    }
  }, [RunsheetId]);

  useEffect(() => {
    CheckpointsListener.store.startListening(RunsheetId);
    ActiveUserListener.store.startListening(RunsheetId);
    NotificationListener.store.startListening(RunsheetId);

    return () => {
      CheckpointsListener.store.stopListening();
      ActiveUserListener.store.stopListening();
      NotificationListener.store.stopListening();
    };
  }, [RunsheetId]);

  if (!CheckpointsStore.store.selectedRunsheetId) {
    return null;
  }

  return (
    <RunsheetContainer>
      <RunsheetHeader />
      <BackgroundContainer>
        <Checkpoints />
        <RunsheetUpdateListener />
      </BackgroundContainer>
    </RunsheetContainer>
  );
});

const Checkpoints = observer(function Checkpoints() {
  return (
    <>
      <CheckpointsTable />
      <Minimap />
      <ColumnSettingsPopover />
      <NotPaidRunsheetAlert />
    </>
  );
});
