import React, { forwardRef } from 'react';

import { ListItem, ListItemButton, ListItemButtonProps } from '@mui/material';
import {
  ArrowDropDown,
  ArrowLeft,
  ArrowRight,
  ArrowUpward,
} from '@mui/icons-material';

import { styled } from '../../colors';

export const InnerPopover = styled('div')<{ noPadding?: boolean }>`
  padding: ${(p) => (p.noPadding ? '6px 2px' : '6px 10px')};
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #111;
`;

export const GrayDivider = styled('div')<{
  padded?: boolean;
  variant?: 'normal' | 'subtle';
}>`
  height: 28px;
  border: 1px solid
    ${(p) => (p.variant && p.variant === 'subtle' ? '#666666' : '#eee')};

  /* &:after {
    content: '';
    width: 1px;
    background: #eee;
  } */
  margin: ${(p) => (p.padded ? '0 4px' : '0')};
`;

export const InnerPopoverVertical = styled('div')<{ noPadding?: boolean }>(
  (p) => {
    return {
      padding: `${p.noPadding ? '6px 2px' : '6px 10px'}`,
      display: 'flex',
      flexDirection: 'column',
      fontSize: '1rem',
      color: '#111',
      [`& ${GrayDivider}`]: {
        width: '100%',
        height: '1px',
      },
    };
  },
);

export const PaddedDiv = styled('div')`
  padding-right: 8px;
`;

export const ArrowedButton = forwardRef<
  HTMLDivElement,
  ListItemButtonProps & {
    arrowDirection?: 'up' | 'right' | 'down' | 'left';
  }
>(({ arrowDirection = 'right', children, ...props }, ref) => {
  const Arrow =
    arrowDirection === 'up' ? (
      <ArrowUpward />
    ) : arrowDirection === 'right' ? (
      <ArrowRight />
    ) : arrowDirection === 'down' ? (
      <ArrowDropDown />
    ) : arrowDirection === 'left' ? (
      <ArrowLeft />
    ) : undefined;

  return (
    <ListItem secondaryAction={Arrow} disablePadding>
      <ListItemButton {...props} ref={ref}>
        {children}
      </ListItemButton>
    </ListItem>
  );
});

/* styled(CapitalizedButton)`
  width: 130px;
  justify-content: space-between;
  text-transform: capitalize;
  text-align: left;
`; */

export const MoveDropDown = styled('div')`
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;

  background: white;
  padding: 4px;

  max-height: 300px;
  overflow: auto;
`;
