import React from 'react';

import Switch from '@mui/material/Switch';
import { observer } from 'mobx-react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProvided,
  DropResult,
} from '@hello-pangea/dnd';

import { Constants, DraggableProps, TableColumn } from '@checkpoints/shared';

import { styled } from '../../colors';
import { Handle } from '../table-cells';
import {
  useReorderUserHeader,
  useUpdateUserHeader,
} from '../../stores/helpers';
import { CheckpointsStore } from '../../stores/checkpointsStore';
import MainStore from '../../stores/Store';

const { USER_NOTES_ACCESSOR } = Constants;

const StyledColumnList = styled('div')`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: 60vh;
`;

export const ColumnList = observer(function ColumnList() {
  const reorder = useReorderUserHeader();

  const handleDragEnd = (result: DropResult) => {
    // setDragging(false);
    // dropped outside the list or same pos
    if (!result.destination) return;
    const from = result.source.index;
    const to = result.destination.index;
    if (to === from) return;
    reorder(from, to);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="userColumnList">
        {(droppableProvided: DroppableProvided) => (
          <StyledColumnList
            className="scrollbar"
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
          >
            <DraggableList />
            {droppableProvided.placeholder}
          </StyledColumnList>
        )}
      </Droppable>
    </DragDropContext>
  );
});

const DraggableList = observer(function DraggableList({}) {
  const columns = CheckpointsStore.store.sortedHeaders;
  //Update the user header information.
  const update = useUpdateUserHeader();

  return (
    <>
      {columns.map((column, idx) => {
        return (
          <Draggable
            draggableId={String(column.accessor)}
            index={idx}
            key={column.accessor}
          >
            {(provided, snapshot) => {
              const { dragHandleProps, draggableProps, innerRef } = provided;
              const { isDragging } = snapshot;

              return (
                <DraggableColumn
                  {...{
                    column,
                    update,
                    dragHandleProps,
                    draggableProps,
                    innerRef,
                    isDragging,
                  }}
                />
              );
            }}
          </Draggable>
        );
      })}
    </>
  );
});

const DraggableColumn = observer(function DraggableColumn({
  column,
  update,
  ...draggable
}: {
  column: TableColumn;
  update: (column: TableColumn, updates: any) => void;
} & DraggableProps) {
  let columnTitle = column.title;
  if (column.accessor === USER_NOTES_ACCESSOR) {
    columnTitle = MainStore.store.meInitials;
  }

  return (
    <StyledDraggableColumn
      ref={draggable.innerRef}
      isDragging={draggable.isDragging}
      {...draggable.draggableProps}
    >
      <Handle dragHandleProps={draggable.dragHandleProps} />
      <Label>{columnTitle}</Label>
      <Switch
        color="primary"
        checked={!!column.active}
        onChange={(e) => {
          update(column, { active: e.target.checked });
        }}
      />
    </StyledDraggableColumn>
  );
});

const StyledDraggableColumn = styled('div')<{ isDragging?: boolean }>`
  display: flex;
  align-items: center;
  ${(p) => (p.isDragging ? `background: rgba(0,0,0,.2);` : '')};

  .handle {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
`;

const Label = styled('span')`
  flex: 1 1 auto;
`;
