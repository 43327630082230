import React from 'react';

import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';

import { AppEventFragment } from '@checkpoints/shared';

import { mediaMobile, styled } from '../../colors';
import { SettingsIcon } from '../../components/common';
import { ServerImage } from '../../components/server-image';
import { TooltipWrapper } from '../../components/tooltip';

export const StyledEvent = styled('div')`
  width: 100%;
  overflow: visible;
  > div {
    height: 0px;
    width: 100%;
    /* Force 3:1 aspect ratio */
    padding-bottom: 33.3333%;
    position: relative;
    overflow: visible;

    .eventButton {
      color: white;
      background-color: transparent;
      padding: 0;
      margin: 0;
      border: 0;
      overflow: hidden;
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out;
      cursor: pointer;
      &:disabled {
        cursor: auto;
      }
      &:focus {
        opacity: 0.8;
        outline: 0;
      }
      &.active {
        opacity: 1;
      }
      border-radius: 3px;
      margin: 0;
    }
    .iconButton {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      color: white;
      padding: 8px;
      /* background: rgba(0, 0, 0, 0.2); */
    }
  }

  img {
    /* cursor: pointer; */
    object-fit: cover;
    /* margin: 15px 0; */
    /* width: 100%;
    height: 100%; */
    background: #ddd;
    /* object-fit: cover; */
  }
`;

export const ImgPlaceholder = styled('div')`
  border: 2px solid white;
  /* cursor: pointer; */

  font-size: 22px;
  ${mediaMobile} {
    font-size: 12px;
  }
  font-weight: 100;
  position: relative;

  > span {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
`;

export function StyledEventCell({
  event,
  onSelected,
  onSettingsPressed,
  clickDisabled,
  isActiveEvent,
  isAdminAtEvent,
}: {
  event: AppEventFragment;
  onSelected?: (id: number) => void;
  onSettingsPressed?: (id: number) => void;
  clickDisabled?: boolean;
  isActiveEvent?: boolean;
  isAdminAtEvent?: boolean;
}) {
  return (
    <StyledEvent>
      <div className="relativeForcedAspect">
        <button
          disabled={!clickDisabled}
          className={classNames({
            eventButton: true,
            'absolute-fill': true,
            active: clickDisabled ? isActiveEvent : true,
          })}
          type="button"
          onFocus={noop}
          onClick={() => {
            if (onSelected) {
              onSelected(event.id);
            }
          }}
        >
          {event.image ? (
            <TooltipWrapper title={event.title} placement="bottom">
              <ServerImage src={event.image.thumbnail} />
            </TooltipWrapper>
          ) : (
            <ImgPlaceholder
              className={classNames({
                'absolute-fill': true,
                'radial-cutout': isActiveEvent && isAdminAtEvent,
              })}
            >
              <span>{event.title}</span>
            </ImgPlaceholder>
          )}
        </button>
        {isActiveEvent && isAdminAtEvent && (
          <IconButton
            className="iconButton fadeIn"
            onClick={() => {
              onSettingsPressed(event.id);
            }}
            size="large"
          >
            <SettingsIcon />
          </IconButton>
        )}
      </div>
    </StyledEvent>
  );
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() {}
