import { FetchResult } from '@apollo/client';
import { runInAction } from 'mobx';

import {
  MutationType,
  NotificationsDocument,
  NotificationsQuery,
  OnActiveUserListChangedSubscription,
  OnNewNotificationDocument,
  OnNewNotificationSubscription,
} from '@checkpoints/shared';

import { CheckpointsStore } from '../../stores/checkpointsStore';
import MainStore from '../../stores/Store';

/**

 */
export class NotificationListener {
  private static _store: NotificationListener = null;
  static get store() {
    if (!this._store) {
      this._store = new NotificationListener();
    }

    return this._store;
  }

  listener: ZenObservable.Subscription;

  fetchNotifications = (runsheetId: number) => {
    MainStore.store.client
      .query<NotificationsQuery>({
        query: NotificationsDocument,
        variables: {
          runsheetId: runsheetId,
        },
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        if (data && data.notifications) {
          console.log('fetchNotifications', data.notifications);
          CheckpointsStore.store.setNotifications(data.notifications);
        }
      });
  };

  startListening = (runsheetId: number) => {
    if (!runsheetId) {
      return;
    }

    this.fetchNotifications(runsheetId);

    const client = MainStore.store.client;

    this.listener = client
      .subscribe({
        query: OnNewNotificationDocument,
        variables: {
          runsheetId: runsheetId,
        },
      })
      .subscribe(this.onNotificationsChanged);
  };

  onNotificationsChanged = (
    result: FetchResult<OnNewNotificationSubscription>,
  ) => {
    const data = result.data.notificationsUpdated;

    runInAction(() => {
      CheckpointsStore.store.setNotifications(data);
    });
  };

  stopListening = () => {
    if (this.listener) {
      this.listener.unsubscribe();
      this.listener = null;
    }
  };
}
