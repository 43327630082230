import { serverUrl } from '../config';

export const UPLOAD_URL = serverUrl + '/library/upload';

export const dropUploader = async (
  files: any[],
  crop: any,
): Promise<{ id: number }[]> => {
  const body = new FormData();
  const file = files[0];
  body.append('images', file, file.name);
  body.append('crop', JSON.stringify(crop));

  const response = await fetch(UPLOAD_URL, {
    method: 'POST',
    credentials: 'include',
    body,
  });
  const result = await response.json();
  console.log('result', result);

  return result;
};

export const convertFileSizeToBytes = (fileSize: string) => {
  const regex = /^(\d+(\.\d+)?)\s*(kb|kb)$/i;
  const match = fileSize.toLowerCase().match(regex);

  if (match) {
    const size = parseFloat(match[1]);
    const unit = match[3].toLowerCase();
    const multiplier = unit === 'kb' ? 1000 : 1000000;

    return Math.round(size * multiplier);
  }

  return null;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${
    sizes[i]
  }`.toLowerCase();
};
