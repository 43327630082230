import React from 'react';

import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { encodeInteger } from '@checkpoints/shared';

import { styled } from '../../colors';
import { AddCircleIcon, FlexColumn } from '../../components/common';
import { ModalStore } from '../../stores/ModalStore';
import MainStore from '../../stores/Store';

import { DashboardStore } from './DashboardStore';
import { Header3 } from './DashboardHeader';
import { StyledEvent, StyledEventCell } from './StyledEventCell';

export const Events = observer(function Events() {
  const navigate = useNavigate();

  const activeEventId = DashboardStore.store.activeEventId;
  const isAdminAtCurrentEvent = DashboardStore.store.isAdminAtCurrentEvent;
  const events = DashboardStore.store.sortedEvents;

  const onSelected = (id: number) => {
    DashboardStore.store.setActiveEventId(id);
  };

  const onSettingsPressed = (id: number) => {
    navigate(`/dashboard/edit/${encodeInteger(id)}`);
  };

  return (
    <FlexColumn>
      <Header3>{`Events:`}</Header3>
      <EventsContainer className="scrollbar">
        {events.map((event) => {
          const isActiveEvent = event.id === activeEventId;
          const isAdminAtEvent = isActiveEvent && isAdminAtCurrentEvent;

          return (
            <StyledEventCell
              key={event.id}
              event={event as any}
              onSettingsPressed={onSettingsPressed}
              onSelected={onSelected}
              clickDisabled={true}
              isActiveEvent={isActiveEvent}
              isAdminAtEvent={isAdminAtEvent}
            />
          );
        })}
        <StyledAddNewEvent>
          <div>
            <div className="absolute-fill">
              <IconButton
                component={Link}
                to="/dashboard/create"
                onClick={(e) => {
                  if (!MainStore.store.hasActivePaidAccount) {
                    ModalStore.store.setCreateEventNoPlan(true);
                    e.preventDefault();
                    e.stopPropagation();

                    return;
                  }
                  // Ensure we clear any active event when we create a new event
                  DashboardStore.store.setActiveEventId(null);
                }}
                size="large"
              >
                <AddCircleIcon />
              </IconButton>
            </div>
          </div>
        </StyledAddNewEvent>
      </EventsContainer>
    </FlexColumn>
  );
});

export const EventsContainer = styled('div')`
  /* display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 15px; */
  max-height: calc(100vh - 360px);
  @media screen and (max-height: 600px) {
    max-height: none;
  }
  /* flex: 1; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: wrap;
  margin: 0px -20px;
  > div {
    /* This doesnt work with the settings icon */
    /* &:nth-of-type(-n + 2) {
      padding-top: 0;
    } */
    padding: 20px;
    width: 50%;
    overflow: hidden;
  }
`;

const StyledAddNewEvent = styled(StyledEvent)`
  a {
    text-decoration: none;
    color: white;
    padding: 4px;
    svg {
      width: 40px;
      height: 40px;
    }

    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
`;
