import React, { useCallback, useEffect, useRef, useState } from 'react';

import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as LogoSvg } from '../../assets/logo.svg';

export const Logo = ({
  variant = 'default',
}: {
  variant?: 'default' | 'white';
}) => {
  return (
    <SvgIcon
      component={LogoSvg}
      inheritViewBox
      sx={{
        width: '100%',
        height: '100%',
        verticalAlign: 'middle',
        fill: '#fff',
        ['& path.bar']: {
          fill: variant === 'default' && '#4376c5',
        },
      }}
    />
  );
};

export function useAnimateLogoV2() {
  const paths = useRef<HTMLDivElement[]>([]);
  const currentTimeOut = useRef<any>();
  const [current, setCurrent] = useState(-1);
  const [done, setDone] = useState(false);

  useEffect(() => {
    paths.current = document.querySelectorAll('#logo path.bar') as any;

    return () => {
      clearTimeout(currentTimeOut.current);
    };
  }, []);

  useEffect(() => {
    if (current > 3) {
      clearTimeout(currentTimeOut.current);
      setDone(true);
    }
    if (current < -1) {
      clearTimeout(currentTimeOut.current);
      setDone(true);
    }
  }, [current, setDone]);

  const clear = () => {
    paths.current.forEach((e) => e.classList.remove('fadeIn'));
  };

  useEffect(() => {
    if (done) {
      clear();
    }
  }, [done]);

  const moveToNext = useCallback(
    (start = false) => {
      if (start) {
        setDone(false);
        setCurrent(0);
      } else {
        setCurrent((c) => {
          return c + 1;
        });
      }
      clearTimeout(currentTimeOut.current);
      currentTimeOut.current = setTimeout(moveToNext, 300);
    },
    [setDone],
  );

  useEffect(() => {
    if (current >= 0 && current <= 2) {
      paths.current[current].classList.add('fadeIn');
    }
  }, [current]);

  return { forwards: moveToNext, backwards: clear, done };
}
