import React, { useCallback, useRef } from 'react';

import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import {
  AppRunsheetFragment,
  useUpdateRunsheetMutation,
  RunsheetUpdate,
} from '@checkpoints/shared';

import colors, { mediaTablet, styled } from '../../colors';
import { CheckpointsStore } from '../../stores/checkpointsStore';
import { useEscapeKey } from '../../utils/useWindowListener';
import { DashboardIcon } from '../common';
import { DashboardStore } from '../../pages/Dashboard/DashboardStore';
import {
  saveCurrentUserHeaders,
  useUpdateUserHeader,
} from '../../stores/helpers';
import MainStore from '../../stores/Store';

import { EventList } from './EventList';
import { InnerRunsheetEditor } from './InnerRunsheetEditor';
import { usePopperBottomCenterStyle } from './popoverStyles';

const Link1 = React.forwardRef<HTMLAnchorElement, any>((props, ref) => (
  <Link ref={ref} {...props} />
));

export const RunsheetPopover = observer(function RunsheetEditor({
  anchorEl,
  runsheet,
}: {
  anchorEl: any;
  runsheet: AppRunsheetFragment;
}) {
  const open = CheckpointsStore.store.showingRunsheetEditModal;
  const editMode = CheckpointsStore.store.editMode;
  const isAdmin = CheckpointsStore.store.isAdmin;
  const id = open ? 'RunsheetEditor-popper' : undefined;

  const arrowRef = useRef(null);
  const { classes } = usePopperBottomCenterStyle();

  const handleClose = useCallback(() => {
    if (document.activeElement && document.activeElement.tagName === 'INPUT') {
      (document.activeElement as HTMLInputElement).blur();

      return;
    }
    window.focus();
    CheckpointsStore.store.toggleRunsheetEditModal(false);
  }, []);

  useEscapeKey(handleClose);

  const [update] = useUpdateRunsheetMutation();
  const updateHeader = useUpdateUserHeader();

  const updateRunsheet = async (updates: Omit<RunsheetUpdate, 'id'>) => {
    if (updates.showSeconds !== undefined) {
      // If we are updating showseconds, our headers have been updated
      await saveCurrentUserHeaders(MainStore.store.client);
    }

    await update({
      variables: {
        body: {
          id: runsheet.id,
          ...updates,
        },
      },
      update: (cache, result) => {
        if (!updates) {
          return;
        }
        // These is updated via websockets in Listeners.txt

        DashboardStore.store.updateRunsheetCache(
          runsheet.eventId,
          runsheet.id,
          result.data.updateRunsheet,
        );
      },
    });
  };

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl.current}
      // transition
      className={classes.popper}
      placement={'bottom'}
      modifiers={[
        {
          name: 'arrow',
          enabled: false,
        },
      ]}
    >
      <PopoverBackground editMode={editMode}>
        <span
          className={`${classes.arrow} ${editMode && 'editMode'}`}
          ref={arrowRef}
        />
        <ClickAwayListener onClickAway={handleClose}>
          <InnerPopover>
            {isAdmin && (
              <>
                {runsheet ? (
                  <InnerRunsheetEditor
                    runsheet={runsheet}
                    updateRunsheet={updateRunsheet}
                    updateHeader={updateHeader}
                  />
                ) : (
                  <div style={{ height: 120 }}></div>
                )}
                <div className="divider" />
              </>
            )}

            <EventList />
            <div className="divider" />
            <StyledDashBoardDiv editMode={editMode}>
              <Button component={Link1} to="/dashboard">
                <DashboardIcon />
                Dashboard
              </Button>
            </StyledDashBoardDiv>
          </InnerPopover>
        </ClickAwayListener>
      </PopoverBackground>
    </Popper>
  );
});

const PopoverBackground = styled('div')<{ editMode: boolean }>`
  border-radius: 7px;
  border: 1px solid white;
  background-color: ${(p) => (p.editMode ? colors.dark : colors.primary)};

  color: white;

  .divider {
    width: 100%;
    border-bottom: 1px solid white;
    /* border-bottom-color: ${(p) => (p.editMode ? '#222' : 'white')}; */
  }

  /* background-color: white; */
  margin-top: 15px;
`;

const InnerPopover = styled('div')`
  width: 60vw;
  ${mediaTablet} {
    width: 90vw;
  }
`;

const StyledDashBoardDiv = styled('div')<{ editMode?: boolean }>`
  padding: 12px 18px;

  a {
    color: white;
    /* ${(p) => (p.editMode ? '#222' : 'white')}; */

    svg {
      margin-right: 10px;
    }
  }
`;
