import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import { observer } from 'mobx-react';

import { WhiteSwitch } from '../../components/switch';
import { CheckpointsStore } from '../../stores/checkpointsStore';
import { useEventListener } from '../../utils/useWindowListener';

export const EditModeSwitch = observer(function EditModeSwitch() {
  const isAdmin = CheckpointsStore.store.isAdmin;

  //Listen to 'E' Key for edit mode
  useEventListener('keydown', (e) => {
    if (e.key === 'e') {
      // dont enable edit mode if inside a textfield
      const userIsNotEditing = ['BODY', 'DIV'].includes(
        document.activeElement.tagName,
      );

      if (userIsNotEditing) {
        CheckpointsStore.store.toggleEditMode();
      }
    }
  });

  if (!isAdmin) return null;

  const editMode = CheckpointsStore.store.editMode;

  const onEditModeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.blur();
    CheckpointsStore.store.toggleEditMode();
  };

  return (
    <FormControlLabel
      value="editMode"
      control={<WhiteSwitch checked={editMode} onChange={onEditModeChanged} />}
      label="Edit"
      labelPlacement="start"
    />
  );
});
