import * as React from 'react';

import { ApolloError } from '@apollo/client';

export function ErrorLoading({
  error,
  loading,
}: {
  error?: ApolloError;
  loading?: boolean;
}) {
  const [showLoading, setShowLoading] = React.useState(false);

  React.useEffect(() => {
    if (!loading) return;

    //if loading is active, wait a while until this is rendered.
    const timer = setTimeout(() => {
      setShowLoading(true);
    }, 800);

    return () => clearTimeout(timer);
  }, [loading]);

  if (error) {
    //TODO: check for auth errors and redirect to /login.
    // if (error.graphQLErrors.length)
    return <div>{'Error:' + error}</div>;
  }
  if (showLoading) return <div className="app-loading">{'Loading...'}</div>;

  return null;
}
