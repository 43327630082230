import React from 'react';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
  // DraggableProvided,
  DraggableProvidedDragHandleProps,
} from '@hello-pangea/dnd';

export function Handle({
  dragHandleProps,
  hidden = false,
}: {
  dragHandleProps: DraggableProvidedDragHandleProps;

  /** Default is false */
  hidden?: boolean;
}) {
  return (
    <div {...dragHandleProps} className={`handle ${hidden ? 'hidden' : ''}`}>
      <DragHandleIcon />
    </div>
  );
}
