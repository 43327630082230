import React, { useEffect } from 'react';

import { css } from '@mui/material/styles';
import { GlobalStyles } from 'tss-react';

import colors from '../colors';

const cssVars = Object.entries(colors).reduce((acc, [variable, color]) => {
  return `${acc} --${variable}: ${color};`;
}, '');

const globalCss = css`
  :root {
    ${cssVars}
  }
`;

export const GlobalStyle = () => {
  return <GlobalStyles styles={globalCss} />;
};
